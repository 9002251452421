import React, { useState }  from "react";
import {useNavigate} from "react-router-dom";
import logo from '../assets/CTBLogoCircle-Medium.png';
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiEmptyPrompt,
    EuiTitle,
    EuiLink,
    EuiSelect,
    EuiButtonEmpty,
    EuiText,
    EuiSpacer,
    EuiFlexGrid,
    EuiModal,
    EuiModalBody
  } from '@elastic/eui';
  import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
  import YTModal from "../components/YTModal";
  import Footer from "../components/Footer";

  
export default function HowToUse(props){
    const navigate = useNavigate();
    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) == "zh-CN" ? "S" : "T";
    var language = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
    const currentURL = window.location.origin;

   const [YTVideo, setYTVideo] = useState({isActive: false, videoURL: "", id:"",title:""});
   const showYTPreview = (id, link, title) => {
    setYTVideo({isActive: true, videoURL: link, id: id, title: title});
    }

   let ytPreview;
    if (YTVideo.isActive){
      ytPreview = (
        <EuiModal style={{ width: 800 }} onClose={()=>{setYTVideo({isActive: false, videoURL: "", id: "", title: ""})}}><EuiModalBody>
            <YTModal videoURL={YTVideo.videoURL}></YTModal>
            </EuiModalBody>
        </EuiModal>
      );
    }

    return(
        <EuiPageTemplate
        template="centeredbody"
        pageContentProps={{ paddingSize: 'l' }}
        >
        <EuiEmptyPrompt
                title={<><a href={currentURL}><img src={logo} alt="Check The Bible" /></a></>}
                layout="horizontal"
                color="plain"
                body={
                <>
                   <h1>{TranslateLanguage(language,"如何使用")} Check The Bible</h1>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                            <EuiText><h2>{TranslateLanguage(language,"開始搜尋 To Search")}</h2></EuiText>
                        </EuiFlexItem>  
                    </EuiFlexGroup>
                    <EuiSpacer></EuiSpacer>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                              {TranslateLanguage(language,"美國及非亞洲地區: ")}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup> 
                        <EuiFlexItem><EuiButtonEmpty href="https://www.checkthebible.com">https://www.CheckTheBible.com</EuiButtonEmpty></EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                              {TranslateLanguage(language,"亞洲地區: ")}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup > 
                            <EuiFlexItem gutterSize="s"><EuiButtonEmpty href="https://www.checkthebible.com.cn">https://www.CheckTheBible.com.cn</EuiButtonEmpty></EuiFlexItem>
                            <EuiFlexItem gutterSize="s"><EuiButtonEmpty href="https://www.checkthebible.cn">https://www.CheckTheBible.cn</EuiButtonEmpty></EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                            <EuiText><h2>{TranslateLanguage(language,"使用說明")}</h2></EuiText>
                        </EuiFlexItem>  
                    </EuiFlexGroup>
                    <EuiFlexGrid columns={2}> 
                        <EuiFlexItem>
                            <EuiButtonEmpty onClick={()=>showYTPreview(1,"https://youtu.be/L8Xv-sshV24","")}>{TranslateLanguage(language,"電腦版")}</EuiButtonEmpty>
                        </EuiFlexItem>  
                        <EuiFlexItem>
                        <EuiButtonEmpty onClick={()=>showYTPreview(1,"https://youtu.be/sPQ24LyO1qc","")}>{TranslateLanguage(language,"手機版")}</EuiButtonEmpty>
                        </EuiFlexItem>  
                    </EuiFlexGrid>
                    <EuiFlexGroup> 
                    <EuiFlexItem>
                            <EuiText><h2>{TranslateLanguage(language,"如何安裝到手機")}</h2></EuiText>
                        </EuiFlexItem>  
                    </EuiFlexGroup>
                    <EuiFlexGrid columns={2}> 
                        <EuiFlexItem>
                            <EuiButtonEmpty href="https://www.youtube.com/shorts/yLwx8jQNomg" target="_blank">{TranslateLanguage(language,"蘋果 Apple iPhone, iPad")}</EuiButtonEmpty>
                        </EuiFlexItem>  
                        <EuiFlexItem>
                        <EuiButtonEmpty onClick={()=>showYTPreview(1,"https://youtu.be/7T-SyFxfM8Y","")}>{TranslateLanguage(language,"安卓 Android")}</EuiButtonEmpty>
                        </EuiFlexItem>  
                    </EuiFlexGrid>
                    <EuiFlexGroup> 
                    <EuiFlexItem>
                            <EuiText><h2>{TranslateLanguage(language,"如何使用我的歌單")}</h2></EuiText>
                        </EuiFlexItem>  
                    </EuiFlexGroup>                   
                    <EuiFlexGrid columns={2}> 
                        <EuiFlexItem>
                        <EuiButtonEmpty onClick={()=>showYTPreview(1,"https://youtu.be/FCfZ4fbNNBM","")}>{TranslateLanguage(language,"功能介紹")}</EuiButtonEmpty>
                        </EuiFlexItem>  
                        <EuiFlexItem>
                        <EuiButtonEmpty onClick={()=>showYTPreview(1,"https://youtu.be/YHALIWX7m9g","")}>{TranslateLanguage(language,"使用我的歌單來帶領敬拜")}</EuiButtonEmpty>
                        </EuiFlexItem>                          
                    </EuiFlexGrid>                    
                    
                    {ytPreview}
                </>
                }
            />
            <Footer />
      </EuiPageTemplate> 

    )

}