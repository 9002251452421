export default function GetDailyVerse(dayOfYear, plan){
    dayOfYear = dayOfYear > 365? 365 : dayOfYear;
    var verseList = null;
    switch(plan){
        case "HT":
            verseList = loadDailyVerse();
            break;
        case "OT":
            verseList = loadDailyVerseOT();
            break;
        case "NT":
            verseList = loadDailyVerseNT();
            break;
    }
    return verseList[dayOfYear];
}

function loadDailyVerse(){
    var dailyVerseList = [
        {
            "id": "1",
            "link": "https://www.youtube.com/watch?v=Dx9LFHw575M",
            "book": "創1-3 "
        },
        {
            "id": "2",
            "link": "https://www.youtube.com/watch?v=YM-3LRGTGQU",
            "book": "創4-7 "
        },
        {
            "id": "3",
            "link": "https://www.youtube.com/watch?v=MmVcRewU43E",
            "book": "創8-11 "
        },
        {
            "id": "4",
            "link": "https://www.youtube.com/watch?v=DdIcpHTiK1U",
            "book": "伯1-5 "
        },
        {
            "id": "5",
            "link": "https://www.youtube.com/watch?v=7VuCH8TNIug",
            "book": "伯6-9 "
        },
        {
            "id": "6",
            "link": "https://www.youtube.com/watch?v=X1DIRrw1G_M",
            "book": "伯10-13 "
        },
        {
            "id": "7",
            "link": "https://www.youtube.com/watch?v=HOhhFYybtV0",
            "book": "伯14-16 "
        },
        {
            "id": "8",
            "link": "https://www.youtube.com/watch?v=swUQpqZsAK4",
            "book": "伯17-20 "
        },
        {
            "id": "9",
            "link": "https://www.youtube.com/watch?v=x_7a7aMpgGQ",
            "book": "伯21-23 "
        },
        {
            "id": "10",
            "link": "https://www.youtube.com/watch?v=PwKDbCulTzE",
            "book": "伯24-28 "
        },
        {
            "id": "11",
            "link": "https://www.youtube.com/watch?v=84FCrwsrwMs",
            "book": "伯29-31 "
        },
        {
            "id": "12",
            "link": "https://www.youtube.com/watch?v=r-gpuobXO8Q",
            "book": "伯32-34 "
        },
        {
            "id": "13",
            "link": "https://www.youtube.com/watch?v=tzDCuQ7yV90",
            "book": "伯35-37 "
        },
        {
            "id": "14",
            "link": "https://www.youtube.com/watch?v=F_Wr_U-uO5Q",
            "book": "伯38-39 "
        },
        {
            "id": "15",
            "link": "https://www.youtube.com/watch?v=1mwYOnvTO2M",
            "book": "伯40-42 "
        },
        {
            "id": "16",
            "link": "https://www.youtube.com/watch?v=-V75hnn5YqE",
            "book": "創12-15 "
        },
        {
            "id": "17",
            "link": "https://www.youtube.com/watch?v=ctl6etLfrkY",
            "book": "創16-18 "
        },
        {
            "id": "18",
            "link": "https://www.youtube.com/watch?v=VxaJqoktsBw",
            "book": "創19-21 "
        },
        {
            "id": "19",
            "link": "https://www.youtube.com/watch?v=fx-2IJ76GiE",
            "book": "創22-24 "
        },
        {
            "id": "20",
            "link": "https://www.youtube.com/watch?v=nm5_1Gf1jgg",
            "book": "創25-26 "
        },
        {
            "id": "21",
            "link": "https://www.youtube.com/watch?v=jxe3dujf3K8",
            "book": "創27-29 "
        },
        {
            "id": "22",
            "link": "https://www.youtube.com/watch?v=Sjup_xW_pWA",
            "book": "創30-31 "
        },
        {
            "id": "23",
            "link": "https://www.youtube.com/watch?v=QNM5ll33KXg",
            "book": "創32-34 "
        },
        {
            "id": "24",
            "link": "https://www.youtube.com/watch?v=OIEesp5aNQ8",
            "book": "創35-37 "
        },
        {
            "id": "25",
            "link": "https://www.youtube.com/watch?v=D0roAL_90yg",
            "book": "創38-40 "
        },
        {
            "id": "26",
            "link": "https://www.youtube.com/watch?v=XXW4YbNTe3w",
            "book": "創41-42 "
        },
        {
            "id": "27",
            "link": "https://www.youtube.com/watch?v=PO0prsqfxDc",
            "book": "創43-45 "
        },
        {
            "id": "28",
            "link": "https://www.youtube.com/watch?v=GudE8HUk-as",
            "book": "創46-47 "
        },
        {
            "id": "29",
            "link": "https://www.youtube.com/watch?v=JygrfnNwVAU",
            "book": "創48-50 "
        },
        {
            "id": "30",
            "link": "https://www.youtube.com/watch?v=OOSvgFS9Dtw",
            "book": "出1-3 "
        },
        {
            "id": "31",
            "link": "https://www.youtube.com/watch?v=kOqtQ0NM1to",
            "book": "出4-6 "
        },
        {
            "id": "32",
            "link": "https://www.youtube.com/watch?v=wmpIKPwzUb4",
            "book": "出7-9 "
        },
        {
            "id": "33",
            "link": "https://www.youtube.com/watch?v=KaiDxud60Z4",
            "book": "出10-12 "
        },
        {
            "id": "34",
            "link": "https://www.youtube.com/watch?v=VkR1DJU1J3g",
            "book": "出13-15 "
        },
        {
            "id": "35",
            "link": "https://www.youtube.com/watch?v=cMcp1OJRYDk",
            "book": "出16-18 "
        },
        {
            "id": "36",
            "link": "https://www.youtube.com/watch?v=Tzw7ldwehEA",
            "book": "出19-21 "
        },
        {
            "id": "37",
            "link": "https://www.youtube.com/watch?v=-tLnnhRfI8Y",
            "book": "出22-24 "
        },
        {
            "id": "38",
            "link": "https://www.youtube.com/watch?v=q6EfaSN2ZNI",
            "book": "出25-27 "
        },
        {
            "id": "39",
            "link": "https://www.youtube.com/watch?v=UADPMbDoitw",
            "book": "出28-29 "
        },
        {
            "id": "40",
            "link": "https://www.youtube.com/watch?v=beI5e_sujAk",
            "book": "出30-32 "
        },
        {
            "id": "41",
            "link": "https://www.youtube.com/watch?v=4HPaR8KDVro",
            "book": "出33-35 "
        },
        {
            "id": "42",
            "link": "https://www.youtube.com/watch?v=CjoVHSMRQhk",
            "book": "出36-38 "
        },
        {
            "id": "43",
            "link": "https://www.youtube.com/watch?v=k_XJf1H84DI",
            "book": "出39-40 "
        },
        {
            "id": "44",
            "link": "https://www.youtube.com/watch?v=-K7JtMf0uMs",
            "book": "利1-4 "
        },
        {
            "id": "45",
            "link": "https://www.youtube.com/watch?v=MIW_1wkdf7A",
            "book": "利5-7 "
        },
        {
            "id": "46",
            "link": "https://www.youtube.com/watch?v=JbyiTry4Hdg",
            "book": "利8-10 "
        },
        {
            "id": "47",
            "link": "https://www.youtube.com/watch?v=7h5viT5R4DI",
            "book": "利11-13 "
        },
        {
            "id": "48",
            "link": "https://www.youtube.com/watch?v=M5RWxIUkftk",
            "book": "利14-15 "
        },
        {
            "id": "49",
            "link": "https://www.youtube.com/watch?v=xONofqxOuPE",
            "book": "利16-18 "
        },
        {
            "id": "50",
            "link": "https://www.youtube.com/watch?v=UtL4NwrIz1Q",
            "book": "利19-21 "
        },
        {
            "id": "51",
            "link": "https://www.youtube.com/watch?v=ce6cGwcY55w",
            "book": "利22-23 "
        },
        {
            "id": "52",
            "link": "https://www.youtube.com/watch?v=0ixiGU2Allk",
            "book": "利24-25 "
        },
        {
            "id": "53",
            "link": "https://www.youtube.com/watch?v=MhPl43vGP-4",
            "book": "利26-27 "
        },
        {
            "id": "54",
            "link": "https://www.youtube.com/watch?v=RcumaLcLdj8",
            "book": "民1-2 "
        },
        {
            "id": "55",
            "link": "https://www.youtube.com/watch?v=lGPRl3WQ7R8",
            "book": "民3-4 "
        },
        {
            "id": "56",
            "link": "https://www.youtube.com/watch?v=gc73xXg-I4Q",
            "book": "民5-6 "
        },
        {
            "id": "57",
            "link": "https://www.youtube.com/watch?v=UPvJJVCZ62w",
            "book": "民7 "
        },
        {
            "id": "58",
            "link": "https://www.youtube.com/watch?v=39VmcSGvz9Y",
            "book": "民8-10 "
        },
        {
            "id": "59",
            "link": "https://www.youtube.com/watch?v=xREe-GHgDzU",
            "book": "民11-13 "
        },
        {
            "id": "60",
            "link": "https://www.youtube.com/watch?v=Dbu48zZ75io",
            "book": "民14-15 詩90 "
        },
        {
            "id": "61",
            "link": "https://www.youtube.com/watch?v=zedqZdtcGyg",
            "book": "民16-17 "
        },
        {
            "id": "62",
            "link": "https://www.youtube.com/watch?v=DFzAE6Ed9YE",
            "book": "民18-20 "
        },
        {
            "id": "63",
            "link": "https://www.youtube.com/watch?v=SXwH1P5Bpak",
            "book": "民21-22 "
        },
        {
            "id": "64",
            "link": "https://www.youtube.com/watch?v=8hl2Gb6pjKg",
            "book": "民23-25 "
        },
        {
            "id": "65",
            "link": "https://www.youtube.com/watch?v=UtLHSXM7T2w",
            "book": "民26-27 "
        },
        {
            "id": "66",
            "link": "https://www.youtube.com/watch?v=15znwb4qp58",
            "book": "民28-30 "
        },
        {
            "id": "67",
            "link": "https://www.youtube.com/watch?v=0xSFSOzSoFg",
            "book": "民31-32 "
        },
        {
            "id": "68",
            "link": "https://www.youtube.com/watch?v=YkL9aMBjaRY",
            "book": "民33-34 "
        },
        {
            "id": "69",
            "link": "https://www.youtube.com/watch?v=-9SD-s95JRo",
            "book": "民35-36 "
        },
        {
            "id": "70",
            "link": "https://www.youtube.com/watch?v=4zsEZlhq0nU",
            "book": "申1-2 "
        },
        {
            "id": "71",
            "link": "https://www.youtube.com/watch?v=XkxPYDDmvzA",
            "book": "申3-4 "
        },
        {
            "id": "72",
            "link": "https://www.youtube.com/watch?v=Jh9xBPlpg5s",
            "book": "申5-7 "
        },
        {
            "id": "73",
            "link": "https://www.youtube.com/watch?v=fMNBNZnPnnk",
            "book": "申8-10 "
        },
        {
            "id": "74",
            "link": "https://www.youtube.com/watch?v=HtynOsz2fos",
            "book": "申11-13 "
        },
        {
            "id": "75",
            "link": "https://www.youtube.com/watch?v=RrxC07NYALY",
            "book": "申14-16 "
        },
        {
            "id": "76",
            "link": "https://www.youtube.com/watch?v=nBfXcucrKRg",
            "book": "申17-20 "
        },
        {
            "id": "77",
            "link": "https://www.youtube.com/watch?v=n5AEMMBCV-s",
            "book": "申21-23 "
        },
        {
            "id": "78",
            "link": "https://www.youtube.com/watch?v=1CwcjDLbPYs",
            "book": "申24-27 "
        },
        {
            "id": "79",
            "link": "https://www.youtube.com/watch?v=1fc3M_Axo1E",
            "book": "申28-29 "
        },
        {
            "id": "80",
            "link": "https://www.youtube.com/watch?v=c5ta_x5CuV4",
            "book": "申30-31 "
        },
        {
            "id": "81",
            "link": "https://www.youtube.com/watch?v=JdY3b4VD-Ys",
            "book": "申32-34 詩91 "
        },
        {
            "id": "82",
            "link": "https://www.youtube.com/watch?v=t8lT84xQpyE",
            "book": "書1-4 "
        },
        {
            "id": "83",
            "link": "https://www.youtube.com/watch?v=ujP8wvqoz8w",
            "book": "書5-8 "
        },
        {
            "id": "84",
            "link": "https://www.youtube.com/watch?v=7npEsmyePHM",
            "book": "書9-11 "
        },
        {
            "id": "85",
            "link": "https://www.youtube.com/watch?v=zD9YHH2mizc",
            "book": "書12-15 "
        },
        {
            "id": "86",
            "link": "https://www.youtube.com/watch?v=o7q3dXDaCUY",
            "book": "書16-18 "
        },
        {
            "id": "87",
            "link": "https://www.youtube.com/watch?v=DC8XCgNnGPo",
            "book": "書19-21 "
        },
        {
            "id": "88",
            "link": "https://www.youtube.com/watch?v=s2kigzt0Xjc",
            "book": "書22-24 "
        },
        {
            "id": "89",
            "link": "https://www.youtube.com/watch?v=s2HnO9ybniw",
            "book": "士1-2 "
        },
        {
            "id": "90",
            "link": "https://www.youtube.com/watch?v=5l0BcLxRuBA",
            "book": "士3-5 "
        },
        {
            "id": "91",
            "link": "https://www.youtube.com/watch?v=51QK8nLmHmI",
            "book": "士6-7 "
        },
        {
            "id": "92",
            "link": "https://www.youtube.com/watch?v=Zzx2dHNh5VE",
            "book": "士8-9 "
        },
        {
            "id": "93",
            "link": "https://www.youtube.com/watch?v=O_wau49Mac8",
            "book": "士10-12 "
        },
        {
            "id": "94",
            "link": "https://www.youtube.com/watch?v=zqAmGWYG3f4",
            "book": "士13-15 "
        },
        {
            "id": "95",
            "link": "https://www.youtube.com/watch?v=YgZprqq_TEc",
            "book": "士16-18 "
        },
        {
            "id": "96",
            "link": "https://www.youtube.com/watch?v=amdn9YVeaBk",
            "book": "士19-21 "
        },
        {
            "id": "97",
            "link": "https://www.youtube.com/watch?v=CcNOlXDa7Qk",
            "book": "得1-4 "
        },
        {
            "id": "98",
            "link": "https://www.youtube.com/watch?v=rJk5nveTicE",
            "book": "撒上1-3 "
        },
        {
            "id": "99",
            "link": "https://www.youtube.com/watch?v=gDppa2QJ1SM",
            "book": "撒上4-8 "
        },
        {
            "id": "100",
            "link": "https://www.youtube.com/watch?v=7Dcy0fwqDxk",
            "book": "撒上9-12 "
        },
        {
            "id": "101",
            "link": "https://www.youtube.com/watch?v=rlEF5T9s6fY",
            "book": "撒上13-14 "
        },
        {
            "id": "102",
            "link": "https://www.youtube.com/watch?v=W3zKvXP57iQ",
            "book": "撒上15-17 "
        },
        {
            "id": "103",
            "link": "https://www.youtube.com/watch?v=t6eJVAyDz_c",
            "book": "撒上18-20 詩11 詩59 "
        },
        {
            "id": "104",
            "link": "https://www.youtube.com/watch?v=dNvPr3Hk_UI",
            "book": "撒上21-24 "
        },
        {
            "id": "105",
            "link": "https://www.youtube.com/watch?v=b8klI_zUjZw",
            "book": "詩7 詩27 詩31 詩34 詩52 "
        },
        {
            "id": "106",
            "link": "https://www.youtube.com/watch?v=SD4UIfWBLg0",
            "book": "詩56 詩120 詩140-142 "
        },
        {
            "id": "107",
            "link": "https://www.youtube.com/watch?v=Gx18hN6o4mE",
            "book": "撒上25-27 "
        },
        {
            "id": "108",
            "link": "https://www.youtube.com/watch?v=0wCKluxuUUQ",
            "book": "詩17 詩35 詩54 詩63 "
        },
        {
            "id": "109",
            "link": "https://www.youtube.com/watch?v=YSotcMvpL2M",
            "book": "撒上28-31 詩18 "
        },
        {
            "id": "110",
            "link": "https://www.youtube.com/watch?v=SF_EJBeE5Ow",
            "book": "詩121 詩123-125 詩128-130 "
        },
        {
            "id": "111",
            "link": "https://www.youtube.com/watch?v=_aU3b12dTJo",
            "book": "撒下1-4 "
        },
        {
            "id": "112",
            "link": "https://www.youtube.com/watch?v=Chj8nfXFids",
            "book": "詩6 詩8-10 詩14 詩16 詩19 詩21 "
        },
        {
            "id": "113",
            "link": "https://www.youtube.com/watch?v=Lm_aXdG8GJM",
            "book": "代上1-2 "
        },
        {
            "id": "114",
            "link": "https://www.youtube.com/watch?v=Jt9844p2ke4",
            "book": "詩43-45 詩49 詩84-85 詩87 "
        },
        {
            "id": "115",
            "link": "https://www.youtube.com/watch?v=g6VUjx5ZMzM",
            "book": "代上3-5 "
        },
        {
            "id": "116",
            "link": "https://www.youtube.com/watch?v=ORDKQntADww",
            "book": "詩73 詩77-78 "
        },
        {
            "id": "117",
            "link": "https://www.youtube.com/watch?v=kSAQOtcWJLQ",
            "book": "代上6 "
        },
        {
            "id": "118",
            "link": "https://www.youtube.com/watch?v=wvNUyOcvS0A",
            "book": "詩81 詩88 詩92-93 "
        },
        {
            "id": "119",
            "link": "https://www.youtube.com/watch?v=q1S329_-vvU",
            "book": "代上7-10 "
        },
        {
            "id": "120",
            "link": "https://www.youtube.com/watch?v=swcsK-rusFw",
            "book": "詩102-104 "
        },
        {
            "id": "121",
            "link": "https://www.youtube.com/watch?v=Isn1oSRs3uc",
            "book": "撒下5:1-10 代上11-12 "
        },
        {
            "id": "122",
            "link": "https://www.youtube.com/watch?v=1E0aKo--P-4",
            "book": "詩133 "
        },
        {
            "id": "123",
            "link": "https://www.youtube.com/watch?v=t9IOp4PKtJU",
            "book": "詩106-107 "
        },
        {
            "id": "124",
            "link": "https://www.youtube.com/watch?v=ahmVQM_GhhQ",
            "book": "撒下5:11-6:23 代上13-16 "
        },
        {
            "id": "125",
            "link": "https://www.youtube.com/watch?v=k1Elbk7CWsU",
            "book": "詩1-2詩15詩22-24 詩47 詩68 "
        },
        {
            "id": "126",
            "link": "https://www.youtube.com/watch?v=qmP5HN5JyrQ",
            "book": "詩89 詩96 詩100 詩101 詩105 詩132 "
        },
        {
            "id": "127",
            "link": "https://www.youtube.com/watch?v=jxqvZHHSNMo",
            "book": "撒下7 代上17 "
        },
        {
            "id": "128",
            "link": "https://www.youtube.com/watch?v=JHoZsMjsFPQ",
            "book": "詩25 詩29 詩33 詩36 詩39 "
        },
        {
            "id": "129",
            "link": "https://www.youtube.com/watch?v=iIIZQC_Lcck",
            "book": "撒下8-9 代上18 "
        },
        {
            "id": "130",
            "link": "https://www.youtube.com/watch?v=b1H5uXGgwGY",
            "book": "詩50 詩53 詩60 詩75 "
        },
        {
            "id": "131",
            "link": "https://www.youtube.com/watch?v=21TdaUlaNqI",
            "book": "撒下10 代上19 詩20 "
        },
        {
            "id": "132",
            "link": "https://www.youtube.com/watch?v=vrhlar_qkeg",
            "book": "詩65-67 詩69-70 "
        },
        {
            "id": "133",
            "link": "https://www.youtube.com/watch?v=eFC9p6JNEGk",
            "book": "撒下11-12 代上20 "
        },
        {
            "id": "134",
            "link": "https://www.youtube.com/watch?v=DpQCAhU3hA0",
            "book": "詩32 詩51 詩86 詩122 "
        },
        {
            "id": "135",
            "link": "https://www.youtube.com/watch?v=sa272c37-FM",
            "book": "撒下13-15 "
        },
        {
            "id": "136",
            "link": "https://www.youtube.com/watch?v=5nCmWruJRJw",
            "book": "詩3-4 詩12-13 詩28 詩55 "
        },
        {
            "id": "137",
            "link": "https://www.youtube.com/watch?v=vqtq1ToMW6o",
            "book": "撒下16-18 "
        },
        {
            "id": "138",
            "link": "https://www.youtube.com/watch?v=vWXJT8YAETQ",
            "book": "詩26 詩40 詩58 詩61-62 詩64 "
        },
        {
            "id": "139",
            "link": "https://www.youtube.com/watch?v=CfY4Va8qksE",
            "book": "撒下19-21 "
        },
        {
            "id": "140",
            "link": "https://www.youtube.com/watch?v=rb51fG3L-ko",
            "book": "詩5 詩38 詩41-42 "
        },
        {
            "id": "141",
            "link": "https://www.youtube.com/watch?v=ga6pqjZpAJQ",
            "book": "撒下22-23 詩57 "
        },
        {
            "id": "142",
            "link": "https://www.youtube.com/watch?v=JbXSmNBeVds",
            "book": "詩95 詩97-99 "
        },
        {
            "id": "143",
            "link": "https://www.youtube.com/watch?v=VhkPKu-fDwk",
            "book": "撒下24 代上21-22 詩30 "
        },
        {
            "id": "144",
            "link": "https://www.youtube.com/watch?v=RkZ2YQVOD5I",
            "book": "詩108-110 "
        },
        {
            "id": "145",
            "link": "https://www.youtube.com/watch?v=msDCIcdacTY",
            "book": "代上23-25 "
        },
        {
            "id": "146",
            "link": "https://www.youtube.com/watch?v=TE1cRjDxBvw",
            "book": "詩131 詩138-139 詩143-145 "
        },
        {
            "id": "147",
            "link": "https://www.youtube.com/watch?v=WEZLzSD65OE",
            "book": "代上26-29 詩127 "
        },
        {
            "id": "148",
            "link": "https://www.youtube.com/watch?v=lEaSlXbLHF4",
            "book": "詩111-118 "
        },
        {
            "id": "149",
            "link": "https://www.youtube.com/watch?v=xSSeVvRDnD8",
            "book": "王上1-2 詩37 詩71 詩94 "
        },
        {
            "id": "150",
            "link": "https://www.youtube.com/watch?v=cKZuEVlhqCY",
            "book": "詩119:1-88 "
        },
        {
            "id": "151",
            "link": "https://www.youtube.com/watch?v=ibP2SA4yZow",
            "book": "王上3-4 代下1 詩72 "
        },
        {
            "id": "152",
            "link": "https://www.youtube.com/watch?v=-48lURu-PU4",
            "book": "詩119:89-176 "
        },
        {
            "id": "153",
            "link": "https://www.youtube.com/watch?v=ZzKPeLlt1nM",
            "book": "歌1-8 "
        },
        {
            "id": "154",
            "link": "https://www.youtube.com/watch?v=jJh1sIkpWv0",
            "book": "箴1-3 "
        },
        {
            "id": "155",
            "link": "https://www.youtube.com/watch?v=D2w2x778yYw",
            "book": "箴4-6 "
        },
        {
            "id": "156",
            "link": "https://www.youtube.com/watch?v=N4Rfs5sNZ7A",
            "book": "箴7-9 "
        },
        {
            "id": "157",
            "link": "https://www.youtube.com/watch?v=IqjbN-TjpRE",
            "book": "箴10-12 "
        },
        {
            "id": "158",
            "link": "https://www.youtube.com/watch?v=h4cMfzP_2sQ",
            "book": "箴13-15 "
        },
        {
            "id": "159",
            "link": "https://www.youtube.com/watch?v=Hqp_JXrlDvU",
            "book": "箴16-18 "
        },
        {
            "id": "160",
            "link": "https://www.youtube.com/watch?v=o0ziyvVAR3Q",
            "book": "箴19-21 "
        },
        {
            "id": "161",
            "link": "https://www.youtube.com/watch?v=E8C6LHb7E1Y",
            "book": "箴22-24 "
        },
        {
            "id": "162",
            "link": "https://www.youtube.com/watch?v=ATlnRT_mjeo",
            "book": "王上5-6 代下2-3 "
        },
        {
            "id": "163",
            "link": "https://www.youtube.com/watch?v=eVsfx8ZDCZo",
            "book": "王上7 代下4 "
        },
        {
            "id": "164",
            "link": "https://www.youtube.com/watch?v=w-vI1xAwkQ4",
            "book": "王上8 代下5 "
        },
        {
            "id": "165",
            "link": "https://www.youtube.com/watch?v=7PYxTXEDIms",
            "book": "代下6-7 詩136 "
        },
        {
            "id": "166",
            "link": "https://www.youtube.com/watch?v=X6x4GqFzmvU",
            "book": "詩134 詩146-150 "
        },
        {
            "id": "167",
            "link": "https://www.youtube.com/watch?v=mYfpCqSMLAo",
            "book": "王上9 代下8 "
        },
        {
            "id": "168",
            "link": "https://www.youtube.com/watch?v=2mEBopk4aKY",
            "book": "箴25-26 "
        },
        {
            "id": "169",
            "link": "https://www.youtube.com/watch?v=plm2MZD3GpQ",
            "book": "箴27-29 "
        },
        {
            "id": "170",
            "link": "https://www.youtube.com/watch?v=7gyzH5zX51M",
            "book": "傳1-6 "
        },
        {
            "id": "171",
            "link": "https://www.youtube.com/watch?v=yjc5C58QQgc",
            "book": "傳7-12 "
        },
        {
            "id": "172",
            "link": "https://www.youtube.com/watch?v=54aJ_BuMENY",
            "book": "王上10-11 代下9 "
        },
        {
            "id": "173",
            "link": "https://www.youtube.com/watch?v=LH6UX2s86JM",
            "book": "箴30-31 "
        },
        {
            "id": "174",
            "link": "https://www.youtube.com/watch?v=KK8jjxv1fE0",
            "book": "王上12-14 "
        },
        {
            "id": "175",
            "link": "https://www.youtube.com/watch?v=nbTqFaKtSrk",
            "book": "代下10-12 "
        },
        {
            "id": "176",
            "link": "https://www.youtube.com/watch?v=guDibPITX7c",
            "book": "王上15:1-24 代下13-16 "
        },
        {
            "id": "177",
            "link": "https://www.youtube.com/watch?v=PGKWxJBxQY4",
            "book": "王上15:25-16:34 代下17 "
        },
        {
            "id": "178",
            "link": "https://www.youtube.com/watch?v=EVIWPBEfZKU",
            "book": "王上17-19 "
        },
        {
            "id": "179",
            "link": "https://www.youtube.com/watch?v=eyh4davjdYE",
            "book": "王上20-21 "
        },
        {
            "id": "180",
            "link": "https://www.youtube.com/watch?v=Ex8quqijnMk",
            "book": "王上22 代下18 "
        },
        {
            "id": "181",
            "link": "https://www.youtube.com/watch?v=ayUF2JcG_Ws",
            "book": "代下19-23 "
        },
        {
            "id": "182",
            "link": "https://www.youtube.com/watch?v=eU5T9OmAQEA",
            "book": "俄1 詩82-83 "
        },
        {
            "id": "183",
            "link": "https://www.youtube.com/watch?v=Dpj1QZu_0h0",
            "book": "王下1-4 "
        },
        {
            "id": "184",
            "link": "https://www.youtube.com/watch?v=maaudNysY9k",
            "book": "王下5-8 "
        },
        {
            "id": "185",
            "link": "https://www.youtube.com/watch?v=qY5iU0IvPKU",
            "book": "王下9-11 "
        },
        {
            "id": "186",
            "link": "https://www.youtube.com/watch?v=A4p4wKl9Fxg",
            "book": "王下12-13 代下24 "
        },
        {
            "id": "187",
            "link": "https://www.youtube.com/watch?v=XHxUIzaeTEo",
            "book": "王下14 代下25 "
        },
        {
            "id": "188",
            "link": "https://www.youtube.com/watch?v=QXsm_ak7vlI",
            "book": "拿1-4 "
        },
        {
            "id": "189",
            "link": "https://www.youtube.com/watch?v=TIB7z5LyR3k",
            "book": "王下15 代下26 "
        },
        {
            "id": "190",
            "link": "https://www.youtube.com/watch?v=7l821dC6NVk",
            "book": "賽1-4 "
        },
        {
            "id": "191",
            "link": "https://www.youtube.com/watch?v=4JNooB8-_xc",
            "book": "賽5-8 "
        },
        {
            "id": "192",
            "link": "https://www.youtube.com/watch?v=BhtvkICR4Ig",
            "book": "摩1-5 "
        },
        {
            "id": "193",
            "link": "https://www.youtube.com/watch?v=_Ui0NW4d9eI",
            "book": "摩6-9 "
        },
        {
            "id": "194",
            "link": "https://www.youtube.com/watch?v=3mFMUVj4YHM",
            "book": "代下27 賽9-12 "
        },
        {
            "id": "195",
            "link": "https://www.youtube.com/watch?v=maomT5Sag54",
            "book": "彌1-7 "
        },
        {
            "id": "196",
            "link": "https://www.youtube.com/watch?v=NYMqi6q8OEA",
            "book": "代下28 王下16-17 "
        },
        {
            "id": "197",
            "link": "https://www.youtube.com/watch?v=Rs1kKSFFYms",
            "book": "賽13-17 "
        },
        {
            "id": "198",
            "link": "https://www.youtube.com/watch?v=mUdxR3I62ac",
            "book": "賽18-22 "
        },
        {
            "id": "199",
            "link": "https://www.youtube.com/watch?v=VJMfWvLLE3U",
            "book": "賽23-27 "
        },
        {
            "id": "200",
            "link": "https://www.youtube.com/watch?v=X_ZXNitHK-0",
            "book": "王下18:1-8 代下29-31 詩48 "
        },
        {
            "id": "201",
            "link": "https://www.youtube.com/watch?v=dQS2XE31dng",
            "book": "何1-7 "
        },
        {
            "id": "202",
            "link": "https://www.youtube.com/watch?v=BxLF0jMR3bM",
            "book": "何8-14 "
        },
        {
            "id": "203",
            "link": "https://www.youtube.com/watch?v=zD0ldvKZWus",
            "book": "賽28-30 "
        },
        {
            "id": "204",
            "link": "https://www.youtube.com/watch?v=oieaAQ6OGjk",
            "book": "賽31-34 "
        },
        {
            "id": "205",
            "link": "https://www.youtube.com/watch?v=jVWLJhrrDEg",
            "book": "賽35-36 "
        },
        {
            "id": "206",
            "link": "https://www.youtube.com/watch?v=Or1KWvJM_7A",
            "book": "賽37-39 詩76 "
        },
        {
            "id": "207",
            "link": "https://www.youtube.com/watch?v=tFz20DiA7I0",
            "book": "賽40-43 "
        },
        {
            "id": "208",
            "link": "https://www.youtube.com/watch?v=LyVQg9zxF7c",
            "book": "賽44-48 "
        },
        {
            "id": "209",
            "link": "https://www.youtube.com/watch?v=x87d1FGZHVE",
            "book": "王下18:9-19:37 詩46 詩80 詩135 "
        },
        {
            "id": "210",
            "link": "https://www.youtube.com/watch?v=l_yNmrNvz7g",
            "book": "賽49-53 "
        },
        {
            "id": "211",
            "link": "https://www.youtube.com/watch?v=87Mvv3FuvTM",
            "book": "賽54-58 "
        },
        {
            "id": "212",
            "link": "https://www.youtube.com/watch?v=0grhYlNVYdw",
            "book": "賽59-63 "
        },
        {
            "id": "213",
            "link": "https://www.youtube.com/watch?v=0Vkx0IeN_rY",
            "book": "賽64-66 "
        },
        {
            "id": "214",
            "link": "https://www.youtube.com/watch?v=FsENOxPUgR8",
            "book": "王下20-21 "
        },
        {
            "id": "215",
            "link": "https://www.youtube.com/watch?v=zfy9_sUAE_U",
            "book": "代下32-33 "
        },
        {
            "id": "216",
            "link": "https://www.youtube.com/watch?v=T-6vhGcni1Y",
            "book": "鴻1-3 "
        },
        {
            "id": "217",
            "link": "https://www.youtube.com/watch?v=gfnoMZ-kf30",
            "book": "王下22-23 代下34-35 "
        },
        {
            "id": "218",
            "link": "https://www.youtube.com/watch?v=P_3yGVOlY98",
            "book": "番1-3 "
        },
        {
            "id": "219",
            "link": "https://www.youtube.com/watch?v=k7ryISSgDBk",
            "book": "耶1-3 "
        },
        {
            "id": "220",
            "link": "https://www.youtube.com/watch?v=mYnu7jQCRt8",
            "book": "耶4-6 "
        },
        {
            "id": "221",
            "link": "https://www.youtube.com/watch?v=FUZ21JBJDoM",
            "book": "耶7-9 "
        },
        {
            "id": "222",
            "link": "https://www.youtube.com/watch?v=3FFL9OlYOG4",
            "book": "耶10-13 "
        },
        {
            "id": "223",
            "link": "https://www.youtube.com/watch?v=jfOuMLPW5g0",
            "book": "耶14-17 "
        },
        {
            "id": "224",
            "link": "https://www.youtube.com/watch?v=eMXuGWRRc4Y",
            "book": "耶18-22 "
        },
        {
            "id": "225",
            "link": "https://www.youtube.com/watch?v=bW5IIjm1WuE",
            "book": "耶23-25 "
        },
        {
            "id": "226",
            "link": "https://www.youtube.com/watch?v=VARB7UtvZ-E",
            "book": "耶26-29 "
        },
        {
            "id": "227",
            "link": "https://www.youtube.com/watch?v=XuEFHBzgX3k",
            "book": "耶30-31 "
        },
        {
            "id": "228",
            "link": "https://www.youtube.com/watch?v=XB0N_WyXl6s",
            "book": "耶32-34 "
        },
        {
            "id": "229",
            "link": "https://www.youtube.com/watch?v=Oz3brrI4wv0",
            "book": "耶35-37 "
        },
        {
            "id": "230",
            "link": "https://www.youtube.com/watch?v=QDQDgFS26UY",
            "book": "耶38-40 詩74 詩79 "
        },
        {
            "id": "231",
            "link": "https://www.youtube.com/watch?v=h7mapP9KEoY",
            "book": "王下24-25 代下36 "
        },
        {
            "id": "232",
            "link": "https://www.youtube.com/watch?v=j9JTABLJftA",
            "book": "哈1-3 "
        },
        {
            "id": "233",
            "link": "https://www.youtube.com/watch?v=UVatJxKBed4",
            "book": "耶41-45 "
        },
        {
            "id": "234",
            "link": "https://www.youtube.com/watch?v=HnJd66CFhvU",
            "book": "耶46-48 "
        },
        {
            "id": "235",
            "link": "https://www.youtube.com/watch?v=yrknvcedFeY",
            "book": "耶49-50 "
        },
        {
            "id": "236",
            "link": "https://www.youtube.com/watch?v=j-yTB29kSdE",
            "book": "耶51-52 "
        },
        {
            "id": "237",
            "link": "https://www.youtube.com/watch?v=Ywwg2UB_nBY",
            "book": "哀1:1-3:36 "
        },
        {
            "id": "238",
            "link": "https://www.youtube.com/watch?v=-uOCeLR0dxk",
            "book": "哀3:37-5:22 "
        },
        {
            "id": "239",
            "link": "https://www.youtube.com/watch?v=utQAFh5kks0",
            "book": "結1-4 "
        },
        {
            "id": "240",
            "link": "https://www.youtube.com/watch?v=jC3pC76T12o",
            "book": "結5-8 "
        },
        {
            "id": "241",
            "link": "https://www.youtube.com/watch?v=QuB5AMqZD-U",
            "book": "結9-12 "
        },
        {
            "id": "242",
            "link": "https://www.youtube.com/watch?v=XJ-qLdi2Qyw",
            "book": "結13-15 "
        },
        {
            "id": "243",
            "link": "https://www.youtube.com/watch?v=ebzt7qrqOGg",
            "book": "結16-17 "
        },
        {
            "id": "244",
            "link": "https://www.youtube.com/watch?v=WVFJObL1vpc",
            "book": "結18-19 "
        },
        {
            "id": "245",
            "link": "https://www.youtube.com/watch?v=91pmF7xkA3k",
            "book": "結20-21 "
        },
        {
            "id": "246",
            "link": "https://www.youtube.com/watch?v=kmquJ0cT69I",
            "book": "結22-23 "
        },
        {
            "id": "247",
            "link": "https://www.youtube.com/watch?v=UfVcCcHXlqU",
            "book": "結24-27 "
        },
        {
            "id": "248",
            "link": "https://www.youtube.com/watch?v=iRvzDtjlESk",
            "book": "結28-31 "
        },
        {
            "id": "249",
            "link": "https://www.youtube.com/watch?v=7VB5Z9-4N_o",
            "book": "結32-34 "
        },
        {
            "id": "250",
            "link": "https://www.youtube.com/watch?v=0TKsfvq7GoQ",
            "book": "結35-37 "
        },
        {
            "id": "251",
            "link": "https://www.youtube.com/watch?v=hVoqM2opsas",
            "book": "結38-39 "
        },
        {
            "id": "252",
            "link": "https://www.youtube.com/watch?v=G4_hw05NznA",
            "book": "結40-41 "
        },
        {
            "id": "253",
            "link": "https://www.youtube.com/watch?v=e20cCYQlJBM",
            "book": "結42-43 "
        },
        {
            "id": "254",
            "link": "https://www.youtube.com/watch?v=DKGKCGc1Tis",
            "book": "結44-45 "
        },
        {
            "id": "255",
            "link": "https://www.youtube.com/watch?v=cCAcgOrd1po",
            "book": "結46-48 "
        },
        {
            "id": "256",
            "link": "https://www.youtube.com/watch?v=CiR9qWvHFYY",
            "book": "珥1-3 "
        },
        {
            "id": "257",
            "link": "https://www.youtube.com/watch?v=8cS3ncPvZ00",
            "book": "但1-3 "
        },
        {
            "id": "258",
            "link": "https://www.youtube.com/watch?v=soaa-cObd84",
            "book": "但4-6 "
        },
        {
            "id": "259",
            "link": "https://www.youtube.com/watch?v=NEtshcbZJzg",
            "book": "但7-9 "
        },
        {
            "id": "260",
            "link": "https://www.youtube.com/watch?v=DmvirpdQATM",
            "book": "但10-12 "
        },
        {
            "id": "261",
            "link": "https://www.youtube.com/watch?v=yEwTNlHN_-o",
            "book": "拉1-3 "
        },
        {
            "id": "262",
            "link": "https://www.youtube.com/watch?v=5VMtx9Ae7es",
            "book": "拉4-6 詩137 "
        },
        {
            "id": "263",
            "link": "https://www.youtube.com/watch?v=nygBG5PzEao",
            "book": "該1-2 "
        },
        {
            "id": "264",
            "link": "https://www.youtube.com/watch?v=oEBrJuoWrfk",
            "book": "亞1-7 "
        },
        {
            "id": "265",
            "link": "https://www.youtube.com/watch?v=hbZRDfcmro4",
            "book": "亞8-14 "
        },
        {
            "id": "266",
            "link": "https://www.youtube.com/watch?v=1oCXxryx5j0",
            "book": "斯1-5 "
        },
        {
            "id": "267",
            "link": "https://www.youtube.com/watch?v=MXYY3vw0tDI",
            "book": "斯6-10 "
        },
        {
            "id": "268",
            "link": "https://www.youtube.com/watch?v=x5DVLGZTYnI",
            "book": "拉7-10 "
        },
        {
            "id": "269",
            "link": "https://www.youtube.com/watch?v=RUfPkrPCoak",
            "book": "尼1-5 "
        },
        {
            "id": "270",
            "link": "https://www.youtube.com/watch?v=GV1MdF6BkdA",
            "book": "尼6-7 "
        },
        {
            "id": "271",
            "link": "https://www.youtube.com/watch?v=HpBihOj3K50",
            "book": "尼8-10 "
        },
        {
            "id": "272",
            "link": "https://www.youtube.com/watch?v=qKbKzmMxEdc",
            "book": "尼11-13 詩126 "
        },
        {
            "id": "273",
            "link": "https://www.youtube.com/watch?v=CzoYPTlT6js",
            "book": "瑪1-4 "
        },
        {
            "id": "274",
            "link": "https://www.youtube.com/watch?v=Bx_ZIu2VQac",
            "book": "路1 約1:1-14 "
        },
        {
            "id": "275",
            "link": "https://www.youtube.com/watch?v=WMa4_cpYWVk",
            "book": "太1 路2:1-38 "
        },
        {
            "id": "276",
            "link": "https://www.youtube.com/watch?v=GTP_2MdszOk",
            "book": "太2 路2:39-52 "
        },
        {
            "id": "277",
            "link": "https://www.youtube.com/watch?v=QBT9e3MSVoQ",
            "book": "太3 可1 路3 "
        },
        {
            "id": "278",
            "link": "https://www.youtube.com/watch?v=ZVSKmCPwNc8",
            "book": "太4 路4-5 約1:15-51 "
        },
        {
            "id": "279",
            "link": "https://www.youtube.com/watch?v=1_lKqJyARcc",
            "book": "約2-4 "
        },
        {
            "id": "280",
            "link": "https://www.youtube.com/watch?v=H1bGQsmToQ8",
            "book": "可2 "
        },
        {
            "id": "281",
            "link": "https://www.youtube.com/watch?v=pKvs1lHl3Po",
            "book": "約5-7 "
        },
        {
            "id": "282",
            "link": "https://www.youtube.com/watch?v=VXMwowkIGWs",
            "book": "太12:1-21 可3 路6 "
        },
        {
            "id": "283",
            "link": "https://www.youtube.com/watch?v=fk4e-86uACE",
            "book": "太5-7 "
        },
        {
            "id": "284",
            "link": "https://www.youtube.com/watch?v=W2Slq6sROaE",
            "book": "太8:1-13 路7 "
        },
        {
            "id": "285",
            "link": "https://www.youtube.com/watch?v=G6CLaBtgCBk",
            "book": "太11 "
        },
        {
            "id": "286",
            "link": "https://www.youtube.com/watch?v=r3bDlZi_Bbg",
            "book": "太12:22-50 路11 "
        },
        {
            "id": "287",
            "link": "https://www.youtube.com/watch?v=qNH4Qfe40Xc",
            "book": "太13 路8 "
        },
        {
            "id": "288",
            "link": "https://www.youtube.com/watch?v=XSTpn8oEZsQ",
            "book": "太8:14-34 可4-5 "
        },
        {
            "id": "289",
            "link": "https://www.youtube.com/watch?v=_XbIFfMjIrU",
            "book": "太9-10 "
        },
        {
            "id": "290",
            "link": "https://www.youtube.com/watch?v=hhffFp1XfPM",
            "book": "太14 可6 路9:1-17 "
        },
        {
            "id": "291",
            "link": "https://www.youtube.com/watch?v=spzxBljGurI",
            "book": "約6 "
        },
        {
            "id": "292",
            "link": "https://www.youtube.com/watch?v=cgMCPYDehV0",
            "book": "太15 可7 "
        },
        {
            "id": "293",
            "link": "https://www.youtube.com/watch?v=pSt06WKN-bM",
            "book": "太16 可8 路9:18-27 "
        },
        {
            "id": "294",
            "link": "https://www.youtube.com/watch?v=8Db4Y1-sXDs",
            "book": "太17 可9 路9:28-62 "
        },
        {
            "id": "295",
            "link": "https://www.youtube.com/watch?v=oyt-wbMDExQ",
            "book": "太18 "
        },
        {
            "id": "296",
            "link": "https://www.youtube.com/watch?v=73IxKY9XEk4",
            "book": "約7-8 "
        },
        {
            "id": "297",
            "link": "https://www.youtube.com/watch?v=kKtBGXakUGY",
            "book": "約9:1-10:21 "
        },
        {
            "id": "298",
            "link": "https://www.youtube.com/watch?v=p88aeg5_AqU",
            "book": "路10-11 約10:22-42 "
        },
        {
            "id": "299",
            "link": "https://www.youtube.com/watch?v=i1B1TikhnwY",
            "book": "路12-13 "
        },
        {
            "id": "300",
            "link": "https://www.youtube.com/watch?v=BtzbnLDmi60",
            "book": "路14-15 "
        },
        {
            "id": "301",
            "link": "https://www.youtube.com/watch?v=dSxrpSBLE5I",
            "book": "路16-17:10 "
        },
        {
            "id": "302",
            "link": "https://www.youtube.com/watch?v=2nGcIs6mXIU",
            "book": "約11 "
        },
        {
            "id": "303",
            "link": "https://www.youtube.com/watch?v=Z0trz6vuBzg",
            "book": "路17:11-18:14 "
        },
        {
            "id": "304",
            "link": "https://www.youtube.com/watch?v=ydyb1n98YZU",
            "book": "太19 可10 "
        },
        {
            "id": "305",
            "link": "https://www.youtube.com/watch?v=lpWa4JqOeVw",
            "book": "太20-21 "
        },
        {
            "id": "306",
            "link": "https://www.youtube.com/watch?v=mILe2hXQ5Bo",
            "book": "路18:15-19:48 "
        },
        {
            "id": "307",
            "link": "https://www.youtube.com/watch?v=DleA7LxLMCI",
            "book": "可11 約12 "
        },
        {
            "id": "308",
            "link": "https://www.youtube.com/watch?v=1sY43dyVQNM",
            "book": "太22 可12 "
        },
        {
            "id": "309",
            "link": "https://www.youtube.com/watch?v=WQ71GaybEdo",
            "book": "太23 路20-21 "
        },
        {
            "id": "310",
            "link": "https://www.youtube.com/watch?v=dwA8Ycy2WK4",
            "book": "可13 "
        },
        {
            "id": "311",
            "link": "https://www.youtube.com/watch?v=EngH5d_c-K4",
            "book": "太24 "
        },
        {
            "id": "312",
            "link": "https://www.youtube.com/watch?v=JkmdfUpzTE4",
            "book": "太25 "
        },
        {
            "id": "313",
            "link": "https://www.youtube.com/watch?v=K49ChJYCb_w",
            "book": "太26 可14 "
        },
        {
            "id": "314",
            "link": "https://www.youtube.com/watch?v=fhHv19EGaz4",
            "book": "路22 約13 "
        },
        {
            "id": "315",
            "link": "https://www.youtube.com/watch?v=28KP4_YhX08",
            "book": "約14-17 "
        },
        {
            "id": "316",
            "link": "https://www.youtube.com/watch?v=YitCfdrOe5k",
            "book": "太27 可15 "
        },
        {
            "id": "317",
            "link": "https://www.youtube.com/watch?v=WR84u1dtHU0",
            "book": "路23 約18-19 "
        },
        {
            "id": "318",
            "link": "https://www.youtube.com/watch?v=0vRBotz0Pvo",
            "book": "太28 可16 "
        },
        {
            "id": "319",
            "link": "https://www.youtube.com/watch?v=aU2hVBxCn_s",
            "book": "路24 約20-21 "
        },
        {
            "id": "320",
            "link": "https://www.youtube.com/watch?v=qlo8m8CUGm8",
            "book": "徒1-3 "
        },
        {
            "id": "321",
            "link": "https://www.youtube.com/watch?v=8dOrFeDx2LQ",
            "book": "徒4-6 "
        },
        {
            "id": "322",
            "link": "https://www.youtube.com/watch?v=j62wNjl44Gc",
            "book": "徒7-8 "
        },
        {
            "id": "323",
            "link": "https://www.youtube.com/watch?v=E7R0Urq6ME0",
            "book": "徒9-10 "
        },
        {
            "id": "324",
            "link": "https://www.youtube.com/watch?v=cGweb6kbHAw",
            "book": "徒11-12 "
        },
        {
            "id": "325",
            "link": "https://www.youtube.com/watch?v=YYxzkLrJW5c",
            "book": "徒13-14 "
        },
        {
            "id": "326",
            "link": "https://www.youtube.com/watch?v=rSjLIBdSb9w",
            "book": "雅1-5 "
        },
        {
            "id": "327",
            "link": "https://www.youtube.com/watch?v=3kOmCRDZy80",
            "book": "徒15-16 "
        },
        {
            "id": "328",
            "link": "https://www.youtube.com/watch?v=wgxOBOPKJhM",
            "book": "加1-3 "
        },
        {
            "id": "329",
            "link": "https://www.youtube.com/watch?v=mHl6Z8VqloM",
            "book": "加4-6 "
        },
        {
            "id": "330",
            "link": "https://www.youtube.com/watch?v=qifQZN6XF5Q",
            "book": "徒17-18:18 "
        },
        {
            "id": "331",
            "link": "https://www.youtube.com/watch?v=k2qE5sOYp2w",
            "book": "帖前1-5 帖後1-3 "
        },
        {
            "id": "332",
            "link": "https://www.youtube.com/watch?v=N3vR3f-Y978",
            "book": "徒18:19-19:41 "
        },
        {
            "id": "333",
            "link": "https://www.youtube.com/watch?v=QlffSGW7fxQ",
            "book": "林前1-4 "
        },
        {
            "id": "334",
            "link": "https://www.youtube.com/watch?v=Op5urzqeORY",
            "book": "林前5-8 "
        },
        {
            "id": "335",
            "link": "https://www.youtube.com/watch?v=3iOgmkqTYfk",
            "book": "林前9-11 "
        },
        {
            "id": "336",
            "link": "https://www.youtube.com/watch?v=OEhq9m88It8",
            "book": "林前12-14 "
        },
        {
            "id": "337",
            "link": "https://www.youtube.com/watch?v=WQ2oTkJqjEc",
            "book": "林前15-16 "
        },
        {
            "id": "338",
            "link": "https://www.youtube.com/watch?v=uWQQckFnun0",
            "book": "林後1-4 "
        },
        {
            "id": "339",
            "link": "https://www.youtube.com/watch?v=pAtZaKTA9gA",
            "book": "林後5-9 "
        },
        {
            "id": "340",
            "link": "https://www.youtube.com/watch?v=4FIXb3WXxRU",
            "book": "林後10-13 "
        },
        {
            "id": "341",
            "link": "https://www.youtube.com/watch?v=rMRlGoEDPQE",
            "book": "徒20:1-3 羅1-3 "
        },
        {
            "id": "342",
            "link": "https://www.youtube.com/watch?v=Yf1zQ-ivtqk",
            "book": "羅4-7 "
        },
        {
            "id": "343",
            "link": "https://www.youtube.com/watch?v=RuQEFahBE4M",
            "book": "羅8-10 "
        },
        {
            "id": "344",
            "link": "https://www.youtube.com/watch?v=yV0yIDfTXuo",
            "book": "羅11-13 "
        },
        {
            "id": "345",
            "link": "https://www.youtube.com/watch?v=p1XVNrTVpUk",
            "book": "羅14-16 "
        },
        {
            "id": "346",
            "link": "https://www.youtube.com/watch?v=qhnHVEoaDi0",
            "book": "徒20:4-23:35 "
        },
        {
            "id": "347",
            "link": "https://www.youtube.com/watch?v=T3a0OCg-iEI",
            "book": "徒24-26 "
        },
        {
            "id": "348",
            "link": "https://www.youtube.com/watch?v=YVH0s15vSCk",
            "book": "徒27-28 "
        },
        {
            "id": "349",
            "link": "https://www.youtube.com/watch?v=hQgN48vSFKU",
            "book": "西1-4 門1 "
        },
        {
            "id": "350",
            "link": "https://www.youtube.com/watch?v=HAU_5Jwd9gg",
            "book": "弗1-6 "
        },
        {
            "id": "351",
            "link": "https://www.youtube.com/watch?v=I5GbZTLiRQs",
            "book": "腓1-4 "
        },
        {
            "id": "352",
            "link": "https://www.youtube.com/watch?v=JgwbLAChDe4",
            "book": "提前1-6 "
        },
        {
            "id": "353",
            "link": "https://www.youtube.com/watch?v=35A6EHJm3VY",
            "book": "多1-3 "
        },
        {
            "id": "354",
            "link": "https://www.youtube.com/watch?v=DhxqpFHwzBY",
            "book": "彼前1-5 "
        },
        {
            "id": "355",
            "link": "https://www.youtube.com/watch?v=x_v0Xz6wmv8",
            "book": "來1-6 "
        },
        {
            "id": "356",
            "link": "https://www.youtube.com/watch?v=6lm3h3KYv7I",
            "book": "來7-10 "
        },
        {
            "id": "357",
            "link": "https://www.youtube.com/watch?v=KejcYUelU9g",
            "book": "來11-13 "
        },
        {
            "id": "358",
            "link": "https://www.youtube.com/watch?v=Jyk4MQbNYUI",
            "book": "提後1-4 "
        },
        {
            "id": "359",
            "link": "https://www.youtube.com/watch?v=JgUUy0D343I",
            "book": "彼後1-3 猶1 "
        },
        {
            "id": "360",
            "link": "https://www.youtube.com/watch?v=ORLtiEbEWaU",
            "book": "約一1-5 "
        },
        {
            "id": "361",
            "link": "https://www.youtube.com/watch?v=ze-ANyQeQtQ",
            "book": "約二1 約三1 "
        },
        {
            "id": "362",
            "link": "https://www.youtube.com/watch?v=JOuNVmB9PNQ",
            "book": "啟1-5 "
        },
        {
            "id": "363",
            "link": "https://www.youtube.com/watch?v=yGqzKkMrMds",
            "book": "啟6-11 "
        },
        {
            "id": "364",
            "link": "https://www.youtube.com/watch?v=KT2kt7bKBRY",
            "book": "啟12-18 "
        },
        {
            "id": "365",
            "link": "https://www.youtube.com/watch?v=_bCX7pBRcDw",
            "book": "啟19-22 "
        }
    ];

    return dailyVerseList;
}


function loadDailyVerseOT(){
    var dailyVerseList = 
    [
        {
            "id": "1",
            "link": "https://www.youtube.com/watch?v=LnDicKCqPGI",
            "book": "創1-2"
        },
        {
            "id": "2",
            "link": "https://www.youtube.com/watch?v=uvEx-Jma0rQ",
            "book": "創3-5"
        },
        {
            "id": "3",
            "link": "https://www.youtube.com/watch?v=rT4ccM1P5PI",
            "book": "創6-9"
        },
        {
            "id": "4",
            "link": "https://www.youtube.com/watch?v=69v-yCRbWW8",
            "book": "創10-11"
        },
        {
            "id": "5",
            "link": "https://www.youtube.com/watch?v=qu8B_wtlgqk",
            "book": "創12-14"
        },
        {
            "id": "6",
            "link": "https://www.youtube.com/watch?v=QoX-H55ezmw",
            "book": "創15-16"
        },
        {
            "id": "7",
            "link": "https://www.youtube.com/watch?v=xXbGkSHDtPY",
            "book": "創17-18"
        },
        {
            "id": "8",
            "link": "https://www.youtube.com/watch?v=t5QMQIRqbXI",
            "book": "創19-21"
        },
        {
            "id": "9",
            "link": "https://www.youtube.com/watch?v=DwqFkteYaFE",
            "book": "創22-24"
        },
        {
            "id": "10",
            "link": "https://www.youtube.com/watch?v=iEWP17gxlQ8",
            "book": "創25-26"
        },
        {
            "id": "11",
            "link": "https://www.youtube.com/watch?v=rMDKJkim2OI",
            "book": "創27-31"
        },
        {
            "id": "12",
            "link": "https://www.youtube.com/watch?v=4qV1VUHdNFQ",
            "book": "創32-36"
        },
        {
            "id": "13",
            "link": "https://www.youtube.com/watch?v=hxdSvqo1DpE",
            "book": "創37-38"
        },
        {
            "id": "14",
            "link": "https://www.youtube.com/watch?v=5f1TWnGZtqM",
            "book": "創39-40"
        },
        {
            "id": "15",
            "link": "https://www.youtube.com/watch?v=8HsHvneC8uA",
            "book": "創41-44"
        },
        {
            "id": "16",
            "link": "https://www.youtube.com/watch?v=rNKJP60ucG8",
            "book": "創45-47"
        },
        {
            "id": "17",
            "link": "https://www.youtube.com/watch?v=ZHj8-3HZzzo",
            "book": "創48-50"
        },
        {
            "id": "18",
            "link": "https://www.youtube.com/watch?v=qy3J-2SffDo",
            "book": "出1-2"
        },
        {
            "id": "19",
            "link": "https://www.youtube.com/watch?v=8MMkxVv34IM",
            "book": "出3-6"
        },
        {
            "id": "20",
            "link": "https://www.youtube.com/watch?v=EalH7Da6BbA",
            "book": "出7-8"
        },
        {
            "id": "21",
            "link": "https://www.youtube.com/watch?v=tPKPveh_CZo",
            "book": "出9-10"
        },
        {
            "id": "22",
            "link": "https://www.youtube.com/watch?v=bzXRDbO7Tjc",
            "book": "出11-12"
        },
        {
            "id": "23",
            "link": "https://www.youtube.com/watch?v=BKboO83wsY4",
            "book": "出13-15"
        },
        {
            "id": "24",
            "link": "https://www.youtube.com/watch?v=8DpQY0Uwxlg",
            "book": "出16-18"
        },
        {
            "id": "25",
            "link": "https://www.youtube.com/watch?v=t1EUnJadm-w",
            "book": "出19-20"
        },
        {
            "id": "26",
            "link": "https://www.youtube.com/watch?v=yyir9Yl-7VY",
            "book": "出21-24"
        },
        {
            "id": "27",
            "link": "https://www.youtube.com/watch?v=2NpTXskMG3I",
            "book": "出25-26"
        },
        {
            "id": "28",
            "link": "https://www.youtube.com/watch?v=D3jj5E9XSZY",
            "book": "出27-28"
        },
        {
            "id": "29",
            "link": "https://www.youtube.com/watch?v=8DOGe30Ahck",
            "book": "出29-31"
        },
        {
            "id": "30",
            "link": "https://www.youtube.com/watch?v=FHwYVfn8kvQ",
            "book": "出32-34"
        },
        {
            "id": "31",
            "link": "https://www.youtube.com/watch?v=9dfjg9hCqaw",
            "book": "出35-40"
        },
        {
            "id": "32",
            "link": "https://www.youtube.com/watch?v=Y43Zomggcz8",
            "book": "利1-3"
        },
        {
            "id": "33",
            "link": "https://www.youtube.com/watch?v=eL2oUyYaRbU",
            "book": "利4-7"
        },
        {
            "id": "34",
            "link": "https://www.youtube.com/watch?v=cU_cIoxHSHU",
            "book": "利8-9"
        },
        {
            "id": "35",
            "link": "https://www.youtube.com/watch?v=TnYEGiIA6sc",
            "book": "利10-12"
        },
        {
            "id": "36",
            "link": "https://www.youtube.com/watch?v=lTuA-TKSvWE",
            "book": "利13-15"
        },
        {
            "id": "37",
            "link": "https://www.youtube.com/watch?v=EtubsgI0Bu8",
            "book": "利16-17"
        },
        {
            "id": "38",
            "link": "https://www.youtube.com/watch?v=WhT75A3xjW8",
            "book": "利18-20"
        },
        {
            "id": "39",
            "link": "https://www.youtube.com/watch?v=O_X-7vkr6D0",
            "book": "利21-23"
        },
        {
            "id": "40",
            "link": "https://www.youtube.com/watch?v=pNDs3bANAg4",
            "book": "利24-27"
        },
        {
            "id": "41",
            "link": "https://www.youtube.com/watch?v=Y7GO9VBzPTE",
            "book": "民1-3"
        },
        {
            "id": "42",
            "link": "https://www.youtube.com/watch?v=bl2OO14ymEo",
            "book": "民4-5"
        },
        {
            "id": "43",
            "link": "https://www.youtube.com/watch?v=_snViX8BT_I",
            "book": "民6-8"
        },
        {
            "id": "44",
            "link": "https://www.youtube.com/watch?v=CpOYIDYDEzI",
            "book": "民9-12"
        },
        {
            "id": "45",
            "link": "https://www.youtube.com/watch?v=pOdoiE5sGxQ",
            "book": "民13-16"
        },
        {
            "id": "46",
            "link": "https://www.youtube.com/watch?v=i1HRXLjQhpY",
            "book": "民17-20"
        },
        {
            "id": "47",
            "link": "https://www.youtube.com/watch?v=4hy_vHJJ-Lc",
            "book": "民21-25"
        },
        {
            "id": "48",
            "link": "https://www.youtube.com/watch?v=elm5QJDPe4o",
            "book": "民26-28"
        },
        {
            "id": "49",
            "link": "https://www.youtube.com/watch?v=KVvLc848vOY",
            "book": "民29-30"
        },
        {
            "id": "50",
            "link": "https://www.youtube.com/watch?v=Z7MEPF07Kfk",
            "book": "民31-33"
        },
        {
            "id": "51",
            "link": "https://www.youtube.com/watch?v=UL1EvnEwQSY",
            "book": "民34-36"
        },
        {
            "id": "52",
            "link": "https://www.youtube.com/watch?v=jfB8TXTt3FY",
            "book": "申1-4"
        },
        {
            "id": "53",
            "link": "https://www.youtube.com/watch?v=rTVTlEUSGso",
            "book": "申5-7"
        },
        {
            "id": "54",
            "link": "https://www.youtube.com/watch?v=YSb4Q14bf40",
            "book": "申8-11"
        },
        {
            "id": "55",
            "link": "https://www.youtube.com/watch?v=hrjjj15xFYo",
            "book": "申12-16"
        },
        {
            "id": "56",
            "link": "https://www.youtube.com/watch?v=NybwpqtkXxA",
            "book": "申17-20"
        },
        {
            "id": "57",
            "link": "https://www.youtube.com/watch?v=VyR5tsASyC8",
            "book": "申21-26"
        },
        {
            "id": "58",
            "link": "https://www.youtube.com/watch?v=Pvp1L_oN1N0",
            "book": "申27-30"
        },
        {
            "id": "59",
            "link": "https://www.youtube.com/watch?v=NfAX7i2-ltk",
            "book": "申31-34"
        },
        {
            "id": "60",
            "link": "https://www.youtube.com/watch?v=44ogPOjh7G8",
            "book": "書1-5"
        },
        {
            "id": "61",
            "link": "https://www.youtube.com/watch?v=wrq-wRN2Q38",
            "book": "書6-8"
        },
        {
            "id": "62",
            "link": "https://www.youtube.com/watch?v=Pi-_q5Nolkg",
            "book": "書9-11"
        },
        {
            "id": "63",
            "link": "https://www.youtube.com/watch?v=rVy5vu3FmNU",
            "book": "書12-13"
        },
        {
            "id": "64",
            "link": "https://www.youtube.com/watch?v=iRSZng8CzU8",
            "book": "書14-17"
        },
        {
            "id": "65",
            "link": "https://www.youtube.com/watch?v=AIzO-en0xag",
            "book": "書18-21"
        },
        {
            "id": "66",
            "link": "https://www.youtube.com/watch?v=T94liW8C1O8",
            "book": "書22-24"
        },
        {
            "id": "67",
            "link": "https://www.youtube.com/watch?v=LfgfuEN5Xis",
            "book": "士1-5"
        },
        {
            "id": "68",
            "link": "https://www.youtube.com/watch?v=yEQv2TFVTDk",
            "book": "士6-8"
        },
        {
            "id": "69",
            "link": "https://www.youtube.com/watch?v=wh0NlqhOTPA",
            "book": "士9-11"
        },
        {
            "id": "70",
            "link": "https://www.youtube.com/watch?v=aNqW-tRiAgk",
            "book": "士12_13"
        },
        {
            "id": "71",
            "link": "https://www.youtube.com/watch?v=Fa7X0yy490A",
            "book": "士14-16"
        },
        {
            "id": "72",
            "link": "https://www.youtube.com/watch?v=H4NnNcw1rVg",
            "book": "士17-21"
        },
        {
            "id": "73",
            "link": "https://www.youtube.com/watch?v=coO4XXHmrZ8",
            "book": "得1-4"
        },
        {
            "id": "74",
            "link": "https://www.youtube.com/watch?v=2JjaVX5kMv8",
            "book": "撒上1-3"
        },
        {
            "id": "75",
            "link": "https://www.youtube.com/watch?v=OcNZcb8MsOc",
            "book": "撒上4-8"
        },
        {
            "id": "76",
            "link": "https://www.youtube.com/watch?v=Kv9Auy1v_BE",
            "book": "撒上9-10"
        },
        {
            "id": "77",
            "link": "https://www.youtube.com/watch?v=mPaUqmbN-nU",
            "book": "撒上11-12"
        },
        {
            "id": "78",
            "link": "https://www.youtube.com/watch?v=D_KVq0duPxI",
            "book": "撒上13-15"
        },
        {
            "id": "79",
            "link": "https://www.youtube.com/watch?v=FvrXVR3_DuQ",
            "book": "撒上16-19"
        },
        {
            "id": "80",
            "link": "https://www.youtube.com/watch?v=c93KKhGF60U",
            "book": "撒上20-23"
        },
        {
            "id": "81",
            "link": "https://www.youtube.com/watch?v=U17GJ05VnTs",
            "book": "撒上24-26"
        },
        {
            "id": "82",
            "link": "https://www.youtube.com/watch?v=TZBKyKeCOHg",
            "book": "撒上27-31"
        },
        {
            "id": "83",
            "link": "https://www.youtube.com/watch?v=gxNU67CzwII",
            "book": "撒下1-2"
        },
        {
            "id": "84",
            "link": "https://www.youtube.com/watch?v=KBmG3fkdrSc",
            "book": "撒下3-4"
        },
        {
            "id": "85",
            "link": "https://www.youtube.com/watch?v=jJmtjjzLcyk",
            "book": "撒下5-7"
        },
        {
            "id": "86",
            "link": "https://www.youtube.com/watch?v=eBRJvtvKGL0",
            "book": "撒下8-10"
        },
        {
            "id": "87",
            "link": "https://www.youtube.com/watch?v=37ETUU0syTw",
            "book": "撒下11-14"
        },
        {
            "id": "88",
            "link": "https://www.youtube.com/watch?v=Dvt1rUqTL34",
            "book": "撒下15-18"
        },
        {
            "id": "89",
            "link": "https://www.youtube.com/watch?v=j5XCBtMFlto",
            "book": "撒下19-20"
        },
        {
            "id": "90",
            "link": "https://www.youtube.com/watch?v=YDf23fpD0H8",
            "book": "撒下21-24"
        },
        {
            "id": "91",
            "link": "https://www.youtube.com/watch?v=pvQa6dTQEy4",
            "book": "王上1-4"
        },
        {
            "id": "92",
            "link": "https://www.youtube.com/watch?v=xAwLFhstDQ8",
            "book": "王上5-8"
        },
        {
            "id": "93",
            "link": "https://www.youtube.com/watch?v=Slfyqiv5VRs",
            "book": "王上9-11"
        },
        {
            "id": "94",
            "link": "https://www.youtube.com/watch?v=WCktQBL-NHE",
            "book": "王上12-16"
        },
        {
            "id": "95",
            "link": "https://www.youtube.com/watch?v=VqMu0DT1_Hc",
            "book": "王上17-19"
        },
        {
            "id": "96",
            "link": "https://www.youtube.com/watch?v=iYedBJuUS0M",
            "book": "王上20-22"
        },
        {
            "id": "97",
            "link": "https://www.youtube.com/watch?v=0Nmm5HTOnYQ",
            "book": "王下1-2"
        },
        {
            "id": "98",
            "link": "https://www.youtube.com/watch?v=vLJ4010YMdw",
            "book": "王下3-4"
        },
        {
            "id": "99",
            "link": "https://www.youtube.com/watch?v=9hI_WMTtP04",
            "book": "王下5-8"
        },
        {
            "id": "100",
            "link": "https://www.youtube.com/watch?v=jW-doN9hWKE",
            "book": "王下9-12"
        },
        {
            "id": "101",
            "link": "https://www.youtube.com/watch?v=bHrkbJY4KXY",
            "book": "王下13-17"
        },
        {
            "id": "102",
            "link": "https://www.youtube.com/watch?v=CWQ3qRZVUXk",
            "book": "王下18-21"
        },
        {
            "id": "103",
            "link": "https://www.youtube.com/watch?v=61t2jT6dWHk",
            "book": "王下22-25"
        },
        {
            "id": "104",
            "link": "https://www.youtube.com/watch?v=ScfORgs-0KY",
            "book": "代上1-5"
        },
        {
            "id": "105",
            "link": "https://www.youtube.com/watch?v=CMg8jZsIxJI",
            "book": "代上6-9"
        },
        {
            "id": "106",
            "link": "https://www.youtube.com/watch?v=QuDgJq3P2M4",
            "book": "代上10-16"
        },
        {
            "id": "107",
            "link": "https://www.youtube.com/watch?v=zryz-xfqNug",
            "book": "代上17-21"
        },
        {
            "id": "108",
            "link": "https://www.youtube.com/watch?v=aTmlJJjJMEo",
            "book": "代上22-27"
        },
        {
            "id": "109",
            "link": "https://www.youtube.com/watch?v=m4I7gVqtIl8",
            "book": "代上28-29"
        },
        {
            "id": "110",
            "link": "https://www.youtube.com/watch?v=JvGIxlONOEM",
            "book": "代下1-5"
        },
        {
            "id": "111",
            "link": "https://www.youtube.com/watch?v=0TjSyj3VTf0",
            "book": "代下6-7"
        },
        {
            "id": "112",
            "link": "https://www.youtube.com/watch?v=-W-A62jYHdU",
            "book": "代下8-9"
        },
        {
            "id": "113",
            "link": "https://www.youtube.com/watch?v=-CQg0QUykLo",
            "book": "代下10-12"
        },
        {
            "id": "114",
            "link": "https://www.youtube.com/watch?v=1OeCPRSyE8I",
            "book": "代下13-16"
        },
        {
            "id": "115",
            "link": "https://www.youtube.com/watch?v=CbODo-IQG7U",
            "book": "代下17-20"
        },
        {
            "id": "116",
            "link": "https://www.youtube.com/watch?v=yVHv0YyherU",
            "book": "代下21-25"
        },
        {
            "id": "117",
            "link": "https://www.youtube.com/watch?v=JCcB-6piTjM",
            "book": "代下26-28"
        },
        {
            "id": "118",
            "link": "https://www.youtube.com/watch?v=EXdD7qP1QKc",
            "book": "代下29-30"
        },
        {
            "id": "119",
            "link": "https://www.youtube.com/watch?v=XW3ibKrq8z8",
            "book": "代下31-32"
        },
        {
            "id": "120",
            "link": "https://www.youtube.com/watch?v=p6yQ6tvu_8Y",
            "book": "代下33-36"
        },
        {
            "id": "121",
            "link": "https://www.youtube.com/watch?v=4nXFpLUHHQU",
            "book": "拉1-3"
        },
        {
            "id": "122",
            "link": "https://www.youtube.com/watch?v=G_8XkzwWAoE",
            "book": "拉4-6"
        },
        {
            "id": "123",
            "link": "https://www.youtube.com/watch?v=L5RQwdlLHgk",
            "book": "拉7-8"
        },
        {
            "id": "124",
            "link": "https://www.youtube.com/watch?v=ZnSfKk5tOoU",
            "book": "拉9-10"
        },
        {
            "id": "125",
            "link": "https://www.youtube.com/watch?v=UNLK2yysIXk",
            "book": "尼1-2"
        },
        {
            "id": "126",
            "link": "https://www.youtube.com/watch?v=pQrvqd84Ow8",
            "book": "尼3-4"
        },
        {
            "id": "127",
            "link": "https://www.youtube.com/watch?v=11JnySFFSgg",
            "book": "尼5-6"
        },
        {
            "id": "128",
            "link": "https://www.youtube.com/watch?v=UaIdAdWLSk8",
            "book": "尼7-8"
        },
        {
            "id": "129",
            "link": "https://www.youtube.com/watch?v=eihboz6zYB4",
            "book": "尼9-10"
        },
        {
            "id": "130",
            "link": "https://www.youtube.com/watch?v=ywl5pWibhxc",
            "book": "尼11-13"
        },
        {
            "id": "131",
            "link": "https://www.youtube.com/watch?v=vV8xRxuyfds",
            "book": "斯1-2"
        },
        {
            "id": "132",
            "link": "https://www.youtube.com/watch?v=LX5v7ng_9qM",
            "book": "斯3-4"
        },
        {
            "id": "133",
            "link": "https://www.youtube.com/watch?v=pwi3rgerMdc",
            "book": "斯5-6"
        },
        {
            "id": "134",
            "link": "https://www.youtube.com/watch?v=7JOvhGfkcMQ",
            "book": "斯7-8"
        },
        {
            "id": "135",
            "link": "https://www.youtube.com/watch?v=z1uwrnzg7YA",
            "book": "斯9-10"
        },
        {
            "id": "136",
            "link": "https://www.youtube.com/watch?v=Ko7rmOS7lj4",
            "book": "伯1-3"
        },
        {
            "id": "137",
            "link": "https://www.youtube.com/watch?v=B3CptGwiEqw",
            "book": "伯4-7"
        },
        {
            "id": "138",
            "link": "https://www.youtube.com/watch?v=VnQ1PgVSl4A",
            "book": "伯8-10"
        },
        {
            "id": "139",
            "link": "https://www.youtube.com/watch?v=E-v-j_-1aDo",
            "book": "伯11-12"
        },
        {
            "id": "140",
            "link": "https://www.youtube.com/watch?v=AE3QzNOLoXk",
            "book": "伯13-14"
        },
        {
            "id": "141",
            "link": "https://www.youtube.com/watch?v=hZXtZKo0ekk",
            "book": "伯15-17"
        },
        {
            "id": "142",
            "link": "https://www.youtube.com/watch?v=SUA8iylAz1Q",
            "book": "伯18-19"
        },
        {
            "id": "143",
            "link": "https://www.youtube.com/watch?v=Pn7WSvylaEU",
            "book": "伯20-21"
        },
        {
            "id": "144",
            "link": "https://www.youtube.com/watch?v=dDnMLKs_giI",
            "book": "伯22-24"
        },
        {
            "id": "145",
            "link": "https://www.youtube.com/watch?v=eTnsMGAEVKk",
            "book": "伯25-28"
        },
        {
            "id": "146",
            "link": "https://www.youtube.com/watch?v=NYJWP8t08x0",
            "book": "伯29-30"
        },
        {
            "id": "147",
            "link": "https://www.youtube.com/watch?v=F716HAGDlig",
            "book": "伯31-32"
        },
        {
            "id": "148",
            "link": "https://www.youtube.com/watch?v=hJACoxpQakQ",
            "book": "伯33-34"
        },
        {
            "id": "149",
            "link": "https://www.youtube.com/watch?v=aKcnu3t-gP4",
            "book": "伯35-37"
        },
        {
            "id": "150",
            "link": "https://www.youtube.com/watch?v=5q5MLkC8aCU",
            "book": "伯38-39"
        },
        {
            "id": "151",
            "link": "https://www.youtube.com/watch?v=Fo3Aw9j7eOo",
            "book": "伯40-42"
        },
        {
            "id": "152",
            "link": "https://www.youtube.com/watch?v=nCwSOdG4WSg",
            "book": "詩1-6"
        },
        {
            "id": "153",
            "link": "https://www.youtube.com/watch?v=QYe4am7718Y",
            "book": "詩7-9"
        },
        {
            "id": "154",
            "link": "https://www.youtube.com/watch?v=3yBurZT95rw",
            "book": "詩10-12"
        },
        {
            "id": "155",
            "link": "https://www.youtube.com/watch?v=YG4oWgI9uKM",
            "book": "詩13-18"
        },
        {
            "id": "156",
            "link": "https://www.youtube.com/watch?v=s3VqwYFCMbQ",
            "book": "詩19-24"
        },
        {
            "id": "157",
            "link": "https://www.youtube.com/watch?v=FgJ8BsgTXQk",
            "book": "詩25-30"
        },
        {
            "id": "158",
            "link": "https://www.youtube.com/watch?v=KpvhEDI0DOc",
            "book": "詩31-35"
        },
        {
            "id": "159",
            "link": "https://www.youtube.com/watch?v=HtIscvLTpWU",
            "book": "詩36-41"
        },
        {
            "id": "160",
            "link": "https://www.youtube.com/watch?v=-pI8sMjRQLs",
            "book": "詩42-45"
        },
        {
            "id": "161",
            "link": "https://www.youtube.com/watch?v=sWnrSXqhIO0",
            "book": "詩46-49"
        },
        {
            "id": "162",
            "link": "https://www.youtube.com/watch?v=Fpc5XvjVZU8",
            "book": "詩50-54"
        },
        {
            "id": "163",
            "link": "https://www.youtube.com/watch?v=Ra1-JY1csMQ",
            "book": "詩55-59"
        },
        {
            "id": "164",
            "link": "https://www.youtube.com/watch?v=TvQxTpw-a8g",
            "book": "詩60-66"
        },
        {
            "id": "165",
            "link": "https://www.youtube.com/watch?v=WDfCfmNj_84",
            "book": "詩67-72"
        },
        {
            "id": "166",
            "link": "https://www.youtube.com/watch?v=h7EDn84jqRQ",
            "book": "詩73-77"
        },
        {
            "id": "167",
            "link": "https://www.youtube.com/watch?v=E8IwJ2l8sYk",
            "book": "詩78-80"
        },
        {
            "id": "168",
            "link": "https://www.youtube.com/watch?v=od1ZQddW3E4",
            "book": "詩81-83"
        },
        {
            "id": "169",
            "link": "https://www.youtube.com/watch?v=JJRa1Wt-EYA",
            "book": "詩84-89"
        },
        {
            "id": "170",
            "link": "https://www.youtube.com/watch?v=B2Tuf0Fd2ts",
            "book": "詩90-97"
        },
        {
            "id": "171",
            "link": "https://www.youtube.com/watch?v=XpUaiLzQR98",
            "book": "詩98-103"
        },
        {
            "id": "172",
            "link": "https://www.youtube.com/watch?v=Y0p52sSRN8w",
            "book": "詩104-106"
        },
        {
            "id": "173",
            "link": "https://www.youtube.com/watch?v=MgAZBV3AGRY",
            "book": "詩107-110"
        },
        {
            "id": "174",
            "link": "https://www.youtube.com/watch?v=3Q8qj5WB0e4",
            "book": "詩111-114"
        },
        {
            "id": "175",
            "link": "https://www.youtube.com/watch?v=pimNtx0YBgs",
            "book": "詩115-118"
        },
        {
            "id": "176",
            "link": "https://www.youtube.com/watch?v=_bXiH-Q5B-Y",
            "book": "詩119"
        },
        {
            "id": "177",
            "link": "https://www.youtube.com/watch?v=wbKl0mbrbh8",
            "book": "詩120-127"
        },
        {
            "id": "178",
            "link": "https://www.youtube.com/watch?v=zZG9QPmmBvU",
            "book": "詩128-134"
        },
        {
            "id": "179",
            "link": "https://www.youtube.com/watch?v=jtrM7HDZi0M",
            "book": "詩135-139"
        },
        {
            "id": "180",
            "link": "https://www.youtube.com/watch?v=RMtlyw-T49Q",
            "book": "詩140-145"
        },
        {
            "id": "181",
            "link": "https://www.youtube.com/watch?v=t1qLHdGwN9I",
            "book": "詩146-150"
        },
        {
            "id": "182",
            "link": "https://www.youtube.com/watch?v=ffEmhApewTU",
            "book": "箴1-4"
        },
        {
            "id": "183",
            "link": "https://www.youtube.com/watch?v=B8bo7hPbmuo",
            "book": "箴5-9"
        },
        {
            "id": "184",
            "link": "https://www.youtube.com/watch?v=ZXobgyvOVQk",
            "book": "箴10-13"
        },
        {
            "id": "185",
            "link": "https://www.youtube.com/watch?v=vVPAIxUr1uo",
            "book": "箴14-17"
        },
        {
            "id": "186",
            "link": "https://www.youtube.com/watch?v=uRNPXTBCcng",
            "book": "箴18-21"
        },
        {
            "id": "187",
            "link": "https://www.youtube.com/watch?v=B2hlp8kEYjs",
            "book": "箴22-24"
        },
        {
            "id": "188",
            "link": "https://www.youtube.com/watch?v=QA6DDo6a85A",
            "book": "箴25-27"
        },
        {
            "id": "189",
            "link": "https://www.youtube.com/watch?v=RrtvdD_jQ5w",
            "book": "箴28-29"
        },
        {
            "id": "190",
            "link": "https://www.youtube.com/watch?v=d_SOvkDTsJQ",
            "book": "箴30-31"
        },
        {
            "id": "191",
            "link": "https://www.youtube.com/watch?v=B5BNRlukl0o",
            "book": "傳1-6"
        },
        {
            "id": "192",
            "link": "https://www.youtube.com/watch?v=5zkohtsRIrc",
            "book": "傳7-12"
        },
        {
            "id": "193",
            "link": "https://www.youtube.com/watch?v=chip-3iv4TY",
            "book": "歌1-8"
        },
        {
            "id": "194",
            "link": "https://www.youtube.com/watch?v=BOb-GhNOUr0",
            "book": "賽1-4"
        },
        {
            "id": "195",
            "link": "https://www.youtube.com/watch?v=GgAwhMu2qXM",
            "book": "賽5-6"
        },
        {
            "id": "196",
            "link": "https://www.youtube.com/watch?v=4h2d5I_8b9Y",
            "book": "賽7-8"
        },
        {
            "id": "197",
            "link": "https://www.youtube.com/watch?v=Ge4CM4WkTuI",
            "book": "賽9-12"
        },
        {
            "id": "198",
            "link": "https://www.youtube.com/watch?v=YJN7D2LK3nQ",
            "book": "賽13-14"
        },
        {
            "id": "199",
            "link": "https://www.youtube.com/watch?v=iJOQMfQu0RQ",
            "book": "賽15-16"
        },
        {
            "id": "200",
            "link": "https://www.youtube.com/watch?v=ddIeAt1ilo4",
            "book": "賽17-20"
        },
        {
            "id": "201",
            "link": "https://www.youtube.com/watch?v=cbuiz-ss7v0",
            "book": "賽21-23"
        },
        {
            "id": "202",
            "link": "https://www.youtube.com/watch?v=XU6d7NAZJ2k",
            "book": "賽24-27"
        },
        {
            "id": "203",
            "link": "https://www.youtube.com/watch?v=RmN9Eeigvfw",
            "book": "賽28-29"
        },
        {
            "id": "204",
            "link": "https://www.youtube.com/watch?v=dnoRsPjv33Q",
            "book": "賽30-31"
        },
        {
            "id": "205",
            "link": "https://www.youtube.com/watch?v=n7WL7XYVBEU",
            "book": "賽32-35"
        },
        {
            "id": "206",
            "link": "https://www.youtube.com/watch?v=9aYcGrLcLqE",
            "book": "賽36-39"
        },
        {
            "id": "207",
            "link": "https://www.youtube.com/watch?v=xYzabYWNjvM",
            "book": "賽40-43"
        },
        {
            "id": "208",
            "link": "https://www.youtube.com/watch?v=AQPYJ1v3WeQ",
            "book": "賽44-48"
        },
        {
            "id": "209",
            "link": "https://www.youtube.com/watch?v=OzJY1DJu7sM",
            "book": "賽49-51"
        },
        {
            "id": "210",
            "link": "https://www.youtube.com/watch?v=SwU_vCVmVmc",
            "book": "賽52-54"
        },
        {
            "id": "211",
            "link": "https://www.youtube.com/watch?v=NaFMJO0T3Nk",
            "book": "賽55-57"
        },
        {
            "id": "212",
            "link": "https://www.youtube.com/watch?v=iHtrC0FGg5U",
            "book": "賽58-62"
        },
        {
            "id": "213",
            "link": "https://www.youtube.com/watch?v=QbLV4WieBm4",
            "book": "賽63-66"
        },
        {
            "id": "214",
            "link": "https://www.youtube.com/watch?v=DU5vtNNCcbE",
            "book": "耶1-3"
        },
        {
            "id": "215",
            "link": "https://www.youtube.com/watch?v=KVCOVSHSGcA",
            "book": "耶4-6"
        },
        {
            "id": "216",
            "link": "https://www.youtube.com/watch?v=ksVr9fvZgsQ",
            "book": "耶7-10"
        },
        {
            "id": "217",
            "link": "https://www.youtube.com/watch?v=kyWQ0s0T6YM",
            "book": "耶11-13"
        },
        {
            "id": "218",
            "link": "https://www.youtube.com/watch?v=zex1WkQf1j0",
            "book": "耶14-16"
        },
        {
            "id": "219",
            "link": "https://www.youtube.com/watch?v=jtneRX0rpuA",
            "book": "耶17-20"
        },
        {
            "id": "220",
            "link": "https://www.youtube.com/watch?v=0H3O6MTRRwY",
            "book": "耶21-25"
        },
        {
            "id": "221",
            "link": "https://www.youtube.com/watch?v=5B2eKa0vmRc",
            "book": "耶26-29"
        },
        {
            "id": "222",
            "link": "https://www.youtube.com/watch?v=k9TtcKuapsY",
            "book": "耶30-33"
        },
        {
            "id": "223",
            "link": "https://www.youtube.com/watch?v=Zmjo65V2tyA",
            "book": "耶34-39"
        },
        {
            "id": "224",
            "link": "https://www.youtube.com/watch?v=6HIt--8-QsM",
            "book": "耶40-42"
        },
        {
            "id": "225",
            "link": "https://www.youtube.com/watch?v=UrAxp_heAlQ",
            "book": "耶43-45 1"
        },
        {
            "id": "226",
            "link": "https://www.youtube.com/watch?v=rpP1AW3rqfU",
            "book": "耶46-49"
        },
        {
            "id": "227",
            "link": "https://www.youtube.com/watch?v=lmhk1jpKK0c",
            "book": "耶50-52"
        },
        {
            "id": "228",
            "link": "https://www.youtube.com/watch?v=lOK04rR2Irw",
            "book": "哀1-5"
        },
        {
            "id": "229",
            "link": "https://www.youtube.com/watch?v=Yi2zAFabDZc",
            "book": "結1-6"
        },
        {
            "id": "230",
            "link": "https://www.youtube.com/watch?v=TGPNnILrNtc",
            "book": "結7-11"
        },
        {
            "id": "231",
            "link": "https://www.youtube.com/watch?v=HUYVbOCCE18",
            "book": "結12-14"
        },
        {
            "id": "232",
            "link": "https://www.youtube.com/watch?v=b_LN6KFTzzc",
            "book": "結15-16"
        },
        {
            "id": "233",
            "link": "https://www.youtube.com/watch?v=5MMIVJNK1eE",
            "book": "結17-19"
        },
        {
            "id": "234",
            "link": "https://www.youtube.com/watch?v=5qatBISdes0",
            "book": "結20-23"
        },
        {
            "id": "235",
            "link": "https://www.youtube.com/watch?v=F9mt3lxvNfE",
            "book": "結24-28"
        },
        {
            "id": "236",
            "link": "https://www.youtube.com/watch?v=7nrTqFzvdz8",
            "book": "結29-32"
        },
        {
            "id": "237",
            "link": "https://www.youtube.com/watch?v=2dtk6bM6FLk",
            "book": "結33-36"
        },
        {
            "id": "238",
            "link": "https://www.youtube.com/watch?v=-Zowr3Gx4ak",
            "book": "結37-38"
        },
        {
            "id": "239",
            "link": "https://www.youtube.com/watch?v=q4J2H6KOGkk",
            "book": "結39-40"
        },
        {
            "id": "240",
            "link": "https://www.youtube.com/watch?v=oSYWqgDTA8Y",
            "book": "結41-43"
        },
        {
            "id": "241",
            "link": "https://www.youtube.com/watch?v=laParj0xfwQ",
            "book": "結44-48"
        },
        {
            "id": "242",
            "link": "https://www.youtube.com/watch?v=sRH5siidgM0",
            "book": "但1-3"
        },
        {
            "id": "243",
            "link": "https://www.youtube.com/watch?v=ssI7agO56ao",
            "book": "但4-6"
        },
        {
            "id": "244",
            "link": "https://www.youtube.com/watch?v=j2ZSyG2T6sA",
            "book": "但7-12"
        },
        {
            "id": "245",
            "link": "https://www.youtube.com/watch?v=u2tqt4_xVME",
            "book": "何1-2"
        },
        {
            "id": "246",
            "link": "https://www.youtube.com/watch?v=Ip3FdSKcnYM",
            "book": "何3-4"
        },
        {
            "id": "247",
            "link": "https://www.youtube.com/watch?v=0kbYQrTPxAM",
            "book": "何5-6"
        },
        {
            "id": "248",
            "link": "https://www.youtube.com/watch?v=xShbhF70cL0",
            "book": "何7-8"
        },
        {
            "id": "249",
            "link": "https://www.youtube.com/watch?v=0vC4kakmTKA",
            "book": "何9-11"
        },
        {
            "id": "250",
            "link": "https://www.youtube.com/watch?v=AmY_ib3IsgQ",
            "book": "何12-14"
        },
        {
            "id": "251",
            "link": "https://www.youtube.com/watch?v=zlrS0niPf4s",
            "book": "珥1-3"
        },
        {
            "id": "252",
            "link": "https://www.youtube.com/watch?v=wwj2KSSUrX8",
            "book": "摩1-2"
        },
        {
            "id": "253",
            "link": "https://www.youtube.com/watch?v=Bl1toWID6aI",
            "book": "摩3"
        },
        {
            "id": "254",
            "link": "https://www.youtube.com/watch?v=Y2WFEObwFGE",
            "book": "摩4-5"
        },
        {
            "id": "255",
            "link": "https://www.youtube.com/watch?v=-l2XIBMglS4",
            "book": "摩6-7"
        },
        {
            "id": "256",
            "link": "https://www.youtube.com/watch?v=UO2lFOmo_Iw",
            "book": "摩8-9"
        },
        {
            "id": "257",
            "link": "https://www.youtube.com/watch?v=0ULQ4LhvMsY",
            "book": "俄1"
        },
        {
            "id": "258",
            "link": "https://www.youtube.com/watch?v=eJ4zzB318B4",
            "book": "拿1-4"
        },
        {
            "id": "259",
            "link": "https://www.youtube.com/watch?v=6mHlEOr7e20",
            "book": "彌1-2"
        },
        {
            "id": "260",
            "link": "https://www.youtube.com/watch?v=H0HFau12JVA",
            "book": "彌3"
        },
        {
            "id": "261",
            "link": "https://www.youtube.com/watch?v=V-t_UFslHnM",
            "book": "彌4-5"
        },
        {
            "id": "262",
            "link": "https://www.youtube.com/watch?v=PCqiz8ugnFc",
            "book": "彌6-7"
        },
        {
            "id": "263",
            "link": "https://www.youtube.com/watch?v=0lY3hmR08PE",
            "book": "鴻1-3"
        },
        {
            "id": "264",
            "link": "https://www.youtube.com/watch?v=EQD0V6NEO4Q",
            "book": "哈1-3"
        },
        {
            "id": "265",
            "link": "https://www.youtube.com/watch?v=wRQEMV5ojPM",
            "book": "番1-3"
        },
        {
            "id": "266",
            "link": "https://www.youtube.com/watch?v=2CSp0DeosEM",
            "book": "該1-2"
        },
        {
            "id": "267",
            "link": "https://www.youtube.com/watch?v=avG4hWf0WtA",
            "book": "亞1-2"
        },
        {
            "id": "268",
            "link": "https://www.youtube.com/watch?v=FvILzf50xdM",
            "book": "亞3-4"
        },
        {
            "id": "269",
            "link": "https://www.youtube.com/watch?v=UcICwKdt_HA",
            "book": "亞5-6"
        },
        {
            "id": "270",
            "link": "https://www.youtube.com/watch?v=wS_1AqopkVc",
            "book": "亞7-8"
        },
        {
            "id": "271",
            "link": "https://www.youtube.com/watch?v=M4gR93CVpGM",
            "book": "亞9-11"
        },
        {
            "id": "272",
            "link": "https://www.youtube.com/watch?v=qcyKczemp8o",
            "book": "亞12-14"
        },
        {
            "id": "273",
            "link": "https://www.youtube.com/watch?v=8sWE0tSUw4s",
            "book": "瑪1-4"
        },
        {
            "id": "274",
            "link": "https://www.youtube.com/watch?v=TLDD4cto4Dw",
            "book": "太1-4"
        },
        {
            "id": "275",
            "link": "https://www.youtube.com/watch?v=OVkBaWChXYc",
            "book": "太5-7"
        },
        {
            "id": "276",
            "link": "https://www.youtube.com/watch?v=eYxpNA3AanA",
            "book": "太8-11"
        },
        {
            "id": "277",
            "link": "https://www.youtube.com/watch?v=A-mEsM_EU7w",
            "book": "太12-15"
        },
        {
            "id": "278",
            "link": "https://www.youtube.com/watch?v=4GujV68z2jQ",
            "book": "太16-19"
        },
        {
            "id": "279",
            "link": "https://www.youtube.com/watch?v=PPHWa60j434",
            "book": "太20-21"
        },
        {
            "id": "280",
            "link": "https://www.youtube.com/watch?v=goiK5ygabjw",
            "book": "太22-23"
        },
        {
            "id": "281",
            "link": "https://www.youtube.com/watch?v=4JddJ1JEx2M",
            "book": "太24-25"
        },
        {
            "id": "282",
            "link": "https://www.youtube.com/watch?v=CUsdTecY1aA",
            "book": "太26-28"
        },
        {
            "id": "283",
            "link": "https://www.youtube.com/watch?v=GDZKpZyoSY4",
            "book": "可1-3"
        },
        {
            "id": "284",
            "link": "https://www.youtube.com/watch?v=HfA53x3kI-Q",
            "book": "可4-7"
        },
        {
            "id": "285",
            "link": "https://www.youtube.com/watch?v=Por-6s_o71I",
            "book": "可8-10"
        },
        {
            "id": "286",
            "link": "https://www.youtube.com/watch?v=-5EYStBPdiY",
            "book": "可11-12"
        },
        {
            "id": "287",
            "link": "https://www.youtube.com/watch?v=ptbQmnHbYL8",
            "book": "可13-14"
        },
        {
            "id": "288",
            "link": "https://www.youtube.com/watch?v=QZb0m68zobo",
            "book": "可15-16"
        },
        {
            "id": "289",
            "link": "https://www.youtube.com/watch?v=v64Pqq6qoyc",
            "book": "路1-2"
        },
        {
            "id": "290",
            "link": "https://www.youtube.com/watch?v=dMreNd0HZRQ",
            "book": "路3-6"
        },
        {
            "id": "291",
            "link": "https://www.youtube.com/watch?v=Ls6irYImmas",
            "book": "路7-9"
        },
        {
            "id": "292",
            "link": "https://www.youtube.com/watch?v=MV0GEX1R8xE",
            "book": "路10-11"
        },
        {
            "id": "293",
            "link": "https://www.youtube.com/watch?v=k7vCM3sh7Bk",
            "book": "路12-13"
        },
        {
            "id": "294",
            "link": "https://www.youtube.com/watch?v=dO40eW7ce3w",
            "book": "路14-15"
        },
        {
            "id": "295",
            "link": "https://www.youtube.com/watch?v=jplCwLJJYZ8",
            "book": "路16-18"
        },
        {
            "id": "296",
            "link": "https://www.youtube.com/watch?v=ld8xM_rY61s",
            "book": "路19-21"
        },
        {
            "id": "297",
            "link": "https://www.youtube.com/watch?v=xI5YJJDr6B0",
            "book": "路22-24"
        },
        {
            "id": "298",
            "link": "https://www.youtube.com/watch?v=IqK1g4AWq8M",
            "book": "約1-4"
        },
        {
            "id": "299",
            "link": "https://www.youtube.com/watch?v=UiVmjEV5hp8",
            "book": "約5-7"
        },
        {
            "id": "300",
            "link": "https://www.youtube.com/watch?v=KQHX7vkXWgI",
            "book": "約8-9"
        },
        {
            "id": "301",
            "link": "https://www.youtube.com/watch?v=dP6e1dLhKeI",
            "book": "約10-11"
        },
        {
            "id": "302",
            "link": "https://www.youtube.com/watch?v=jhhczowm05U",
            "book": "約12-13"
        },
        {
            "id": "303",
            "link": "https://www.youtube.com/watch?v=6dUqQ6VPP_o",
            "book": "約14-17"
        },
        {
            "id": "304",
            "link": "https://www.youtube.com/watch?v=hmXYyHp3KD0",
            "book": "約18-21"
        },
        {
            "id": "305",
            "link": "https://www.youtube.com/watch?v=agJa2OSpwPw",
            "book": "徒1-4"
        },
        {
            "id": "306",
            "link": "https://www.youtube.com/watch?v=Fpb2ISwRu4k",
            "book": "徒5-7"
        },
        {
            "id": "307",
            "link": "https://www.youtube.com/watch?v=lI7SSdDGZ2A",
            "book": "徒8-9"
        },
        {
            "id": "308",
            "link": "https://www.youtube.com/watch?v=KCQ9ThAE4x8",
            "book": "徒10-11"
        },
        {
            "id": "309",
            "link": "https://www.youtube.com/watch?v=3FviODV4O0Q",
            "book": "徒12-13"
        },
        {
            "id": "310",
            "link": "https://www.youtube.com/watch?v=GGy86EZXRe8",
            "book": "徒14-15"
        },
        {
            "id": "311",
            "link": "https://www.youtube.com/watch?v=FVe9nfQLLHg",
            "book": "徒16-18"
        },
        {
            "id": "312",
            "link": "https://www.youtube.com/watch?v=VhL3A8uLR3c",
            "book": "徒19-20"
        },
        {
            "id": "313",
            "link": "https://www.youtube.com/watch?v=G65p1WlcyEQ",
            "book": "徒21-22"
        },
        {
            "id": "314",
            "link": "https://www.youtube.com/watch?v=HiLsvpDgUa0",
            "book": "徒23-24"
        },
        {
            "id": "315",
            "link": "https://www.youtube.com/watch?v=bfLqZSCMy9U",
            "book": "徒25-26"
        },
        {
            "id": "316",
            "link": "https://www.youtube.com/watch?v=y_hcHVpfYGg",
            "book": "徒27-28"
        },
        {
            "id": "317",
            "link": "https://www.youtube.com/watch?v=5uOkDBfyo2A",
            "book": "羅1-3"
        },
        {
            "id": "318",
            "link": "https://www.youtube.com/watch?v=ZYcuZzk7_o8",
            "book": "羅4-5"
        },
        {
            "id": "319",
            "link": "https://www.youtube.com/watch?v=YFR8XKrR080",
            "book": "羅6-8"
        },
        {
            "id": "320",
            "link": "https://www.youtube.com/watch?v=B7QGlSC6wOM",
            "book": "羅9-11"
        },
        {
            "id": "321",
            "link": "https://www.youtube.com/watch?v=_Uy4BbTZ864",
            "book": "羅12-14"
        },
        {
            "id": "322",
            "link": "https://www.youtube.com/watch?v=ldjPa2X43l0",
            "book": "羅15-16"
        },
        {
            "id": "323",
            "link": "https://www.youtube.com/watch?v=MCz7P-8klrM",
            "book": "林前1-6"
        },
        {
            "id": "324",
            "link": "https://www.youtube.com/watch?v=MNo2r4a7dec",
            "book": "林前7-10"
        },
        {
            "id": "325",
            "link": "https://www.youtube.com/watch?v=2VrlPhkqQgM",
            "book": "林前11-14"
        },
        {
            "id": "326",
            "link": "https://www.youtube.com/watch?v=o-FgOs0zg_s",
            "book": "林前15-16"
        },
        {
            "id": "327",
            "link": "https://www.youtube.com/watch?v=92PeXN--Ipo",
            "book": "林後1-4"
        },
        {
            "id": "328",
            "link": "https://www.youtube.com/watch?v=a0TMJvtZiRY",
            "book": "林後5-8"
        },
        {
            "id": "329",
            "link": "https://www.youtube.com/watch?v=vva6ZG2AyBM",
            "book": "林後9-10"
        },
        {
            "id": "330",
            "link": "https://www.youtube.com/watch?v=aZw5DeHR1Vk",
            "book": "林後11-13"
        },
        {
            "id": "331",
            "link": "https://www.youtube.com/watch?v=eCzoL-Qh5LI",
            "book": "加1-6"
        },
        {
            "id": "332",
            "link": "https://www.youtube.com/watch?v=TwDZiCQNiO4",
            "book": "弗1-6"
        },
        {
            "id": "333",
            "link": "https://www.youtube.com/watch?v=Ilawqqc9urg",
            "book": "腓1-4"
        },
        {
            "id": "334",
            "link": "https://www.youtube.com/watch?v=PJMmceIWsZs",
            "book": "西1-4"
        },
        {
            "id": "335",
            "link": "https://www.youtube.com/watch?v=iPCled_2gmA",
            "book": "帖前1-3"
        },
        {
            "id": "336",
            "link": "https://www.youtube.com/watch?v=RpqJL_XiB_8",
            "book": "帖前4-5"
        },
        {
            "id": "337",
            "link": "https://www.youtube.com/watch?v=f8MROWTSL_o",
            "book": "帖後1-3"
        },
        {
            "id": "338",
            "link": "https://www.youtube.com/watch?v=k78eV91xYj4",
            "book": "提前1-3"
        },
        {
            "id": "339",
            "link": "https://www.youtube.com/watch?v=ksg6Tp0sb_o",
            "book": "提前4-6"
        },
        {
            "id": "340",
            "link": "https://www.youtube.com/watch?v=vcNzWzCOowU",
            "book": "提後1-2"
        },
        {
            "id": "341",
            "link": "https://www.youtube.com/watch?v=8aYwuj8eU44",
            "book": "提後3-4"
        },
        {
            "id": "342",
            "link": "https://www.youtube.com/watch?v=ZQa5ThAOT2Y",
            "book": "多1-3"
        },
        {
            "id": "343",
            "link": "https://www.youtube.com/watch?v=S897FkVZsy0",
            "book": "門1"
        },
        {
            "id": "344",
            "link": "https://www.youtube.com/watch?v=S8ajA8RoIvE",
            "book": "來1-2"
        },
        {
            "id": "345",
            "link": "https://www.youtube.com/watch?v=nRAWdF3W854",
            "book": "來3-4"
        },
        {
            "id": "346",
            "link": "https://www.youtube.com/watch?v=MV56u0s6DVo",
            "book": "來5-7"
        },
        {
            "id": "347",
            "link": "https://www.youtube.com/watch?v=2lirklKQqk4",
            "book": "來8-10"
        },
        {
            "id": "348",
            "link": "https://www.youtube.com/watch?v=m_4-6CSmcFY",
            "book": "來11-13"
        },
        {
            "id": "349",
            "link": "https://www.youtube.com/watch?v=_OO8sZdR13Y",
            "book": "雅1-3"
        },
        {
            "id": "350",
            "link": "https://www.youtube.com/watch?v=adYZ_2NAsB0",
            "book": "雅4-5"
        },
        {
            "id": "351",
            "link": "https://www.youtube.com/watch?v=fExq3NzkrgU",
            "book": "彼前1-5"
        },
        {
            "id": "352",
            "link": "https://www.youtube.com/watch?v=I8D_S9xenXg",
            "book": "彼後1-3"
        },
        {
            "id": "353",
            "link": "https://www.youtube.com/watch?v=3oylUWCo0Fo",
            "book": "約一1-5"
        },
        {
            "id": "354",
            "link": "https://www.youtube.com/watch?v=mdSWjBywV-4",
            "book": "約二1"
        },
        {
            "id": "355",
            "link": "https://www.youtube.com/watch?v=56kUmVrFQj0",
            "book": "約三1"
        },
        {
            "id": "356",
            "link": "https://www.youtube.com/watch?v=t--KYPurbAQ",
            "book": "猶1"
        },
        {
            "id": "357",
            "link": "https://www.youtube.com/watch?v=CiI3lv0NXsU",
            "book": "啟1-3"
        },
        {
            "id": "358",
            "link": "https://www.youtube.com/watch?v=B5zShqOmkOw",
            "book": "啟4-5"
        },
        {
            "id": "359",
            "link": "https://www.youtube.com/watch?v=-5FObZavcfQ",
            "book": "啟6-7"
        },
        {
            "id": "360",
            "link": "https://www.youtube.com/watch?v=bqJPY50Me9I",
            "book": "啟8-9"
        },
        {
            "id": "361",
            "link": "https://www.youtube.com/watch?v=vQ0FgnNN6iU",
            "book": "啟10-12"
        },
        {
            "id": "362",
            "link": "https://www.youtube.com/watch?v=6nyurXDZb7A",
            "book": "啟13-15"
        },
        {
            "id": "363",
            "link": "https://www.youtube.com/watch?v=rOSuxhyP_VU",
            "book": "啟16-17"
        },
        {
            "id": "364",
            "link": "https://www.youtube.com/watch?v=Sqd6hyJEGbk",
            "book": "啟18-19"
        },
        {
            "id": "365",
            "link": "https://www.youtube.com/watch?v=TYDIjSDeWeg",
            "book": "啟20-22"
        }
    ]
    return dailyVerseList;
}



function loadDailyVerseNT(){
    var dailyVerseList = 
    [
        {
            "id": "1",
            "link": "https://www.youtube.com/watch?v=TLDD4cto4Dw",
            "book": "太1-4"
        },
        {
            "id": "2",
            "link": "https://www.youtube.com/watch?v=OVkBaWChXYc",
            "book": "太5-7"
        },
        {
            "id": "3",
            "link": "https://www.youtube.com/watch?v=eYxpNA3AanA",
            "book": "太8-11"
        },
        {
            "id": "4",
            "link": "https://www.youtube.com/watch?v=A-mEsM_EU7w",
            "book": "太12-15"
        },
        {
            "id": "5",
            "link": "https://www.youtube.com/watch?v=4GujV68z2jQ",
            "book": "太16-19"
        },
        {
            "id": "6",
            "link": "https://www.youtube.com/watch?v=PPHWa60j434",
            "book": "太20-21"
        },
        {
            "id": "7",
            "link": "https://www.youtube.com/watch?v=goiK5ygabjw",
            "book": "太22-23"
        },
        {
            "id": "8",
            "link": "https://www.youtube.com/watch?v=4JddJ1JEx2M",
            "book": "太24-25"
        },
        {
            "id": "9",
            "link": "https://www.youtube.com/watch?v=CUsdTecY1aA",
            "book": "太26-28"
        },
        {
            "id": "10",
            "link": "https://www.youtube.com/watch?v=GDZKpZyoSY4",
            "book": "可1-3"
        },
        {
            "id": "11",
            "link": "https://www.youtube.com/watch?v=HfA53x3kI-Q",
            "book": "可4-7"
        },
        {
            "id": "12",
            "link": "https://www.youtube.com/watch?v=Por-6s_o71I",
            "book": "可8-10"
        },
        {
            "id": "13",
            "link": "https://www.youtube.com/watch?v=-5EYStBPdiY",
            "book": "可11-12"
        },
        {
            "id": "14",
            "link": "https://www.youtube.com/watch?v=ptbQmnHbYL8",
            "book": "可13-14"
        },
        {
            "id": "15",
            "link": "https://www.youtube.com/watch?v=QZb0m68zobo",
            "book": "可15-16"
        },
        {
            "id": "16",
            "link": "https://www.youtube.com/watch?v=v64Pqq6qoyc",
            "book": "路1-2"
        },
        {
            "id": "17",
            "link": "https://www.youtube.com/watch?v=dMreNd0HZRQ",
            "book": "路3-6"
        },
        {
            "id": "18",
            "link": "https://www.youtube.com/watch?v=Ls6irYImmas",
            "book": "路7-9"
        },
        {
            "id": "19",
            "link": "https://www.youtube.com/watch?v=MV0GEX1R8xE",
            "book": "路10-11"
        },
        {
            "id": "20",
            "link": "https://www.youtube.com/watch?v=k7vCM3sh7Bk",
            "book": "路12-13"
        },
        {
            "id": "21",
            "link": "https://www.youtube.com/watch?v=dO40eW7ce3w",
            "book": "路14-15"
        },
        {
            "id": "22",
            "link": "https://www.youtube.com/watch?v=jplCwLJJYZ8",
            "book": "路16-18"
        },
        {
            "id": "23",
            "link": "https://www.youtube.com/watch?v=ld8xM_rY61s",
            "book": "路19-21"
        },
        {
            "id": "24",
            "link": "https://www.youtube.com/watch?v=xI5YJJDr6B0",
            "book": "路22-24"
        },
        {
            "id": "25",
            "link": "https://www.youtube.com/watch?v=IqK1g4AWq8M",
            "book": "約1-4"
        },
        {
            "id": "26",
            "link": "https://www.youtube.com/watch?v=UiVmjEV5hp8",
            "book": "約5-7"
        },
        {
            "id": "27",
            "link": "https://www.youtube.com/watch?v=KQHX7vkXWgI",
            "book": "約8-9"
        },
        {
            "id": "28",
            "link": "https://www.youtube.com/watch?v=dP6e1dLhKeI",
            "book": "約10-11"
        },
        {
            "id": "29",
            "link": "https://www.youtube.com/watch?v=jhhczowm05U",
            "book": "約12-13"
        },
        {
            "id": "30",
            "link": "https://www.youtube.com/watch?v=6dUqQ6VPP_o",
            "book": "約14-17"
        },
        {
            "id": "31",
            "link": "https://www.youtube.com/watch?v=hmXYyHp3KD0",
            "book": "約18-21"
        },
        {
            "id": "32",
            "link": "https://www.youtube.com/watch?v=agJa2OSpwPw",
            "book": "徒1-4"
        },
        {
            "id": "33",
            "link": "https://www.youtube.com/watch?v=Fpb2ISwRu4k",
            "book": "徒5-7"
        },
        {
            "id": "34",
            "link": "https://www.youtube.com/watch?v=lI7SSdDGZ2A",
            "book": "徒8-9"
        },
        {
            "id": "35",
            "link": "https://www.youtube.com/watch?v=KCQ9ThAE4x8",
            "book": "徒10-11"
        },
        {
            "id": "36",
            "link": "https://www.youtube.com/watch?v=3FviODV4O0Q",
            "book": "徒12-13"
        },
        {
            "id": "37",
            "link": "https://www.youtube.com/watch?v=GGy86EZXRe8",
            "book": "徒14-15"
        },
        {
            "id": "38",
            "link": "https://www.youtube.com/watch?v=FVe9nfQLLHg",
            "book": "徒16-18"
        },
        {
            "id": "39",
            "link": "https://www.youtube.com/watch?v=VhL3A8uLR3c",
            "book": "徒19-20"
        },
        {
            "id": "40",
            "link": "https://www.youtube.com/watch?v=G65p1WlcyEQ",
            "book": "徒21-22"
        },
        {
            "id": "41",
            "link": "https://www.youtube.com/watch?v=HiLsvpDgUa0",
            "book": "徒23-24"
        },
        {
            "id": "42",
            "link": "https://www.youtube.com/watch?v=bfLqZSCMy9U",
            "book": "徒25-26"
        },
        {
            "id": "43",
            "link": "https://www.youtube.com/watch?v=y_hcHVpfYGg",
            "book": "徒27-28"
        },
        {
            "id": "44",
            "link": "https://www.youtube.com/watch?v=5uOkDBfyo2A",
            "book": "羅1-3"
        },
        {
            "id": "45",
            "link": "https://www.youtube.com/watch?v=ZYcuZzk7_o8",
            "book": "羅4-5"
        },
        {
            "id": "46",
            "link": "https://www.youtube.com/watch?v=YFR8XKrR080",
            "book": "羅6-8"
        },
        {
            "id": "47",
            "link": "https://www.youtube.com/watch?v=B7QGlSC6wOM",
            "book": "羅9-11"
        },
        {
            "id": "48",
            "link": "https://www.youtube.com/watch?v=_Uy4BbTZ864",
            "book": "羅12-14"
        },
        {
            "id": "49",
            "link": "https://www.youtube.com/watch?v=ldjPa2X43l0",
            "book": "羅15-16"
        },
        {
            "id": "50",
            "link": "https://www.youtube.com/watch?v=MCz7P-8klrM",
            "book": "林前1-6"
        },
        {
            "id": "51",
            "link": "https://www.youtube.com/watch?v=MNo2r4a7dec",
            "book": "林前7-10"
        },
        {
            "id": "52",
            "link": "https://www.youtube.com/watch?v=2VrlPhkqQgM",
            "book": "林前11-14"
        },
        {
            "id": "53",
            "link": "https://www.youtube.com/watch?v=o-FgOs0zg_s",
            "book": "林前15-16"
        },
        {
            "id": "54",
            "link": "https://www.youtube.com/watch?v=92PeXN--Ipo",
            "book": "林後1-4"
        },
        {
            "id": "55",
            "link": "https://www.youtube.com/watch?v=a0TMJvtZiRY",
            "book": "林後5-8"
        },
        {
            "id": "56",
            "link": "https://www.youtube.com/watch?v=vva6ZG2AyBM",
            "book": "林後9-10"
        },
        {
            "id": "57",
            "link": "https://www.youtube.com/watch?v=aZw5DeHR1Vk",
            "book": "林後11-13"
        },
        {
            "id": "58",
            "link": "https://www.youtube.com/watch?v=eCzoL-Qh5LI",
            "book": "加1-6"
        },
        {
            "id": "59",
            "link": "https://www.youtube.com/watch?v=TwDZiCQNiO4",
            "book": "弗1-6"
        },
        {
            "id": "60",
            "link": "https://www.youtube.com/watch?v=Ilawqqc9urg",
            "book": "腓1-4"
        },
        {
            "id": "61",
            "link": "https://www.youtube.com/watch?v=PJMmceIWsZs",
            "book": "西1-4"
        },
        {
            "id": "62",
            "link": "https://www.youtube.com/watch?v=iPCled_2gmA",
            "book": "帖前1-3"
        },
        {
            "id": "63",
            "link": "https://www.youtube.com/watch?v=RpqJL_XiB_8",
            "book": "帖前4-5"
        },
        {
            "id": "64",
            "link": "https://www.youtube.com/watch?v=f8MROWTSL_o",
            "book": "帖後1-3"
        },
        {
            "id": "65",
            "link": "https://www.youtube.com/watch?v=k78eV91xYj4",
            "book": "提前1-3"
        },
        {
            "id": "66",
            "link": "https://www.youtube.com/watch?v=ksg6Tp0sb_o",
            "book": "提前4-6"
        },
        {
            "id": "67",
            "link": "https://www.youtube.com/watch?v=vcNzWzCOowU",
            "book": "提後1-2"
        },
        {
            "id": "68",
            "link": "https://www.youtube.com/watch?v=8aYwuj8eU44",
            "book": "提後3-4"
        },
        {
            "id": "69",
            "link": "https://www.youtube.com/watch?v=ZQa5ThAOT2Y",
            "book": "多1-3"
        },
        {
            "id": "70",
            "link": "https://www.youtube.com/watch?v=S897FkVZsy0",
            "book": "門1"
        },
        {
            "id": "71",
            "link": "https://www.youtube.com/watch?v=S8ajA8RoIvE",
            "book": "來1-2"
        },
        {
            "id": "72",
            "link": "https://www.youtube.com/watch?v=nRAWdF3W854",
            "book": "來3-4"
        },
        {
            "id": "73",
            "link": "https://www.youtube.com/watch?v=MV56u0s6DVo",
            "book": "來5-7"
        },
        {
            "id": "74",
            "link": "https://www.youtube.com/watch?v=2lirklKQqk4",
            "book": "來8-10"
        },
        {
            "id": "75",
            "link": "https://www.youtube.com/watch?v=m_4-6CSmcFY",
            "book": "來11-13"
        },
        {
            "id": "76",
            "link": "https://www.youtube.com/watch?v=_OO8sZdR13Y",
            "book": "雅1-3"
        },
        {
            "id": "77",
            "link": "https://www.youtube.com/watch?v=adYZ_2NAsB0",
            "book": "雅4-5"
        },
        {
            "id": "78",
            "link": "https://www.youtube.com/watch?v=fExq3NzkrgU",
            "book": "彼前1-5"
        },
        {
            "id": "79",
            "link": "https://www.youtube.com/watch?v=I8D_S9xenXg",
            "book": "彼後1-3"
        },
        {
            "id": "80",
            "link": "https://www.youtube.com/watch?v=3oylUWCo0Fo",
            "book": "約一1-5"
        },
        {
            "id": "81",
            "link": "https://www.youtube.com/watch?v=mdSWjBywV-4",
            "book": "約二1"
        },
        {
            "id": "82",
            "link": "https://www.youtube.com/watch?v=56kUmVrFQj0",
            "book": "約三1"
        },
        {
            "id": "83",
            "link": "https://www.youtube.com/watch?v=t--KYPurbAQ",
            "book": "猶1"
        },
        {
            "id": "84",
            "link": "https://www.youtube.com/watch?v=CiI3lv0NXsU",
            "book": "啟1-3"
        },
        {
            "id": "85",
            "link": "https://www.youtube.com/watch?v=B5zShqOmkOw",
            "book": "啟4-5"
        },
        {
            "id": "86",
            "link": "https://www.youtube.com/watch?v=-5FObZavcfQ",
            "book": "啟6-7"
        },
        {
            "id": "87",
            "link": "https://www.youtube.com/watch?v=bqJPY50Me9I",
            "book": "啟8-9"
        },
        {
            "id": "88",
            "link": "https://www.youtube.com/watch?v=vQ0FgnNN6iU",
            "book": "啟10-12"
        },
        {
            "id": "89",
            "link": "https://www.youtube.com/watch?v=6nyurXDZb7A",
            "book": "啟13-15"
        },
        {
            "id": "90",
            "link": "https://www.youtube.com/watch?v=rOSuxhyP_VU",
            "book": "啟16-17"
        },
        {
            "id": "91",
            "link": "https://www.youtube.com/watch?v=Sqd6hyJEGbk",
            "book": "啟18-19"
        },
        {
            "id": "92",
            "link": "https://www.youtube.com/watch?v=TYDIjSDeWeg",
            "book": "啟20-22"
        },
        {
            "id": "93",
            "link": "https://www.youtube.com/watch?v=LnDicKCqPGI",
            "book": "創1-2"
        },
        {
            "id": "94",
            "link": "https://www.youtube.com/watch?v=uvEx-Jma0rQ",
            "book": "創3-5"
        },
        {
            "id": "95",
            "link": "https://www.youtube.com/watch?v=rT4ccM1P5PI",
            "book": "創6-9"
        },
        {
            "id": "96",
            "link": "https://www.youtube.com/watch?v=69v-yCRbWW8",
            "book": "創10-11"
        },
        {
            "id": "97",
            "link": "https://www.youtube.com/watch?v=qu8B_wtlgqk",
            "book": "創12-14"
        },
        {
            "id": "98",
            "link": "https://www.youtube.com/watch?v=QoX-H55ezmw",
            "book": "創15-16"
        },
        {
            "id": "99",
            "link": "https://www.youtube.com/watch?v=xXbGkSHDtPY",
            "book": "創17-18"
        },
        {
            "id": "100",
            "link": "https://www.youtube.com/watch?v=t5QMQIRqbXI",
            "book": "創19-21"
        },
        {
            "id": "101",
            "link": "https://www.youtube.com/watch?v=DwqFkteYaFE",
            "book": "創22-24"
        },
        {
            "id": "102",
            "link": "https://www.youtube.com/watch?v=iEWP17gxlQ8",
            "book": "創25-26"
        },
        {
            "id": "103",
            "link": "https://www.youtube.com/watch?v=rMDKJkim2OI",
            "book": "創27-31"
        },
        {
            "id": "104",
            "link": "https://www.youtube.com/watch?v=4qV1VUHdNFQ",
            "book": "創32-36"
        },
        {
            "id": "105",
            "link": "https://www.youtube.com/watch?v=hxdSvqo1DpE",
            "book": "創37-38"
        },
        {
            "id": "106",
            "link": "https://www.youtube.com/watch?v=5f1TWnGZtqM",
            "book": "創39-40"
        },
        {
            "id": "107",
            "link": "https://www.youtube.com/watch?v=8HsHvneC8uA",
            "book": "創41-44"
        },
        {
            "id": "108",
            "link": "https://www.youtube.com/watch?v=rNKJP60ucG8",
            "book": "創45-47"
        },
        {
            "id": "109",
            "link": "https://www.youtube.com/watch?v=ZHj8-3HZzzo",
            "book": "創48-50"
        },
        {
            "id": "110",
            "link": "https://www.youtube.com/watch?v=qy3J-2SffDo",
            "book": "出1-2"
        },
        {
            "id": "111",
            "link": "https://www.youtube.com/watch?v=8MMkxVv34IM",
            "book": "出3-6"
        },
        {
            "id": "112",
            "link": "https://www.youtube.com/watch?v=EalH7Da6BbA",
            "book": "出7-8"
        },
        {
            "id": "113",
            "link": "https://www.youtube.com/watch?v=tPKPveh_CZo",
            "book": "出9-10"
        },
        {
            "id": "114",
            "link": "https://www.youtube.com/watch?v=bzXRDbO7Tjc",
            "book": "出11-12"
        },
        {
            "id": "115",
            "link": "https://www.youtube.com/watch?v=BKboO83wsY4",
            "book": "出13-15"
        },
        {
            "id": "116",
            "link": "https://www.youtube.com/watch?v=8DpQY0Uwxlg",
            "book": "出16-18"
        },
        {
            "id": "117",
            "link": "https://www.youtube.com/watch?v=t1EUnJadm-w",
            "book": "出19-20"
        },
        {
            "id": "118",
            "link": "https://www.youtube.com/watch?v=yyir9Yl-7VY",
            "book": "出21-24"
        },
        {
            "id": "119",
            "link": "https://www.youtube.com/watch?v=2NpTXskMG3I",
            "book": "出25-26"
        },
        {
            "id": "120",
            "link": "https://www.youtube.com/watch?v=D3jj5E9XSZY",
            "book": "出27-28"
        },
        {
            "id": "121",
            "link": "https://www.youtube.com/watch?v=8DOGe30Ahck",
            "book": "出29-31"
        },
        {
            "id": "122",
            "link": "https://www.youtube.com/watch?v=FHwYVfn8kvQ",
            "book": "出32-34"
        },
        {
            "id": "123",
            "link": "https://www.youtube.com/watch?v=9dfjg9hCqaw",
            "book": "出35-40"
        },
        {
            "id": "124",
            "link": "https://www.youtube.com/watch?v=Y43Zomggcz8",
            "book": "利1-3"
        },
        {
            "id": "125",
            "link": "https://www.youtube.com/watch?v=eL2oUyYaRbU",
            "book": "利4-7"
        },
        {
            "id": "126",
            "link": "https://www.youtube.com/watch?v=cU_cIoxHSHU",
            "book": "利8-9"
        },
        {
            "id": "127",
            "link": "https://www.youtube.com/watch?v=TnYEGiIA6sc",
            "book": "利10-12"
        },
        {
            "id": "128",
            "link": "https://www.youtube.com/watch?v=lTuA-TKSvWE",
            "book": "利13-15"
        },
        {
            "id": "129",
            "link": "https://www.youtube.com/watch?v=EtubsgI0Bu8",
            "book": "利16-17"
        },
        {
            "id": "130",
            "link": "https://www.youtube.com/watch?v=WhT75A3xjW8",
            "book": "利18-20"
        },
        {
            "id": "131",
            "link": "https://www.youtube.com/watch?v=O_X-7vkr6D0",
            "book": "利21-23"
        },
        {
            "id": "132",
            "link": "https://www.youtube.com/watch?v=pNDs3bANAg4",
            "book": "利24-27"
        },
        {
            "id": "133",
            "link": "https://www.youtube.com/watch?v=Y7GO9VBzPTE",
            "book": "民1-3"
        },
        {
            "id": "134",
            "link": "https://www.youtube.com/watch?v=bl2OO14ymEo",
            "book": "民4-5"
        },
        {
            "id": "135",
            "link": "https://www.youtube.com/watch?v=_snViX8BT_I",
            "book": "民6-8"
        },
        {
            "id": "136",
            "link": "https://www.youtube.com/watch?v=CpOYIDYDEzI",
            "book": "民9-12"
        },
        {
            "id": "137",
            "link": "https://www.youtube.com/watch?v=pOdoiE5sGxQ",
            "book": "民13-16"
        },
        {
            "id": "138",
            "link": "https://www.youtube.com/watch?v=i1HRXLjQhpY",
            "book": "民17-20"
        },
        {
            "id": "139",
            "link": "https://www.youtube.com/watch?v=4hy_vHJJ-Lc",
            "book": "民21-25"
        },
        {
            "id": "140",
            "link": "https://www.youtube.com/watch?v=elm5QJDPe4o",
            "book": "民26-28"
        },
        {
            "id": "141",
            "link": "https://www.youtube.com/watch?v=KVvLc848vOY",
            "book": "民29-30"
        },
        {
            "id": "142",
            "link": "https://www.youtube.com/watch?v=Z7MEPF07Kfk",
            "book": "民31-33"
        },
        {
            "id": "143",
            "link": "https://www.youtube.com/watch?v=UL1EvnEwQSY",
            "book": "民34-36"
        },
        {
            "id": "144",
            "link": "https://www.youtube.com/watch?v=jfB8TXTt3FY",
            "book": "申1-4"
        },
        {
            "id": "145",
            "link": "https://www.youtube.com/watch?v=rTVTlEUSGso",
            "book": "申5-7"
        },
        {
            "id": "146",
            "link": "https://www.youtube.com/watch?v=YSb4Q14bf40",
            "book": "申8-11"
        },
        {
            "id": "147",
            "link": "https://www.youtube.com/watch?v=hrjjj15xFYo",
            "book": "申12-16"
        },
        {
            "id": "148",
            "link": "https://www.youtube.com/watch?v=NybwpqtkXxA",
            "book": "申17-20"
        },
        {
            "id": "149",
            "link": "https://www.youtube.com/watch?v=VyR5tsASyC8",
            "book": "申21-26"
        },
        {
            "id": "150",
            "link": "https://www.youtube.com/watch?v=Pvp1L_oN1N0",
            "book": "申27-30"
        },
        {
            "id": "151",
            "link": "https://www.youtube.com/watch?v=NfAX7i2-ltk",
            "book": "申31-34"
        },
        {
            "id": "152",
            "link": "https://www.youtube.com/watch?v=44ogPOjh7G8",
            "book": "書1-5"
        },
        {
            "id": "153",
            "link": "https://www.youtube.com/watch?v=wrq-wRN2Q38",
            "book": "書6-8"
        },
        {
            "id": "154",
            "link": "https://www.youtube.com/watch?v=Pi-_q5Nolkg",
            "book": "書9-11"
        },
        {
            "id": "155",
            "link": "https://www.youtube.com/watch?v=rVy5vu3FmNU",
            "book": "書12-13"
        },
        {
            "id": "156",
            "link": "https://www.youtube.com/watch?v=iRSZng8CzU8",
            "book": "書14-17"
        },
        {
            "id": "157",
            "link": "https://www.youtube.com/watch?v=AIzO-en0xag",
            "book": "書18-21"
        },
        {
            "id": "158",
            "link": "https://www.youtube.com/watch?v=T94liW8C1O8",
            "book": "書22-24"
        },
        {
            "id": "159",
            "link": "https://www.youtube.com/watch?v=LfgfuEN5Xis",
            "book": "士1-5"
        },
        {
            "id": "160",
            "link": "https://www.youtube.com/watch?v=yEQv2TFVTDk",
            "book": "士6-8"
        },
        {
            "id": "161",
            "link": "https://www.youtube.com/watch?v=wh0NlqhOTPA",
            "book": "士9-11"
        },
        {
            "id": "162",
            "link": "https://www.youtube.com/watch?v=aNqW-tRiAgk",
            "book": "士12_13"
        },
        {
            "id": "163",
            "link": "https://www.youtube.com/watch?v=Fa7X0yy490A",
            "book": "士14-16"
        },
        {
            "id": "164",
            "link": "https://www.youtube.com/watch?v=H4NnNcw1rVg",
            "book": "士17-21"
        },
        {
            "id": "165",
            "link": "https://www.youtube.com/watch?v=coO4XXHmrZ8",
            "book": "得1-4"
        },
        {
            "id": "166",
            "link": "https://www.youtube.com/watch?v=2JjaVX5kMv8",
            "book": "撒上1-3"
        },
        {
            "id": "167",
            "link": "https://www.youtube.com/watch?v=OcNZcb8MsOc",
            "book": "撒上4-8"
        },
        {
            "id": "168",
            "link": "https://www.youtube.com/watch?v=Kv9Auy1v_BE",
            "book": "撒上9-10"
        },
        {
            "id": "169",
            "link": "https://www.youtube.com/watch?v=mPaUqmbN-nU",
            "book": "撒上11-12"
        },
        {
            "id": "170",
            "link": "https://www.youtube.com/watch?v=D_KVq0duPxI",
            "book": "撒上13-15"
        },
        {
            "id": "171",
            "link": "https://www.youtube.com/watch?v=FvrXVR3_DuQ",
            "book": "撒上16-19"
        },
        {
            "id": "172",
            "link": "https://www.youtube.com/watch?v=c93KKhGF60U",
            "book": "撒上20-23"
        },
        {
            "id": "173",
            "link": "https://www.youtube.com/watch?v=U17GJ05VnTs",
            "book": "撒上24-26"
        },
        {
            "id": "174",
            "link": "https://www.youtube.com/watch?v=TZBKyKeCOHg",
            "book": "撒上27-31"
        },
        {
            "id": "175",
            "link": "https://www.youtube.com/watch?v=gxNU67CzwII",
            "book": "撒下1-2"
        },
        {
            "id": "176",
            "link": "https://www.youtube.com/watch?v=KBmG3fkdrSc",
            "book": "撒下3-4"
        },
        {
            "id": "177",
            "link": "https://www.youtube.com/watch?v=jJmtjjzLcyk",
            "book": "撒下5-7"
        },
        {
            "id": "178",
            "link": "https://www.youtube.com/watch?v=eBRJvtvKGL0",
            "book": "撒下8-10"
        },
        {
            "id": "179",
            "link": "https://www.youtube.com/watch?v=37ETUU0syTw",
            "book": "撒下11-14"
        },
        {
            "id": "180",
            "link": "https://www.youtube.com/watch?v=Dvt1rUqTL34",
            "book": "撒下15-18"
        },
        {
            "id": "181",
            "link": "https://www.youtube.com/watch?v=j5XCBtMFlto",
            "book": "撒下19-20"
        },
        {
            "id": "182",
            "link": "https://www.youtube.com/watch?v=YDf23fpD0H8",
            "book": "撒下21-24"
        },
        {
            "id": "183",
            "link": "https://www.youtube.com/watch?v=pvQa6dTQEy4",
            "book": "王上1-4"
        },
        {
            "id": "184",
            "link": "https://www.youtube.com/watch?v=xAwLFhstDQ8",
            "book": "王上5-8"
        },
        {
            "id": "185",
            "link": "https://www.youtube.com/watch?v=Slfyqiv5VRs",
            "book": "王上9-11"
        },
        {
            "id": "186",
            "link": "https://www.youtube.com/watch?v=WCktQBL-NHE",
            "book": "王上12-16"
        },
        {
            "id": "187",
            "link": "https://www.youtube.com/watch?v=VqMu0DT1_Hc",
            "book": "王上17-19"
        },
        {
            "id": "188",
            "link": "https://www.youtube.com/watch?v=iYedBJuUS0M",
            "book": "王上20-22"
        },
        {
            "id": "189",
            "link": "https://www.youtube.com/watch?v=0Nmm5HTOnYQ",
            "book": "王下1-2"
        },
        {
            "id": "190",
            "link": "https://www.youtube.com/watch?v=vLJ4010YMdw",
            "book": "王下3-4"
        },
        {
            "id": "191",
            "link": "https://www.youtube.com/watch?v=9hI_WMTtP04",
            "book": "王下5-8"
        },
        {
            "id": "192",
            "link": "https://www.youtube.com/watch?v=jW-doN9hWKE",
            "book": "王下9-12"
        },
        {
            "id": "193",
            "link": "https://www.youtube.com/watch?v=bHrkbJY4KXY",
            "book": "王下13-17"
        },
        {
            "id": "194",
            "link": "https://www.youtube.com/watch?v=CWQ3qRZVUXk",
            "book": "王下18-21"
        },
        {
            "id": "195",
            "link": "https://www.youtube.com/watch?v=61t2jT6dWHk",
            "book": "王下22-25"
        },
        {
            "id": "196",
            "link": "https://www.youtube.com/watch?v=ScfORgs-0KY",
            "book": "代上1-5"
        },
        {
            "id": "197",
            "link": "https://www.youtube.com/watch?v=CMg8jZsIxJI",
            "book": "代上6-9"
        },
        {
            "id": "198",
            "link": "https://www.youtube.com/watch?v=QuDgJq3P2M4",
            "book": "代上10-16"
        },
        {
            "id": "199",
            "link": "https://www.youtube.com/watch?v=zryz-xfqNug",
            "book": "代上17-21"
        },
        {
            "id": "200",
            "link": "https://www.youtube.com/watch?v=aTmlJJjJMEo",
            "book": "代上22-27"
        },
        {
            "id": "201",
            "link": "https://www.youtube.com/watch?v=m4I7gVqtIl8",
            "book": "代上28-29"
        },
        {
            "id": "202",
            "link": "https://www.youtube.com/watch?v=JvGIxlONOEM",
            "book": "代下1-5"
        },
        {
            "id": "203",
            "link": "https://www.youtube.com/watch?v=0TjSyj3VTf0",
            "book": "代下6-7"
        },
        {
            "id": "204",
            "link": "https://www.youtube.com/watch?v=-W-A62jYHdU",
            "book": "代下8-9"
        },
        {
            "id": "205",
            "link": "https://www.youtube.com/watch?v=-CQg0QUykLo",
            "book": "代下10-12"
        },
        {
            "id": "206",
            "link": "https://www.youtube.com/watch?v=1OeCPRSyE8I",
            "book": "代下13-16"
        },
        {
            "id": "207",
            "link": "https://www.youtube.com/watch?v=CbODo-IQG7U",
            "book": "代下17-20"
        },
        {
            "id": "208",
            "link": "https://www.youtube.com/watch?v=yVHv0YyherU",
            "book": "代下21-25"
        },
        {
            "id": "209",
            "link": "https://www.youtube.com/watch?v=JCcB-6piTjM",
            "book": "代下26-28"
        },
        {
            "id": "210",
            "link": "https://www.youtube.com/watch?v=EXdD7qP1QKc",
            "book": "代下29-30"
        },
        {
            "id": "211",
            "link": "https://www.youtube.com/watch?v=XW3ibKrq8z8",
            "book": "代下31-32"
        },
        {
            "id": "212",
            "link": "https://www.youtube.com/watch?v=p6yQ6tvu_8Y",
            "book": "代下33-36"
        },
        {
            "id": "213",
            "link": "https://www.youtube.com/watch?v=4nXFpLUHHQU",
            "book": "拉1-3"
        },
        {
            "id": "214",
            "link": "https://www.youtube.com/watch?v=G_8XkzwWAoE",
            "book": "拉4-6"
        },
        {
            "id": "215",
            "link": "https://www.youtube.com/watch?v=L5RQwdlLHgk",
            "book": "拉7-8"
        },
        {
            "id": "216",
            "link": "https://www.youtube.com/watch?v=ZnSfKk5tOoU",
            "book": "拉9-10"
        },
        {
            "id": "217",
            "link": "https://www.youtube.com/watch?v=UNLK2yysIXk",
            "book": "尼1-2"
        },
        {
            "id": "218",
            "link": "https://www.youtube.com/watch?v=pQrvqd84Ow8",
            "book": "尼3-4"
        },
        {
            "id": "219",
            "link": "https://www.youtube.com/watch?v=11JnySFFSgg",
            "book": "尼5-6"
        },
        {
            "id": "220",
            "link": "https://www.youtube.com/watch?v=UaIdAdWLSk8",
            "book": "尼7-8"
        },
        {
            "id": "221",
            "link": "https://www.youtube.com/watch?v=eihboz6zYB4",
            "book": "尼9-10"
        },
        {
            "id": "222",
            "link": "https://www.youtube.com/watch?v=ywl5pWibhxc",
            "book": "尼11-13"
        },
        {
            "id": "223",
            "link": "https://www.youtube.com/watch?v=vV8xRxuyfds",
            "book": "斯1-2"
        },
        {
            "id": "224",
            "link": "https://www.youtube.com/watch?v=LX5v7ng_9qM",
            "book": "斯3-4"
        },
        {
            "id": "225",
            "link": "https://www.youtube.com/watch?v=pwi3rgerMdc",
            "book": "斯5-6"
        },
        {
            "id": "226",
            "link": "https://www.youtube.com/watch?v=7JOvhGfkcMQ",
            "book": "斯7-8"
        },
        {
            "id": "227",
            "link": "https://www.youtube.com/watch?v=z1uwrnzg7YA",
            "book": "斯9-10"
        },
        {
            "id": "228",
            "link": "https://www.youtube.com/watch?v=Ko7rmOS7lj4",
            "book": "伯1-3"
        },
        {
            "id": "229",
            "link": "https://www.youtube.com/watch?v=B3CptGwiEqw",
            "book": "伯4-7"
        },
        {
            "id": "230",
            "link": "https://www.youtube.com/watch?v=VnQ1PgVSl4A",
            "book": "伯8-10"
        },
        {
            "id": "231",
            "link": "https://www.youtube.com/watch?v=E-v-j_-1aDo",
            "book": "伯11-12"
        },
        {
            "id": "232",
            "link": "https://www.youtube.com/watch?v=AE3QzNOLoXk",
            "book": "伯13-14"
        },
        {
            "id": "233",
            "link": "https://www.youtube.com/watch?v=hZXtZKo0ekk",
            "book": "伯15-17"
        },
        {
            "id": "234",
            "link": "https://www.youtube.com/watch?v=SUA8iylAz1Q",
            "book": "伯18-19"
        },
        {
            "id": "235",
            "link": "https://www.youtube.com/watch?v=Pn7WSvylaEU",
            "book": "伯20-21"
        },
        {
            "id": "236",
            "link": "https://www.youtube.com/watch?v=dDnMLKs_giI",
            "book": "伯22-24"
        },
        {
            "id": "237",
            "link": "https://www.youtube.com/watch?v=eTnsMGAEVKk",
            "book": "伯25-28"
        },
        {
            "id": "238",
            "link": "https://www.youtube.com/watch?v=NYJWP8t08x0",
            "book": "伯29-30"
        },
        {
            "id": "239",
            "link": "https://www.youtube.com/watch?v=F716HAGDlig",
            "book": "伯31-32"
        },
        {
            "id": "240",
            "link": "https://www.youtube.com/watch?v=hJACoxpQakQ",
            "book": "伯33-34"
        },
        {
            "id": "241",
            "link": "https://www.youtube.com/watch?v=aKcnu3t-gP4",
            "book": "伯35-37"
        },
        {
            "id": "242",
            "link": "https://www.youtube.com/watch?v=5q5MLkC8aCU",
            "book": "伯38-39"
        },
        {
            "id": "243",
            "link": "https://www.youtube.com/watch?v=Fo3Aw9j7eOo",
            "book": "伯40-42"
        },
        {
            "id": "244",
            "link": "https://www.youtube.com/watch?v=nCwSOdG4WSg",
            "book": "詩1-6"
        },
        {
            "id": "245",
            "link": "https://www.youtube.com/watch?v=QYe4am7718Y",
            "book": "詩7-9"
        },
        {
            "id": "246",
            "link": "https://www.youtube.com/watch?v=3yBurZT95rw",
            "book": "詩10-12"
        },
        {
            "id": "247",
            "link": "https://www.youtube.com/watch?v=YG4oWgI9uKM",
            "book": "詩13-18"
        },
        {
            "id": "248",
            "link": "https://www.youtube.com/watch?v=s3VqwYFCMbQ",
            "book": "詩19-24"
        },
        {
            "id": "249",
            "link": "https://www.youtube.com/watch?v=FgJ8BsgTXQk",
            "book": "詩25-30"
        },
        {
            "id": "250",
            "link": "https://www.youtube.com/watch?v=KpvhEDI0DOc",
            "book": "詩31-35"
        },
        {
            "id": "251",
            "link": "https://www.youtube.com/watch?v=HtIscvLTpWU",
            "book": "詩36-41"
        },
        {
            "id": "252",
            "link": "https://www.youtube.com/watch?v=-pI8sMjRQLs",
            "book": "詩42-45"
        },
        {
            "id": "253",
            "link": "https://www.youtube.com/watch?v=sWnrSXqhIO0",
            "book": "詩46-49"
        },
        {
            "id": "254",
            "link": "https://www.youtube.com/watch?v=Fpc5XvjVZU8",
            "book": "詩50-54"
        },
        {
            "id": "255",
            "link": "https://www.youtube.com/watch?v=Ra1-JY1csMQ",
            "book": "詩55-59"
        },
        {
            "id": "256",
            "link": "https://www.youtube.com/watch?v=TvQxTpw-a8g",
            "book": "詩60-66"
        },
        {
            "id": "257",
            "link": "https://www.youtube.com/watch?v=WDfCfmNj_84",
            "book": "詩67-72"
        },
        {
            "id": "258",
            "link": "https://www.youtube.com/watch?v=h7EDn84jqRQ",
            "book": "詩73-77"
        },
        {
            "id": "259",
            "link": "https://www.youtube.com/watch?v=E8IwJ2l8sYk",
            "book": "詩78-80"
        },
        {
            "id": "260",
            "link": "https://www.youtube.com/watch?v=od1ZQddW3E4",
            "book": "詩81-83"
        },
        {
            "id": "261",
            "link": "https://www.youtube.com/watch?v=JJRa1Wt-EYA",
            "book": "詩84-89"
        },
        {
            "id": "262",
            "link": "https://www.youtube.com/watch?v=B2Tuf0Fd2ts",
            "book": "詩90-97"
        },
        {
            "id": "263",
            "link": "https://www.youtube.com/watch?v=XpUaiLzQR98",
            "book": "詩98-103"
        },
        {
            "id": "264",
            "link": "https://www.youtube.com/watch?v=Y0p52sSRN8w",
            "book": "詩104-106"
        },
        {
            "id": "265",
            "link": "https://www.youtube.com/watch?v=MgAZBV3AGRY",
            "book": "詩107-110"
        },
        {
            "id": "266",
            "link": "https://www.youtube.com/watch?v=3Q8qj5WB0e4",
            "book": "詩111-114"
        },
        {
            "id": "267",
            "link": "https://www.youtube.com/watch?v=pimNtx0YBgs",
            "book": "詩115-118"
        },
        {
            "id": "268",
            "link": "https://www.youtube.com/watch?v=_bXiH-Q5B-Y",
            "book": "詩119"
        },
        {
            "id": "269",
            "link": "https://www.youtube.com/watch?v=wbKl0mbrbh8",
            "book": "詩120-127"
        },
        {
            "id": "270",
            "link": "https://www.youtube.com/watch?v=zZG9QPmmBvU",
            "book": "詩128-134"
        },
        {
            "id": "271",
            "link": "https://www.youtube.com/watch?v=jtrM7HDZi0M",
            "book": "詩135-139"
        },
        {
            "id": "272",
            "link": "https://www.youtube.com/watch?v=RMtlyw-T49Q",
            "book": "詩140-145"
        },
        {
            "id": "273",
            "link": "https://www.youtube.com/watch?v=t1qLHdGwN9I",
            "book": "詩146-150"
        },
        {
            "id": "274",
            "link": "https://www.youtube.com/watch?v=ffEmhApewTU",
            "book": "箴1-4"
        },
        {
            "id": "275",
            "link": "https://www.youtube.com/watch?v=B8bo7hPbmuo",
            "book": "箴5-9"
        },
        {
            "id": "276",
            "link": "https://www.youtube.com/watch?v=ZXobgyvOVQk",
            "book": "箴10-13"
        },
        {
            "id": "277",
            "link": "https://www.youtube.com/watch?v=vVPAIxUr1uo",
            "book": "箴14-17"
        },
        {
            "id": "278",
            "link": "https://www.youtube.com/watch?v=uRNPXTBCcng",
            "book": "箴18-21"
        },
        {
            "id": "279",
            "link": "https://www.youtube.com/watch?v=B2hlp8kEYjs",
            "book": "箴22-24"
        },
        {
            "id": "280",
            "link": "https://www.youtube.com/watch?v=QA6DDo6a85A",
            "book": "箴25-27"
        },
        {
            "id": "281",
            "link": "https://www.youtube.com/watch?v=RrtvdD_jQ5w",
            "book": "箴28-29"
        },
        {
            "id": "282",
            "link": "https://www.youtube.com/watch?v=d_SOvkDTsJQ",
            "book": "箴30-31"
        },
        {
            "id": "283",
            "link": "https://www.youtube.com/watch?v=B5BNRlukl0o",
            "book": "傳1-6"
        },
        {
            "id": "284",
            "link": "https://www.youtube.com/watch?v=5zkohtsRIrc",
            "book": "傳7-12"
        },
        {
            "id": "285",
            "link": "https://www.youtube.com/watch?v=chip-3iv4TY",
            "book": "歌1-8"
        },
        {
            "id": "286",
            "link": "https://www.youtube.com/watch?v=BOb-GhNOUr0",
            "book": "賽1-4"
        },
        {
            "id": "287",
            "link": "https://www.youtube.com/watch?v=GgAwhMu2qXM",
            "book": "賽5-6"
        },
        {
            "id": "288",
            "link": "https://www.youtube.com/watch?v=4h2d5I_8b9Y",
            "book": "賽7-8"
        },
        {
            "id": "289",
            "link": "https://www.youtube.com/watch?v=Ge4CM4WkTuI",
            "book": "賽9-12"
        },
        {
            "id": "290",
            "link": "https://www.youtube.com/watch?v=YJN7D2LK3nQ",
            "book": "賽13-14"
        },
        {
            "id": "291",
            "link": "https://www.youtube.com/watch?v=iJOQMfQu0RQ",
            "book": "賽15-16"
        },
        {
            "id": "292",
            "link": "https://www.youtube.com/watch?v=ddIeAt1ilo4",
            "book": "賽17-20"
        },
        {
            "id": "293",
            "link": "https://www.youtube.com/watch?v=cbuiz-ss7v0",
            "book": "賽21-23"
        },
        {
            "id": "294",
            "link": "https://www.youtube.com/watch?v=XU6d7NAZJ2k",
            "book": "賽24-27"
        },
        {
            "id": "295",
            "link": "https://www.youtube.com/watch?v=RmN9Eeigvfw",
            "book": "賽28-29"
        },
        {
            "id": "296",
            "link": "https://www.youtube.com/watch?v=dnoRsPjv33Q",
            "book": "賽30-31"
        },
        {
            "id": "297",
            "link": "https://www.youtube.com/watch?v=n7WL7XYVBEU",
            "book": "賽32-35"
        },
        {
            "id": "298",
            "link": "https://www.youtube.com/watch?v=9aYcGrLcLqE",
            "book": "賽36-39"
        },
        {
            "id": "299",
            "link": "https://www.youtube.com/watch?v=xYzabYWNjvM",
            "book": "賽40-43"
        },
        {
            "id": "300",
            "link": "https://www.youtube.com/watch?v=AQPYJ1v3WeQ",
            "book": "賽44-48"
        },
        {
            "id": "301",
            "link": "https://www.youtube.com/watch?v=OzJY1DJu7sM",
            "book": "賽49-51"
        },
        {
            "id": "302",
            "link": "https://www.youtube.com/watch?v=SwU_vCVmVmc",
            "book": "賽52-54"
        },
        {
            "id": "303",
            "link": "https://www.youtube.com/watch?v=NaFMJO0T3Nk",
            "book": "賽55-57"
        },
        {
            "id": "304",
            "link": "https://www.youtube.com/watch?v=iHtrC0FGg5U",
            "book": "賽58-62"
        },
        {
            "id": "305",
            "link": "https://www.youtube.com/watch?v=QbLV4WieBm4",
            "book": "賽63-66"
        },
        {
            "id": "306",
            "link": "https://www.youtube.com/watch?v=DU5vtNNCcbE",
            "book": "耶1-3"
        },
        {
            "id": "307",
            "link": "https://www.youtube.com/watch?v=KVCOVSHSGcA",
            "book": "耶4-6"
        },
        {
            "id": "308",
            "link": "https://www.youtube.com/watch?v=ksVr9fvZgsQ",
            "book": "耶7-10"
        },
        {
            "id": "309",
            "link": "https://www.youtube.com/watch?v=kyWQ0s0T6YM",
            "book": "耶11-13"
        },
        {
            "id": "310",
            "link": "https://www.youtube.com/watch?v=zex1WkQf1j0",
            "book": "耶14-16"
        },
        {
            "id": "311",
            "link": "https://www.youtube.com/watch?v=jtneRX0rpuA",
            "book": "耶17-20"
        },
        {
            "id": "312",
            "link": "https://www.youtube.com/watch?v=0H3O6MTRRwY",
            "book": "耶21-25"
        },
        {
            "id": "313",
            "link": "https://www.youtube.com/watch?v=5B2eKa0vmRc",
            "book": "耶26-29"
        },
        {
            "id": "314",
            "link": "https://www.youtube.com/watch?v=k9TtcKuapsY",
            "book": "耶30-33"
        },
        {
            "id": "315",
            "link": "https://www.youtube.com/watch?v=Zmjo65V2tyA",
            "book": "耶34-39"
        },
        {
            "id": "316",
            "link": "https://www.youtube.com/watch?v=6HIt--8-QsM",
            "book": "耶40-42"
        },
        {
            "id": "317",
            "link": "https://www.youtube.com/watch?v=UrAxp_heAlQ",
            "book": "耶43-45 1"
        },
        {
            "id": "318",
            "link": "https://www.youtube.com/watch?v=rpP1AW3rqfU",
            "book": "耶46-49"
        },
        {
            "id": "319",
            "link": "https://www.youtube.com/watch?v=lmhk1jpKK0c",
            "book": "耶50-52"
        },
        {
            "id": "320",
            "link": "https://www.youtube.com/watch?v=lOK04rR2Irw",
            "book": "哀1-5"
        },
        {
            "id": "321",
            "link": "https://www.youtube.com/watch?v=Yi2zAFabDZc",
            "book": "結1-6"
        },
        {
            "id": "322",
            "link": "https://www.youtube.com/watch?v=TGPNnILrNtc",
            "book": "結7-11"
        },
        {
            "id": "323",
            "link": "https://www.youtube.com/watch?v=HUYVbOCCE18",
            "book": "結12-14"
        },
        {
            "id": "324",
            "link": "https://www.youtube.com/watch?v=b_LN6KFTzzc",
            "book": "結15-16"
        },
        {
            "id": "325",
            "link": "https://www.youtube.com/watch?v=5MMIVJNK1eE",
            "book": "結17-19"
        },
        {
            "id": "326",
            "link": "https://www.youtube.com/watch?v=5qatBISdes0",
            "book": "結20-23"
        },
        {
            "id": "327",
            "link": "https://www.youtube.com/watch?v=F9mt3lxvNfE",
            "book": "結24-28"
        },
        {
            "id": "328",
            "link": "https://www.youtube.com/watch?v=7nrTqFzvdz8",
            "book": "結29-32"
        },
        {
            "id": "329",
            "link": "https://www.youtube.com/watch?v=2dtk6bM6FLk",
            "book": "結33-36"
        },
        {
            "id": "330",
            "link": "https://www.youtube.com/watch?v=-Zowr3Gx4ak",
            "book": "結37-38"
        },
        {
            "id": "331",
            "link": "https://www.youtube.com/watch?v=q4J2H6KOGkk",
            "book": "結39-40"
        },
        {
            "id": "332",
            "link": "https://www.youtube.com/watch?v=oSYWqgDTA8Y",
            "book": "結41-43"
        },
        {
            "id": "333",
            "link": "https://www.youtube.com/watch?v=laParj0xfwQ",
            "book": "結44-48"
        },
        {
            "id": "334",
            "link": "https://www.youtube.com/watch?v=sRH5siidgM0",
            "book": "但1-3"
        },
        {
            "id": "335",
            "link": "https://www.youtube.com/watch?v=ssI7agO56ao",
            "book": "但4-6"
        },
        {
            "id": "336",
            "link": "https://www.youtube.com/watch?v=j2ZSyG2T6sA",
            "book": "但7-12"
        },
        {
            "id": "337",
            "link": "https://www.youtube.com/watch?v=u2tqt4_xVME",
            "book": "何1-2"
        },
        {
            "id": "338",
            "link": "https://www.youtube.com/watch?v=Ip3FdSKcnYM",
            "book": "何3-4"
        },
        {
            "id": "339",
            "link": "https://www.youtube.com/watch?v=0kbYQrTPxAM",
            "book": "何5-6"
        },
        {
            "id": "340",
            "link": "https://www.youtube.com/watch?v=xShbhF70cL0",
            "book": "何7-8"
        },
        {
            "id": "341",
            "link": "https://www.youtube.com/watch?v=0vC4kakmTKA",
            "book": "何9-11"
        },
        {
            "id": "342",
            "link": "https://www.youtube.com/watch?v=AmY_ib3IsgQ",
            "book": "何12-14"
        },
        {
            "id": "343",
            "link": "https://www.youtube.com/watch?v=zlrS0niPf4s",
            "book": "珥1-3"
        },
        {
            "id": "344",
            "link": "https://www.youtube.com/watch?v=wwj2KSSUrX8",
            "book": "摩1-2"
        },
        {
            "id": "345",
            "link": "https://www.youtube.com/watch?v=Bl1toWID6aI",
            "book": "摩3"
        },
        {
            "id": "346",
            "link": "https://www.youtube.com/watch?v=Y2WFEObwFGE",
            "book": "摩4-5"
        },
        {
            "id": "347",
            "link": "https://www.youtube.com/watch?v=-l2XIBMglS4",
            "book": "摩6-7"
        },
        {
            "id": "348",
            "link": "https://www.youtube.com/watch?v=UO2lFOmo_Iw",
            "book": "摩8-9"
        },
        {
            "id": "349",
            "link": "https://www.youtube.com/watch?v=0ULQ4LhvMsY",
            "book": "俄1"
        },
        {
            "id": "350",
            "link": "https://www.youtube.com/watch?v=eJ4zzB318B4",
            "book": "拿1-4"
        },
        {
            "id": "351",
            "link": "https://www.youtube.com/watch?v=6mHlEOr7e20",
            "book": "彌1-2"
        },
        {
            "id": "352",
            "link": "https://www.youtube.com/watch?v=H0HFau12JVA",
            "book": "彌3"
        },
        {
            "id": "353",
            "link": "https://www.youtube.com/watch?v=V-t_UFslHnM",
            "book": "彌4-5"
        },
        {
            "id": "354",
            "link": "https://www.youtube.com/watch?v=PCqiz8ugnFc",
            "book": "彌6-7"
        },
        {
            "id": "355",
            "link": "https://www.youtube.com/watch?v=0lY3hmR08PE",
            "book": "鴻1-3"
        },
        {
            "id": "356",
            "link": "https://www.youtube.com/watch?v=EQD0V6NEO4Q",
            "book": "哈1-3"
        },
        {
            "id": "357",
            "link": "https://www.youtube.com/watch?v=wRQEMV5ojPM",
            "book": "番1-3"
        },
        {
            "id": "358",
            "link": "https://www.youtube.com/watch?v=2CSp0DeosEM",
            "book": "該1-2"
        },
        {
            "id": "359",
            "link": "https://www.youtube.com/watch?v=avG4hWf0WtA",
            "book": "亞1-2"
        },
        {
            "id": "360",
            "link": "https://www.youtube.com/watch?v=FvILzf50xdM",
            "book": "亞3-4"
        },
        {
            "id": "361",
            "link": "https://www.youtube.com/watch?v=UcICwKdt_HA",
            "book": "亞5-6"
        },
        {
            "id": "362",
            "link": "https://www.youtube.com/watch?v=wS_1AqopkVc",
            "book": "亞7-8"
        },
        {
            "id": "363",
            "link": "https://www.youtube.com/watch?v=M4gR93CVpGM",
            "book": "亞9-11"
        },
        {
            "id": "364",
            "link": "https://www.youtube.com/watch?v=qcyKczemp8o",
            "book": "亞12-14"
        },
        {
            "id": "365",
            "link": "https://www.youtube.com/watch?v=8sWE0tSUw4s",
            "book": "瑪1-4"
        }
    ]
    return dailyVerseList;
}