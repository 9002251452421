import React from 'react';
import {
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiHorizontalRule,
    EuiProgress,
    EuiButton
 
  } from '@elastic/eui';
import '../style.css';

const VoiceSearchBox = ({ listening, transcript, closeModal, cancelModal }) => {
  return (

        <div id="myModal" className="modal">

        <div className="modal-content">
        <div className="modal-header">
            <span className="closemodal" onClick={cancelModal}>&times;</span>
            <EuiText><h3>語音搜尋</h3></EuiText>
        </div>
        {(!listening) && <EuiHorizontalRule />}
        {(listening) && <><EuiSpacer></EuiSpacer><EuiProgress size="xs" color="accent" /><EuiSpacer></EuiSpacer></>}
        <div className="modal-body">
        <EuiText><b>{(transcript) ? transcript : (!listening) ? "語音辨認停止。請再試一次" : "聆聽中。。。"}</b></EuiText>
        </div>
        <EuiSpacer></EuiSpacer>
        <div className="modal-footer">
            <EuiFlexGroup>
            <EuiFlexItem></EuiFlexItem>
            <EuiFlexItem grow={false}><EuiButton color="primary" onClick={cancelModal}>取消</EuiButton></EuiFlexItem>
            <EuiFlexItem grow={false}><EuiButton color="primary" fill onClick={closeModal}>搜尋</EuiButton></EuiFlexItem>
            </EuiFlexGroup>
        </div>
        </div>

        </div>
  );
};

export default VoiceSearchBox;