import React ,{useState} from 'react';
import { 
        EuiButtonEmpty,
        EuiFlyoutBody,
        EuiFlyoutHeader,
        EuiButton,
        EuiText,
        EuiSpacer,
        EuiTitle,
        EuiFlyoutFooter,
        EuiDragDropContext,
        EuiDraggable,
        EuiDroppable,
        EuiPanel,
        EuiIcon,
        euiDragDropReorder,
        EuiFlexGroup,
        EuiFlexItem,
        copyToClipboard,
        EuiModal ,
        EuiModalHeader,
        EuiModalBody,
        EuiModalHeaderTitle,
        EuiModalFooter } from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';        
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import LoadUserSettings, {RemovePlayListItem, SaveUserSettings} from '../components/UserServices';


function Playlist(props){
    var language = localStorage.userLanguage;
    var videoJson = '[{"id": "i2", "playListId": "1", "title": "活出愛", "videoId": "lfP-3JLVvaw"}]'
    var videoIdList =  (LoadUserSettings("","worshipSongList", true)) ? LoadUserSettings("","worshipSongList", true) : JSON.parse(videoJson);
    // var videoIdList =  JSON.parse(videoJson);

    const [videoId, setVideoId] = useState("loJ8jTRfCik");
    const [currentVideoId, setCurrentVideoId] = useState(0);
    const [closePanelWhenPlay, setClosePanelWhenPlay] = useState(false);
    const [loopPlay, setLoopPlay] = useState(false);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(true);
    const [list, setList] = useState(videoIdList);
    const currentURL = window.location.origin;
    const divStyle = { "text-align": "center" };
    const [shareLink, setSharelink] = useState("");
    const [isModalVisible, setisModalVisible] = useState(false);
    
    const opts = {
        width:"",
        height: "",
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1 
        }
    };    
    
    const shareClicked = ()=> {
      var items = "";
      list.forEach((element, index) => {
        items += element.id.replace("ctb-worship-tc|","") +",";
      })
      items = window.location.origin + "/sharesong?items=" + btoa(items.substring(0,items.length-1));
      setSharelink(items);
      setisModalVisible(true);
    }

    const closeModal =()=> {
      copyToClipboard(shareLink);
      setisModalVisible(false);
    }

    let modal;
    if (isModalVisible) {
      modal = (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h2> {TranslateLanguage(language, "分享我的歌單")}</h2>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
  
          <EuiModalBody>
          {TranslateLanguage(language, "您可以使用以下的連結來分享您的歌單。")}
            <EuiSpacer />{shareLink}
          </EuiModalBody>
  
          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
            {TranslateLanguage(language, "複製 Copy Link")}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      );
    }

    const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
        const items = euiDragDropReorder(list, source.index, destination.index);
        setList(items);
        SaveUserSettings("","worshipSongList",items, true);
    }
    };

    const onRemoveItem = (index) => {
    var newList = RemovePlayListItem("", 1, index);
    setList(newList);
    }

    return (
        <>
        {modal}
        <EuiFlyoutHeader hasBorder>
        <div  style={{"text-align": "center"}}>
          <span><a href={currentURL}><img  src={logosmall} alt="Check The Bible" /></a> </span>
              <EuiTitle size="m"><h2 id="myworshipsongs">{TranslateLanguage(language, "我的歌單")}</h2></EuiTitle>
        </div>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
            <EuiText size="s">{TranslateLanguage(language, "您可以使用下面歌單中 ")}<EuiIcon type="grab"></EuiIcon>{TranslateLanguage(language, " 圖示拖拉詩歌項目來重新排序，或使用 X 圖示來移除詩歌。目前最多只能存取不重複15首")}</EuiText>
            <EuiSpacer></EuiSpacer>
            <EuiDragDropContext onDragEnd={onDragEnd} >
              <EuiDroppable droppableId="DROPPABLE_AREA" spacing="none">
                {list.map(({ videoId, title, id }, idx) => (
                  <EuiDraggable spacing="none" key={id} index={idx} draggableId={id} hasBorder={true} >
                    {(provided, state) => (
                      <EuiPanel hasShadow={state.isDragging} hasBorder={true} paddingSize="none"  style={{"margin": "3px", "border":"1px solid #D3DAE6"}}>
                        <EuiIcon type="grab" style={{"margin": "8px"}}/>
                        <EuiButtonEmpty iconType="cross" onClick={()=> onRemoveItem(idx)} ></EuiButtonEmpty>
                        {TranslateLanguage(language, 
                                              title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ") != "" ? 
                                              title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").substring(0,13) : title)}
                        {title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").length > 14 ? "..." : ""}
                      </EuiPanel>
                    )}
                  </EuiDraggable>
                ))}
              </EuiDroppable>
            </EuiDragDropContext>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize="m">
            <EuiFlexItem grow={false}><EuiButton href="/player">{TranslateLanguage(language, "開始播放")}</EuiButton></EuiFlexItem>
            <EuiFlexItem grow={false}><EuiButton size="m" color="primary" fill onClick={() => shareClicked()}>分享</EuiButton></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </>
  )
}

export default Playlist