import React, { useState} from "react";
import {useNavigate} from "react-router-dom";
import Verse from "../components/Verse"
import Comment from "../components/Comment";
import Gleaner from "../components/Gleaner";
import Testimony from "../components/Testimony";
import YTImage from "../components/YTImage";
import YTModal from "../components/YTModal";
import CrossReference from "../components/CrossReference";
import Prayer from "../components/Prayer";
import GotQuestions from "../components/GotQuestions";
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import VoiceSearchBox from '../components/VoiceSearchBox';
import Playlist from "../components/Playlist";
import ShareList from "../components/ShareList";
import DailyWidget from "../components/DailyWidget";
import Footer from '../components/Footer';    
import FacetView from '../components/FacetView';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import logomedium from '../assets/CTBLogoCircle-Medium.png';
import logo from '../assets/CTBLogo.png';
import micIcon from '../assets/CTB-Mic-P.png';
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiFlyoutFooter,
  EuiText,
  EuiTitle,
  EuiSpacer,
  EuiPanel,
  EuiButtonEmpty,
  EuiSelect,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiCheckbox,
  EuiToolTip,
  EuiFlexItem,
  EuiBadge,
  EuiPageTemplate,
  EuiEmptyPrompt,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiIcon,
  EuiLink
} from '@elastic/eui';
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";
import { Paging as PagingView } from "@elastic/react-search-ui-views";
import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { MultiCheckboxFacet } from "@elastic/react-search-ui-views";
import '@elastic/eui/dist/eui_theme_light.css';
import '../style.css';
import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "../config/config-helper";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import facetView from "../components/FacetView";
import LoadUserSettings, {SavePlayListItem, SaveShareListItem, SaveUserSettings} from '../components/UserServices';

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    disjunctiveFacets: ["doctype","chapter_name","verse","versenumber","publisher","tempo"],
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: false,
  initialState: {resultsPerPage: 20}
};

export default function Search() {
  const navigate = useNavigate();
  const [isFlyoutVisible, setIsFlyoutVisible] = useState({currentState: false, book: "", chapter: "", bookname: "", versenumber: "",  verse_id: "", link: "", doctype: ""});
  const closeFlyout = () => setIsFlyoutVisible({currentState: false, book: "", chapter:"", bookname: "", versenumber: "", verse_id: "", link: "", doctype: ""});
  const [exactSearch, setExactSearch] = useState(false);
  const [sortViewCnt, setSortViewCnt] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [YTVideo, setYTVideo] = useState({isActive: false, videoURL: "", id:"",title:""});
  const [isVoiceSearch, setIsVoiceSearch] = useState(false);
  const [itemSaved, setItemSaved] = useState(false);
  const currentURL = window.location.origin;
  var currentBrowserLanguage = (navigator.language || navigator.userLanguage) == "zh-CN" ? "S" : "T"
  var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
  localStorage.userLanguage = userLanguage;
  const [language, setLanguage] = useState(userLanguage);

  const onChangeLanguage  = () => {
       language == "T"? setLanguage("S"):setLanguage("T");
       language == "T"? localStorage.userLanguage = "S" : localStorage.userLanguage = "T"
       window.scrollTo(0, 0);
      };
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  let flyout;
  if (isFlyoutVisible.currentState) {
    flyout = (
      <EuiFlyout
        ownFocus
        onClose={() => setIsFlyoutVisible({currentState: false, book: "", chapter:"", bookname: "", versenumber: "",  verse_id: "", link: "", doctype: ""})}
        aria-labelledby="flyoutTitle">
          {isFlyoutVisible.doctype == "經文" && <Verse book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} language={language} />}
          {isFlyoutVisible.doctype == "註解" && <Comment book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "拾穗" && <Gleaner book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber}  language={language} />}
          {isFlyoutVisible.doctype == "例證" && <Testimony book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} language={language}  />}
          {isFlyoutVisible.doctype == "串珠" && <CrossReference book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id} language={language} />}
          {isFlyoutVisible.doctype == "禱告" && <Prayer book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id}  language={language} />}
          {isFlyoutVisible.doctype == "信仰問答" && <GotQuestions book={isFlyoutVisible.book} chapter={isFlyoutVisible.chapter} bookname={isFlyoutVisible.bookname} versenumber={isFlyoutVisible.versenumber} verse_id ={isFlyoutVisible.verse_id}  language={language} />}
          {isFlyoutVisible.doctype == "我的歌單" && <Playlist language={language}/>}
          {isFlyoutVisible.doctype == "我的收藏" && <ShareList language={language}/>}
      </EuiFlyout>
    );
  }

  let ytPreview;
  if (YTVideo.isActive){
    ytPreview = (
      <EuiModal style={{ width: 800 }} onClose={()=>{setYTVideo({isActive: false, videoURL: "", id: "", title: ""}); setItemSaved(false)}}><EuiModalBody>
          <YTModal videoURL={YTVideo.videoURL}></YTModal>
          </EuiModalBody>
          <EuiModalFooter>
          <EuiFlexGroup>
            <EuiFlexItem></EuiFlexItem>
            {itemSaved ? (<EuiFlexItem grow={false}>
              <EuiIcon type="check" size="l"  paddingSize="none"></EuiIcon>
            </EuiFlexItem>)
            : null}
            <EuiFlexItem grow={false} gutterSize="s">
              <EuiButtonEmpty iconType="heart" size="xs" color="primary" onClick={()=>{
                              SavePlayListItem("",1,YTVideo.id, YTVideo.videoURL, YTVideo.title);
                              setItemSaved(true);
                              }}>
                              {TranslateLanguage(language,"加入我的歌單")}
              </EuiButtonEmpty>
            </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton color="primary" onClick={()=>{setYTVideo({isActive: false, videoURL:"", id: "", title: ""}); setItemSaved(false);}}>關閉</EuiButton>
              </EuiFlexItem>
          </EuiFlexGroup>  
          </EuiModalFooter>
      </EuiModal>
    );
  }

  return (
    
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ resultSearchTerm, setSearchTerm, results, setFilter, removeFilter, setSort, wasSearched }) => ({
          resultSearchTerm,
          setSearchTerm,
          results,
          setFilter,
          removeFilter,
          setSort,
          wasSearched
        })} 
      >
        {({ resultSearchTerm, setSearchTerm, results, setFilter, removeFilter, setSort, wasSearched }) => {

            // modal handling

            const closeModal = () => {
              setIsVoiceSearch(false);
              (transcript) && setSearchTerm(TranslateInput(transcript)) ;
              SpeechRecognition.stopListening()
            }
            const cancelModal = () => {
              setIsVoiceSearch(false);
              SpeechRecognition.stopListening()
            }
            const showModal = () => {
              setIsVoiceSearch(true);
              SpeechRecognition.startListening({ language: 'cmn-Hant-TW' });
            }

            // end of modal handleing

            const showYTPreview = (id, link, title) => {
              setYTVideo({isActive: true, videoURL: link, id: id, title: title});
            }

            //Misc.

            function getFilterValueDisplay(filterValue) {
              if (filterValue === undefined || filterValue === null) return "";
              if (filterValue.hasOwnProperty("name")) return filterValue.name;
              return String(filterValue);
            }

            // handle things need to update DOM after load
            const lazyLoading = () =>{
              setTimeout(function () {
                var element = document.getElementsByClassName("sui-layout-sidebar-toggle");
                if(element.length != 0){
                  element[0].innerHTML = TranslateLanguage(language,"選擇更多搜尋條件");
                  element[1].innerHTML = TranslateLanguage(language,"回到搜尋結果");
                }
            }, 0);
          }

          return (
            <div className='App'>
              {lazyLoading()}
              {isVoiceSearch ? (
                <VoiceSearchBox
                  listening={listening}
                  transcript={transcript}
                  closeModal={closeModal}
                  cancelModal={cancelModal}
                />
              ) : null}

              {wasSearched ? (
              <ErrorBoundary>
                <Layout
                  header={<EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                            <span align="center"><a href={currentURL}><picture>
                                <source media="(min-width:1280px)" srcSet={logosmall} />
                                <source media="(min-width:768px)" srcSet={logosmall} />
                                <img src={logomedium}  alt="Check The Bible" />
                            </picture></a></span>
                            </EuiFlexItem>
                            <EuiFlexItem  justifyContent="left">
                            <SearchBox autocompleteSuggestions={false} className = "bar-result"
                                      onSubmit={(e) => {
                                        var term = document.getElementById("inputTerm").value;
                                        var newValue = exactSearch ? "\"" + term.split("\"").join("") + "\"" : term.split("\"").join("");
                                        newValue = TranslateInput(newValue);
                                        if(newValue.substring(0,1) == "#"){setSearchTerm(newValue); setFilter("tag",newValue.replace("#",""), "any");} 
                                        if(newValue.substring(0,1) != "#"){setSearchTerm(newValue); removeFilter("doctype");} 
                                      }} 
                                      inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                                        <>
                                          <input    
                                                {...getInputProps({
                                                    placeholder: TranslateLanguage(language, "請輸入關鍵字。如馬太福音"),
                                                    id: "inputTerm",
                                                    className: "searchbar-result",
                                                    autocomplete: "off"
                                                })}/>
                                          {/* {getAutocomplete()} */}
                                          {(browserSupportsSpeechRecognition) && <img onClick={()=>{showModal();}}  className="voice-result"  src={micIcon} title={TranslateLanguage(language,"語音搜尋")} />}
                                          <EuiButtonIcon iconType="search" className="searchbutton-result" onClick={()=>{
                                                var term = document.getElementById("inputTerm").value;
                                                var newValue = exactSearch ? "\"" + term.split("\"").join("") + "\"" : term.split("\"").join("");
                                                newValue = TranslateInput(newValue);
                                                if(newValue.substring(0,1) == "#"){setSearchTerm(newValue); setFilter("tag",newValue.replace("#",""), "any");} 
                                                if(newValue.substring(0,1) != "#"){setSearchTerm(newValue); removeFilter("doctype");} 
                                          }}>搜尋</EuiButtonIcon>
                                        </>
                                      )}
                            />
                            </EuiFlexItem>
                            {/* <EuiFlexGroup alignItems="center" responsive={false}>
                              <EuiFlexItem grow={false}><EuiButton color="text" iconType="search" className="searchbutton" onClick={()=>{setSearchTerm(TranslateInput((document.getElementById("inputTermHome").value)));}}>搜尋</EuiButton></EuiFlexItem>
                            </EuiFlexGroup> */}
                          </EuiFlexGroup>
                          }
                  sideContent={
                    <div>
                      {wasSearched && (
                        <Sorting
                          label={"排序/Sort"}
                          sortOptions={buildSortOptionsFromConfig()} 
                        />
                      )}
                      {/* {getFacetFields().map(field => (
                        <Facet key={field} field={field} label={field} />
                      ))} */}
                      <EuiSpacer></EuiSpacer>
                      <EuiText size="xs" color="subdued">{TranslateLanguage(language,"搜尋方式")}</EuiText>
                      <EuiSpacer size="s"></EuiSpacer>
                      <EuiCheckbox  id="exclusivesearch"
                                    label="最佳符合"
                                    checked={exactSearch}
                                    onChange={(e) => {
                                      var newSearchTerm = document.getElementById("inputTerm").value;
                                      newSearchTerm = e.target.checked ?  "\"" + newSearchTerm + "\"" : newSearchTerm.split("\"").join("");
                                      newSearchTerm = TranslateInput(newSearchTerm);
                                      // setSearchTerm(newSearchTerm);
                                      if(newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any");} 
                                      if(newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm); removeFilter("doctype");}                                       
                                      setExactSearch(e.target.checked);
                                    }}
                      ></EuiCheckbox>
                      <EuiCheckbox  id="sortViewCount"
                                    label="按關注度排序 - 詩歌"
                                    checked={sortViewCnt}
                                    onChange={(e) => {
                                      var newSearchTerm = document.getElementById("inputTerm").value;
                                      newSearchTerm = TranslateInput(newSearchTerm);
                                      // setSearchTerm(newSearchTerm);
                                      if(!sortViewCnt && newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any"); setSort("viewct","desc");} 
                                      if(!sortViewCnt && newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm);  setSort("viewct","desc");}                                       
                                      if(sortViewCnt && newSearchTerm.substring(0,1) == "#"){setSearchTerm(newSearchTerm); setFilter("tag",newSearchTerm.replace("#",""), "any");setSort("","");} 
                                      if(sortViewCnt && newSearchTerm.substring(0,1) != "#"){setSearchTerm(newSearchTerm); setSort("","");}                                       
                                  
                                      setSortViewCnt(e.target.checked);
                                    }}
                       ></EuiCheckbox>

                      <EuiSpacer></EuiSpacer>
                      <Facet field="doctype" label="搜尋來源" className={language} view={facetView} filterType="any" show={8} />
                      <Facet field="testment" label="書別" className={language} view={facetView} filterType="any" />
                      <Facet field="chapter_name" label="篇章" className={language} view={facetView} filterType="any" />
                      <Facet field="category" label={TranslateLanguage(language,"分類")}  className={language} view={facetView} filterType="any" />
                      <Facet field="language" label="語言" className={language} view={facetView} filterType="any" />
                      <Facet field="publisher" label="發行者" className={language} view={facetView} filterType="any" />
                      <Facet field="tempo" label="歌速" className={language} view={facetView} filterType="any" />
                    </div>
                  }
                  bodyContent={
                    <>
                      {results.map(r => (
                        <div>
                          <EuiSpacer size="m" />
                          <EuiPanel>
                            <div key={r.id.raw}> </div>
                            <div><EuiTitle><span dangerouslySetInnerHTML={{__html: TranslateLanguage( language, r.title.snippet)}}></span></EuiTitle></div> 
                            <div>
                            {(r.hasOwnProperty('chapter_name')) && 
                              <EuiText><h4><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language, r.chapter_name.snippet) + ' ' + r.chapter_verse_number.raw}} /></h4></EuiText>}

                            {(r.hasOwnProperty('publisher')) && 
                              <EuiText><h4><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.publisher.snippet)}} /></h4></EuiText>}  

                            {(((r.doctype.raw == "詩歌") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>
                                <EuiFlexItem><EuiText size="s"><span dangerouslySetInnerHTML={{__html: '歌詞: ' + TranslateLanguage(language,r.description.raw)}} /></EuiText>
                                {(r.hasOwnProperty('language')) && <EuiText size="s"><EuiSpacer size="s"></EuiSpacer><span dangerouslySetInnerHTML={{__html: '語言: ' + TranslateLanguage(language,r.language.raw)}} /></EuiText>}
                                {(r.hasOwnProperty('tempo')) && <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: '速度: ' + TranslateLanguage(language,r.tempo.raw)}} /></EuiText>}
                                </EuiFlexItem>
                              </EuiFlexGroup>}
                            
                              {(((r.doctype.raw == "信息") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>
                                <EuiFlexItem>
                                  {(r.speaker.raw) && <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '<h4>講員:</h4> ' + TranslateLanguage(language,r.speaker.raw)}} /></EuiText>} 
                                  {(r.description.raw) && <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '<h4>摘要:</h4> ' + TranslateLanguage(language,r.description.raw)}} /></EuiText>}
                                  {(r.hasOwnProperty('language')) && <EuiText size="s"><EuiSpacer size="s"></EuiSpacer><span dangerouslySetInnerHTML={{__html: '語言: ' + TranslateLanguage(language,r.language.raw)}} /></EuiText>}
                                </EuiFlexItem>
                              </EuiFlexGroup>}

                            {(((r.doctype.raw == "網路") ) && (r.hasOwnProperty('description'))) && 
                              <EuiFlexGroup gutterSize="s">
                                {(r.category.raw == "見證") && <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>}
                                <EuiFlexItem><EuiText size="s">{(r.category.raw == "信仰問答") ? <span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.description.snippet) + '...'}} />
                                                        : <span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.description.raw) + '...'}} />}
                                              </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>}      

                              {(((r.doctype.raw == "禱告") ) && (r.hasOwnProperty('prayer'))) && 
                              <EuiFlexGroup gutterSize="s">
                                {(r.link.raw) && <EuiFlexItem grow={false}><EuiLink onClick={()=> showYTPreview(r.id.raw, r.link.raw, r.title.raw)}><YTImage link={r.link.raw} ></YTImage></EuiLink></EuiFlexItem>}
                                <EuiFlexItem><EuiText size="s"><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.prayer.raw)}} />
                                              </EuiText>
                                </EuiFlexItem>
                              </EuiFlexGroup>}      

                            {(((r.doctype.raw == "網路") ) && (r.hasOwnProperty('origin'))) && 
                              <EuiFlexGroup gutterSize="s">
                                <EuiFlexItem><EuiText size="s"><span dangerouslySetInnerHTML={{__html: '來源：' + TranslateLanguage(language,r.origin.raw)}} /></EuiText></EuiFlexItem>
                              </EuiFlexGroup>}                            

                            {/* {(r.hasOwnProperty('language')) && 
                              <EuiText size="s"><span dangerouslySetInnerHTML={{__html: '語言: ' + TranslateLanguage(language,r.language.raw)}} /></EuiText>} */}

                            {((r.hasOwnProperty('verselist')) && (r.verselist.snippet)) &&  
                              <EuiText size="s"><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,'相關經文: ' + r.verselist.snippet)}} /></EuiText>}

                            {/* {(r.hasOwnProperty('tempo')) && <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: '速度: ' + TranslateLanguage(language,r.tempo.raw)}} /></EuiText>} */}

                            {(r.hasOwnProperty('source')) && 
                              <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.source.snippet)}} /></EuiText>}     
                           
                            {/* ==============  Menu link items ================================ */}
                            
                            <EuiFlexGroup gutterSize="xs" alignItems="center" responsive={false} wrap={true} id={"menu_"+r.id.raw}>
                            
                              <EuiFlexItem grow={false} >
                               
                                {(r.doctype.raw == "經文") && <EuiBadge color="hollow">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "註解") && <EuiBadge color="primary">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "詩歌") && <EuiBadge color="#FEA27F">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "例證") && <EuiBadge color="#BADA55">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "拾穗") && <EuiBadge color="#FCF7BC">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "綱目") && <EuiBadge color="#DDD">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "禱告") && <EuiBadge color="accent">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "網路") && <EuiBadge color="secondary">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                                {(r.doctype.raw == "信息") && <EuiBadge color="#99C172">{TranslateLanguage(language,r.doctype.raw)}</EuiBadge>}
                              </EuiFlexItem>

                              {(r.hasOwnProperty('category')) && 
                              <EuiText size="s"><span  dangerouslySetInnerHTML={{__html: TranslateLanguage(language,r.category.snippet)}} /></EuiText>}    

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem grow={false}>
                                  <EuiButtonEmpty iconType="document" size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.chapter_code_name.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: r.doctype.raw })}>{TranslateLanguage(language,"本篇經文")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="inspect"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "註解" })}>{TranslateLanguage(language,"經文註解")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="inspect"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "拾穗" })}>{TranslateLanguage(language,"拾穗")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="inspect"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "例證" })}>{TranslateLanguage(language,"例證")}</EuiButtonEmpty>
                                </EuiFlexItem>}
                                
                              {(r.doctype.raw == "經文") && 
                                <EuiFlexItem  grow={false}>
                                  <EuiButtonEmpty iconType="branch"  size="xs" color="primary" 
                                  onClick={() => setIsFlyoutVisible({currentState: true, book: r.book_number.raw, chapter: r.chapter_number.raw, bookname: r.chapter_name.raw, versenumber: r.chapter_verse_number.raw, verse_id: r.verse_id.raw, link: "", doctype: "串珠" })}>{TranslateLanguage(language,"串珠")}</EuiButtonEmpty>
                                </EuiFlexItem>}

                              {(r.doctype.raw == "禱告" && (r.link.raw) == "") && 
                              <EuiFlexItem  grow={false}>
                                <EuiButtonEmpty iconType="document"  size="xs" color="primary" 
                                onClick={() => setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: r.title.raw, versenumber: r.verselist.raw, verse_id: r.id.raw, link: "", doctype: "禱告" })}>{TranslateLanguage(language,"禱告內容")}</EuiButtonEmpty>
                              </EuiFlexItem>}

                              {((r.hasOwnProperty('link') && (r.link.raw))) && (r.doctype.raw != '網路') &&
                                <EuiFlexItem grow={false}>
                                  <EuiButtonEmpty iconType="link" size="xs" color="primary" href={(r.hasOwnProperty('link')) && r.link.raw} target='_blank'>{TranslateLanguage(language,"連結來源")}</EuiButtonEmpty>
                               </EuiFlexItem>}

                              {((r.hasOwnProperty('link') && (r.link.raw))) && (r.doctype.raw == '網路') &&
                              <EuiFlexItem grow={false}>
                              {(r.category.raw == "信仰問答") ?
                              <EuiButtonEmpty iconType="link"  size="xs" color="primary" 
                              onClick={() => setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: r.title.raw, versenumber: r.verselist.raw, verse_id: r.id.raw, link: "", doctype: "信仰問答" })}>{TranslateLanguage(language,"詳細內容")}</EuiButtonEmpty>
                              :<EuiButtonEmpty iconType="link" size="xs" color="primary" href={(r.hasOwnProperty('link')) && r.link.raw} target='_blank'>{TranslateLanguage(language,"連結來源")}</EuiButtonEmpty>
                              }
                              </EuiFlexItem>
                              }

                              {(r.doctype.raw == "詩歌") &&
                              
                              <EuiFlexItem  grow={false}>
                              <EuiButtonEmpty iconType="heart" size="xs" color="primary" onClick={()=>{
                                SavePlayListItem("",1,r.id.raw, r.link.raw, r.title.raw);
                                setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: "", verse_id: "", link: "", doctype: "我的歌單"});}}>
                                {TranslateLanguage(language,"加入我的歌單")}</EuiButtonEmpty>
                              </EuiFlexItem>
                              
                              }    
                              {(r.doctype.raw == "詩歌") &&
                              
                                <EuiFlexItem  grow={false}>
                                <EuiButtonEmpty iconType="play" size="xs" color="primary" href="/player">{TranslateLanguage(language,"播放我的歌單")}</EuiButtonEmpty>
                                </EuiFlexItem>
                              }    
                              
                              <EuiFlexItem  grow={false}>
                              <EuiButtonEmpty iconType="heart" size="xs" color="primary" onClick={()=>{
                                var itemType = "";
                                itemType = (r.doctype.raw == "詩歌" || r.doctype.raw == "信息" ) ? "v" : "t";
                                itemType = (r.hasOwnProperty('category')) ? (r.category.raw == "見證") ? "v" : "t" : "t";
                                var link = (r.hasOwnProperty('link')) ? r.link.raw : "";
                                SaveShareListItem("",1,r.id.raw, link , r.title.raw, itemType, r.doctype.raw );
                                setIsFlyoutVisible({currentState: true, book: "", chapter: "", bookname: "", verse_id: "", link: "", doctype: "我的收藏"});}}>
                                {TranslateLanguage(language,"收藏與分享")}</EuiButtonEmpty>
                              </EuiFlexItem>
                              
                            </EuiFlexGroup>
                            {/* ==============  End of Menu link items ================================*/}

                            <EuiFlexGroup gutterSize="xs" alignItems="left" responsive={false} wrap={true}>
                            
                            {(r.tag.raw) && 
                              r.tag.raw.map(tagitem =>(
                                <>
                                <div key={tagitem}></div>
                                <EuiFlexItem  grow={false} >
                                  <EuiButtonEmpty size="xs" color="primary" 
                                  onClick={() => {setSearchTerm("#"+tagitem); setFilter("tag",tagitem, "any");} }>#{tagitem}</EuiButtonEmpty>
                                </EuiFlexItem>
                                </>
                              ))                          
                              } 
                            </EuiFlexGroup>

                            </div>
                          </EuiPanel>
                        </div>
                      ))}
                    </>
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage options={[10, 20, 40, 60]} />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging 
                    view={(props) =>
                      PagingView({
                        ...props,
                        onChange: (value) => {
                          window.scrollTo(0, 0);
                          return props.onChange(value);
                        }
                      })
                    }/>
                    }
                />
              </ErrorBoundary>
              // Landing page with no search term
              // Home page
              ) : (
                  <EuiPageTemplate 
                  template="centeredbody"
                  pageContentProps={{ paddingSize: 'l' }}
                  >
                    <EuiEmptyPrompt title={<span><img className="responsive-logo" src={logo} alt="Check The Bible" /></span>} 
                    body={
                    <SearchBox  autocompleteSuggestions={false} className = "bar"
                                onSubmit={(e) => {
                                  setSearchTerm(TranslateInput((document.getElementById("inputTermHome").value)));
                                }}
                                inputView={() => (
                                    <>
                                      <input className="searchbar" id="inputTermHome" autocomplete="off" placeholder={TranslateLanguage(language,"請輸入關鍵字。如馬太福音")} autoFocus={isMobile} />
                                      {(browserSupportsSpeechRecognition) && <img onClick={()=>{setIsMobile(false);showModal();}}  className="voice"  src={micIcon} title="語音搜尋" />}
                                    </>
                                  )}
                    /> } 
                  /><EuiFlexGroup alignItems="center" responsive={false}>
                      <EuiFlexItem />
                      <EuiFlexItem grow={false}><EuiButton color="text" iconType="search" className="searchbutton" onClick={()=>{setSearchTerm(TranslateInput((document.getElementById("inputTermHome").value)));}}>{TranslateLanguage(language,"搜尋")}</EuiButton></EuiFlexItem>
                      <EuiFlexItem />
                    </EuiFlexGroup>
                    <EuiFlexGroup alignItems="center" responsive={false}>
                      <EuiFlexItem>
                                 
                      </EuiFlexItem>
                    </EuiFlexGroup>
                <DailyWidget></DailyWidget> 

                </EuiPageTemplate>   
              )
              // End of Home Page
             }  
              {flyout}
              {ytPreview}
            </div>
            
          );
        }}
      </WithSearch>
      <Footer withLanguage="1" />
    </SearchProvider>
    
  );
}
