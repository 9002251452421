import React ,{useState, useEffect} from 'react';
import ShareList from './ShareList';
import{ 
    EuiFlexGroup,
    EuiFlexItem
} from '@elastic/eui';

function SharePage(props) {

return(

            <ShareList></ShareList>

    )
}


export default SharePage;