import React from 'react';
import { EuiImage} from '@elastic/eui';

function YTImage(props) {
    var url =  props.link;
    var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    var imagepath ="";
    if (videoid != null) {
        imagepath = 'https://img.youtube.com/vi/' + videoid[1].substring(0,11) + '/mqdefault.jpg'
     return (

         <EuiImage 
         float="left"
         margin="s"
         alt=""
         src={imagepath} />
     )
    } else {
      return "";
    }
}

export default YTImage