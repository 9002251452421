import React ,{useState, useEffect} from 'react';
import YouTube from "react-youtube";
import { 
        EuiFlexGroup,
        EuiFlexItem,
        EuiSpacer,
        EuiButton,
        EuiTitle,
        EuiText,
        EuiModal,
        EuiModalHeader,
        EuiModalBody,
        EuiModalHeaderTitle,
        EuiModalFooter,
        euiDragDropReorder,
        copyToClipboard} from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';                
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import LoadUserSettings, {RemovePlayListItem, SaveUserSettings, ExtractItemFromURL} from '../components/UserServices';
import Footer from '../components/Footer';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

var videoJson = '[{"id": "i1", "playListId": "1", "title": "耶穌，我愛你", "videoId": "loJ8jTRfCik"}, {"id": "i2", "playListId": "1", "title": "活出愛", "videoId": "lfP-3JLVvaw"}, {"id": "i3", "playListId": "1", "title": "我們高舉雙手" , "videoId": "7gB0drDYJy4"}]'
var videoIdList =  (LoadUserSettings("","worshipSongList", true)) ? LoadUserSettings("","worshipSongList", true) : JSON.parse(videoJson);

// var videoIdList =  JSON.parse(videoJson);

function ShareSong(props) {

  const [videoId, setVideoId] = useState("loJ8jTRfCik");
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [closePanelWhenPlay, setClosePanelWhenPlay] = useState(false);
  const [loopPlay, setLoopPlay] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [list, setList] = useState(videoIdList);
  const [isLoaded, setIsLoaded] = useState(false);
  const [shareLink, setSharelink] = useState("");

  const currentURL = window.location.origin;
  const language = localStorage.userLanguage;
  const urlParams = new URLSearchParams(window.location.search);
    
  const getList = async () =>{
    if(urlParams.has('items')){
      var itemList = urlParams.get('items');
      var matchItemList = atob(itemList).split(',');
      var filterList =[];
          matchItemList.forEach((element, index) => {
          filterList.push("ctb-worship-tc|" + element)
        });
      await fetch(apiURL,{
              method:"POST",
              headers:{
                  "Content-Type":"application/json, charset=UTF-8",
                  "Authorization":  "Bearer search-" + apiKey
              },
              body:JSON.stringify({
                  query:"",
                  filters: {"any": {"id": filterList}  }, 
                  page:{size: 1000},
                  sort:{id: "asc"}
              })
          })
          .then(function(response){
              return(response.json())
          })
          .then(function(data){
              var results = JSON.parse(JSON.stringify(data));
              var extractedItems = [];
              filterList.forEach((element, index) => {
                var foundItem = results.results.find(({id}) => id.raw === element)
                if(foundItem){
                  var videoId = foundItem.link.raw.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)[1];
                  extractedItems.push({
                    id: foundItem.id.raw,
                    playListId: "1",
                    title: foundItem.title.raw,
                    videoId: videoId,
                    description: foundItem.description.raw,
                    title: foundItem.title.raw,
                    publisher: foundItem.publisher.raw,
                    verselist: foundItem.verselist.raw
                  });  
                }
              })
              setList(extractedItems);
              setIsFlyoutVisible(false);
              loadVideo(currentVideoId);
          }
        )
    }
  }

  const opts = {
    width:"",
    height: "",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
       autoplay: 1,
       playsinline: 1,
       enablejsapi: 1
     //  mute: 1
    }
  };     
    useEffect(()=>{
        // list.length > 0 && loadVideo(currentVideoId);
       if(urlParams.has('items')){
            getList() 
            setIsLoaded(true);
       } else {
            document.location.href="/";
       } 
     },[])

    const loadVideo = (videoIndex) => {
        if(videoIndex < list.length && videoIndex >= 0){
            setCurrentVideoId(videoIndex)
         }
    }

    const onReady = (e) => {
      e.target.playVideo();
    }
    const onClosePanelWhenPlay = (e) => {
      setClosePanelWhenPlay(e.target.checked);
      setIsFlyoutVisible(false);
    }

    const onLoopPlay = (e) => {
      setLoopPlay(e.target.checked);
    }
 
    const checkStateChange = (e) => {
        if(e.target.playerInfo.playerState === 0){
            // auto next
            var nextId = currentVideoId + 1;
            if(loopPlay && nextId >= videoIdList.length ){
               nextId = 0;
            }
            loadVideo(nextId);
            setIsFlyoutVisible(false);        
          }
        if(e.target.playerInfo.playerState !== 0 ){
          setIsFlyoutVisible(true);
        }
        if(e.target.playerInfo.playerState === 1 && closePanelWhenPlay){
          setIsFlyoutVisible(false);
        }
      };

      const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
          const items = euiDragDropReorder(list, source.index, destination.index);
          setList(items);
          // localStorage.worshipSongList = JSON.stringify(items);
          SaveUserSettings("","worshipSongList",items, true);
          window.location.reload();
        }
      };

      const onRemoveItem = (index) => {
        var newList = RemovePlayListItem("", 1, index);
        setList(newList);
        window.location.reload();
      }

      const shareClicked = ()=> {
        var items = "";
        list.forEach((element, index) => {
          items += element.id.replace("ctb-worship-tc|","") +",";
        })
        items = window.location.origin + "/player?items=" + btoa(items.substring(0,items.length-1));
        setSharelink(items);
        setisModalVisible(true);
      }

      const closeModal =()=> {
        copyToClipboard(shareLink);
        setisModalVisible(false);
      }

      let modal;
      if (isModalVisible) {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h2> {TranslateLanguage(language, "分享我的歌單")}</h2>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
    
            <EuiModalBody>
            {TranslateLanguage(language, "您可以使用以下的連結來分享您的歌單。")}
              <EuiSpacer />{shareLink}
            </EuiModalBody>
    
            <EuiModalFooter>
              <EuiButton onClick={closeModal} fill>
              {TranslateLanguage(language, "複製 Copy")}
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        );
      }

    return (
      <div>
          <EuiFlexGroup responsive={false} alignItems="center">
              <EuiFlexItem></EuiFlexItem>
              <EuiFlexItem style={{ textAlign: 'center'}} grow={false}>
                 <span><a href={currentURL}><img  src={logosmall} alt="Check The Bible" /></a></span>
              </EuiFlexItem>
              <EuiFlexItem  grow={false}>
              <EuiTitle size="m"><h2 id="myworshipsongs">{TranslateLanguage(language, "詩歌分享")}</h2></EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem></EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
              <EuiFlexItem>
                  {isLoaded ? (
                    <YouTube
                        id="ytPlayer"
                        videoId={list[currentVideoId].videoId}
                        className="yt-iframe"
                        containerClassName="yt-container"
                        onStateChange={(e) => checkStateChange(e)}
                        onReady={onReady}
                        opts={opts}
                    />
                  ) : <EuiText>Loading...</EuiText>
                  }
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ width: 300, padding: 20} }>
                    <EuiTitle size="m"><h3>{list[currentVideoId].title}</h3></EuiTitle>
                    <EuiSpacer></EuiSpacer>
                    <EuiText>{list[currentVideoId].description}</EuiText>
                    <EuiSpacer></EuiSpacer>
                    <EuiText>{list[currentVideoId].publisher}</EuiText>
                    <EuiSpacer></EuiSpacer>
                    <EuiText>{list[currentVideoId].verselist}</EuiText>
              </EuiFlexItem>
               
          </EuiFlexGroup>
          <Footer />
    </div>
  )
}

export default ShareSong