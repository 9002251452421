export default function LoadUserSettings(userId, key, isJson){
    var returnResult;
    returnResult = (isJson) ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key)
    return returnResult;
}

export function SaveUserSettings(userId, key, value, isJson){
    var returnResult;
    var saveValue = (isJson) ? JSON.stringify(value) : value
     JSON.stringify(localStorage.setItem(key, saveValue)) 
    return returnResult;
}

export function SavePlayListItem(userId, playListId, id, videoURL, title ){
    const maxNumerOfItems = 15;
    const tableKey = "worshipSongList";
    var videoId = videoURL.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    var playlistItems = LoadUserSettings(userId, tableKey, true);
    if(playlistItems !== null ){
        if(playlistItems.length > maxNumerOfItems){playlistItems.shift();} 
        playlistItems = playlistItems.filter(item => item.id !== id);
        playlistItems.push({"id": id, "playListId": playListId, "title": title , "videoId": videoId[1]});
        SaveUserSettings(userId, tableKey, playlistItems, true)
    }else{
        var newPlaylistItem = '[{"id": "' + id + '", "playListId": "1", "title": "' + title + '", "videoId": "' + videoId[1] + '"}]';
        SaveUserSettings(userId, tableKey, newPlaylistItem, false);
    }
    return playlistItems;
}

export function RemovePlayListItem(userId, playListId, itemIndex){
    const tableKey = "worshipSongList";
    var playlistItems = LoadUserSettings(userId, tableKey, true);
    if(itemIndex >= 0){
        playlistItems.splice(itemIndex,1);
        SaveUserSettings(userId, tableKey, playlistItems, true)
    }
    return playlistItems;
}

export function SaveShareListItem(userId, playListId, id, videoURL, title, itemType, docType ){
    const maxNumerOfItems = 15;
    const tableKey = "shareItemList";
    var videoId = (itemType=="v") ? videoURL.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/) : "" ;
    var link =  (itemType=="v") ? videoId[1] : "";
    var shareListItems = LoadUserSettings(userId, tableKey, true);
    if(shareListItems !== null ){
        if(shareListItems.length > maxNumerOfItems){shareListItems.shift();} 
        shareListItems = shareListItems.filter(item => item.id !== id);
        shareListItems.push({"id": id, "playListId": playListId, "title": title , "videoId": link, "docType": docType});
        SaveUserSettings(userId, tableKey, shareListItems, true)
    }else{
        var newShareListItem = '[{"id": "' + id + '", "playListId": "1", "title": "' + title + '", "videoId": "' + videoId[1] + '", "docType:": "' + docType + '"}]';
        SaveUserSettings(userId, tableKey, newShareListItem, false);
    }
    return shareListItems;
}

export function RemoveShareListItem(userId, playListId, itemIndex){
    const tableKey = "shareItemList";
    var shareListItems = LoadUserSettings(userId, tableKey, true);
    if(itemIndex >= 0){
        shareListItems.splice(itemIndex,1);
        SaveUserSettings(userId, tableKey, shareListItems, true)
    }
    return shareListItems;
}

export function ExtractItemFromURL(itemList){
    const apiURL = process.env.REACT_APP_MasterApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    var matchItemList = itemList.split(',');
    var filterList =[];
    

    matchItemList.forEach((element, index) => {
        filterList.push("ctb-worship-tc|" + element)
      });
    fetch(apiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization":  "Bearer search-" + apiKey
            },
            body:JSON.stringify({
                query:"",
                filters: {"any": {"id": filterList}  }, 
                page:{size: 1000},
                sort:{id: "asc"}
            })
        })
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            var results = JSON.parse(JSON.stringify(data));
            var extractedItems = [];
             results.results.forEach((element, index) => {
                 extractedItems.push({"id": results.results[index].id.raw, "playListId": "1", "title": results.results[index].title.raw , "videoId": results.results[index].link.raw});
             });
            return extractedItems;
        }
        );
}

