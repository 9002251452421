import React ,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import YouTube from "react-youtube";
import { 
        EuiFlexGroup,
        EuiFlexItem,
        EuiSpacer,
        EuiButton,
        EuiTitle,
        EuiText,
        EuiModal,
        EuiModalHeader,
        EuiModalBody,
        EuiModalHeaderTitle,
        EuiModalFooter,
        EuiHorizontalRule,
        euiDragDropReorder,
        EuiAccordion,
        EuiPanel,
        copyToClipboard,
        EuiButtonEmpty} from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';   
import Footer from '../components/Footer';    

import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import LoadUserSettings, {RemovePlayListItem, SaveUserSettings, ExtractItemFromURL} from '../components/UserServices';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

var videoJson = '[{"id": "i2", "playListId": "1", "title": "活出愛", "itemId": "lfP-3JLVvaw","itemType" : "v", "docType" : "詩歌" }]'
var videoIdList =  (LoadUserSettings("","shareItemList", true)) ? LoadUserSettings("","shareItemList", true) : JSON.parse(videoJson);

// var videoIdList =  JSON.parse(videoJson);

function Share(props) {
  const navigate = useNavigate();
  const [videoId, setVideoId] = useState("loJ8jTRfCik");
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [closePanelWhenPlay, setClosePanelWhenPlay] = useState(false);
  const [loopPlay, setLoopPlay] = useState(false);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [list, setList] = useState(videoIdList);
  const [listText, setListText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [shareLink, setSharelink] = useState("");
  const [shareText, setShareText] = useState("");
  const [hideLyric, sethideLyric] = useState(1);

  const currentURL = window.location.origin;
  const language = localStorage.userLanguage;
  const urlParams = new URLSearchParams(window.location.search);

    
  const getList = async () =>{
    if(urlParams.has('items')){
      var itemList = urlParams.get('items');
      var matchItemList = atob(itemList).split(',');
      var filterList =[];
          matchItemList.forEach((element, index) => {
          filterList.push(element)
        });
      await fetch(apiURL,{
              method:"POST",
              headers:{
                  "Content-Type":"application/json, charset=UTF-8",
                  "Authorization":  "Bearer search-" + apiKey
              },
              body:JSON.stringify({
                  query:"",
                  filters: {"any": {"id": filterList}  }, 
                  page:{size: 1000},
                  sort:{id: "asc"}
              })
          })
          .then(function(response){
              return(response.json())
          })
          .then(function(data){
              var results = JSON.parse(JSON.stringify(data));
              var extractedVideos = [];
              var extractedTexts = [];
              filterList.forEach((element, index) => {
                var foundItem = results.results.find(({id}) => id.raw === element)
                if(foundItem){
                    console.log("fournditem",foundItem);
                    var foundDocType = "t"
                    foundDocType = (foundItem.doctype.raw == "詩歌" || foundItem.doctype.raw == "信息" || foundItem.doctype.raw == "禱告")? "v" : foundDocType;
                    foundDocType = (foundItem.doctype.raw == "網路") ? (foundItem.category.raw == "見證" ) ? "v" :foundDocType :foundDocType;
                  if(foundDocType =="v"){
                    var videoId = foundItem.link.raw.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)[1];
                    if(foundItem.doctype.raw != "禱告")
                    {
                      extractedVideos.push({
                        id: foundItem.id.raw,
                        playListId: "1",
                        videoId: videoId,
                        description: foundItem.description.raw,
                        title: foundItem.title.raw,
                        publisher: foundItem.publisher.raw,
                        verselist: foundItem.verselist.raw,
                        doctype: foundItem.doctype.raw
                      });
                    }
                    if(foundItem.doctype.raw == "禱告")
                    {
                      extractedVideos.push({
                        id: foundItem.id.raw,
                        playListId: "1",
                        videoId: videoId,
                        description: foundItem.prayer.raw,
                        title: foundItem.title.raw,
                        publisher: foundItem.publisher.raw,
                        verselist: foundItem.verselist.raw,
                        doctype: foundItem.doctype.raw
                      });
                    }
                  }
                  // If not video type, add record context (title + description ) to a state
                  if(foundDocType =="t"){
                    var title = (foundItem.doctype.raw != "經文") ?  foundItem.title.raw : 
                                foundItem.title.raw + " ( " + foundItem.chapter_name.raw + " " + foundItem.chapter_verse_number.raw +" )";
                    var description = (foundItem.description) && foundItem.description.raw ;
                    description = (foundItem.prayer) ? foundItem.prayer.raw : description;
                    var verseNumber = ( foundItem.chapter_verse_number) ? foundItem.chapter_verse_number.raw : "";
                    var chapterName = ( foundItem.chapter_name) ? foundItem.chapter_name.raw : "";
                    var verse = ( foundItem.verse) ? foundItem.verse.raw : "";
                    extractedTexts.push({
                      id: foundItem.id.raw,
                      playListId: "1",
                      description: description,
                      title: title,
                      chapterName: chapterName,
                      chapter_verse_number: verseNumber,
                      verse: verse,
                      doctype: foundItem.doctype.raw,
                      });
                  }
                }
              })
              setList(extractedVideos); 
              setListText(extractedTexts);
              loadVideo(currentVideoId);
          }
        )
    }
  }

  const opts = {
    width:"",
    height: "",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
       autoplay: 1,
       playsinline: 1,
       enablejsapi: 1
     //  mute: 1
    }
  };     
    useEffect(()=>{
        // list.length > 0 && loadVideo(currentVideoId);
       if(urlParams.has('items')){
            getList() 
            setIsLoaded(true);
       } else {
            document.location.href="/";
       } 
     },[])

    const loadVideo = (videoIndex) => {
        if(videoIndex < list.length && videoIndex >= 0){
            setCurrentVideoId(videoIndex)
         }
    }

    const onReady = (e) => {
      e.target.playVideo();
    }

    const onLoopPlay = (e) => {
      setLoopPlay(e.target.checked);
    }
 
    const checkStateChange = (e) => {
        if(e.target.playerInfo.playerState === 0){
            // auto next
            var nextId = currentVideoId + 1;
            if(loopPlay && nextId >= videoIdList.length ){
               nextId = 0;
            }
            loadVideo(nextId);
           
          }
      };

      const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
          const items = euiDragDropReorder(list, source.index, destination.index);
          setList(items);
          // localStorage.shareItemList = JSON.stringify(items);
          SaveUserSettings("","shareItemList",items, true);
          window.location.reload();
        }
      };

      const onRemoveItem = (index) => {
        var newList = RemovePlayListItem("", 1, index);
        setList(newList);
        window.location.reload();
      }

      const shareClicked = ()=> {
        var items = "";
        list.forEach((element, index) => {
          items += element.id + ",";
        })
        items = window.location.origin + "/player?items=" + btoa(items.substring(0,items.length-1));
        setSharelink(items);
        setisModalVisible(true);
      }

      const closeModal =()=> {
        copyToClipboard(shareLink);
        setisModalVisible(false);
      }

      const onSearch = (e) => {
        navigate('/?q="' + e + '"' );
      }

      let modal;
      if (isModalVisible) {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h2> {TranslateLanguage(language, "分享我的歌單")}</h2>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
    
            <EuiModalBody>
            {TranslateLanguage(language, "您可以使用以下的連結來分享您的歌單。")}
              <EuiSpacer />{shareLink}
            </EuiModalBody>
    
            <EuiModalFooter>
              <EuiButton onClick={closeModal} fill>
              {TranslateLanguage(language, "複製 Copy")}
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        );
      }

    return (
      <div>
          <EuiFlexGroup responsive={false} alignItems="center">
              <EuiFlexItem></EuiFlexItem>
              <EuiFlexItem style={{ textAlign: 'center'}} grow={false}>
                 <span><a href={currentURL}><img  src={logosmall} alt="Check The Bible" /></a></span>
              </EuiFlexItem>
              <EuiFlexItem  grow={false}>
              <EuiTitle size="m"><h2 id="myworshipsongs">{TranslateLanguage(language, "好友分享")}</h2></EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem></EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
          {(isLoaded && list.length > 0 ) ? (
              // shared with video
              <>
               <EuiFlexItem>
                    <YouTube
                        id="ytPlayer"
                        videoId={list[currentVideoId].videoId}
                        className="yt-iframe"
                        containerClassName="yt-container"
                        onStateChange={(e) => checkStateChange(e)}
                        onReady={onReady}
                        opts={opts}></YouTube>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ width: 300, padding: 20} }>
                    <>
                    <EuiAccordion id={0} buttonContent={list[currentVideoId].title} initialIsOpen={false} arrowDisplay="right">
                      <EuiSpacer></EuiSpacer>
                      <EuiText>{list[currentVideoId].description}</EuiText>
                      <EuiSpacer></EuiSpacer>
                      <EuiText>{list[currentVideoId].publisher}</EuiText>
                      <EuiSpacer></EuiSpacer>
                      <EuiText>{list[currentVideoId].verselist}</EuiText>
                    </EuiAccordion>
                    <EuiHorizontalRule />
                    {(listText.length > 0) && (listText.map(element => (
                        <>
                        {(element.doctype != "經文") ? 
                        (<EuiAccordion id={element.id} buttonContent={element.title} initialIsOpen={(element.doctype != "經文")} arrowDisplay={(element.doctype == "經文") ? "none" : "right"}>
                        <EuiPanel color="subdued"><EuiText>{element.description}</EuiText></EuiPanel>
                        </EuiAccordion>)
                        :
                        (
                          <EuiAccordion  id={element.id} buttonContent={element.title} initialIsOpen={(element.doctype != "經文")} arrowDisplay={(element.doctype == "經文") ? "none" : "right"}>
                          <EuiPanel color="subdued"><EuiButtonEmpty onClick={()=> onSearch(element.verse)}>搜尋經文</EuiButtonEmpty></EuiPanel>
                          </EuiAccordion>                       
                        )                     
                        }
                        <EuiHorizontalRule />
                        </>
                    )))}
                    </>
                </EuiFlexItem>
              </>
            ) : (
              // No video shared
              <EuiFlexItem style={{ padding: 20} }>
              {(listText.length > 0) && (listText.map(element => (
                  <>
                    <EuiAccordion  id={element.id} buttonContent={element.title} initialIsOpen={(element.doctype != "經文")} arrowDisplay={(element.doctype == "經文") ? "none" : "right"}>
                    <EuiPanel color="subdued"><EuiButtonEmpty onClick={()=> onSearch(element.verse)}>搜尋經文</EuiButtonEmpty></EuiPanel>
                    </EuiAccordion>    
                  <EuiHorizontalRule />
                  </>
              )))}
              </EuiFlexItem>
            )
          }
        </EuiFlexGroup>
        <Footer />
    </div>
  )
}

export default Share