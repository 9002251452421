import React ,{useState, useEffect} from 'react';
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import '../style.css';

import {
    EuiText,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiButton,
    useDataGridColumnSelector
  } from '@elastic/eui';

function GotQuestions(props) {
    const [result, setResult] = useState({question:"", answer:"", link: "", lastupdate: ""});
    var itemId = props.verse_id.replace("ctb-webresource|","")
    var language = props.language;

    const apiURL = "https://364cbaf8eaa6414ba48bf0a6002764a7.ent-search.us-west-2.aws.cloud.es.io/api/as/v1/engines/ctb-webresource/search"

    useEffect(()=>{
        loadDescription();
    },[])

    const loadDescription = async () => {
        await fetch(apiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization": "Bearer search-72ikvuebo7fp1sagg8j3majj"
            },
            body:JSON.stringify({
                query:itemId,
                filters: {"all": [{id: itemId}]}, 
                page:{size: 1000},
                sort:{id: "asc"}
            })
        })
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            var results = JSON.parse(JSON.stringify(data))
            var resultQuestion= results.results[0].title.raw
            var resultAnswer = results.results[0].description.raw
            var resultLink = results.results[0].link.raw
            var resultLastUpdate = results.results[0].lastupdate.raw
            resultAnswer = resultAnswer + "。。。（前往原始網站查看更多）<br><br>"
            setResult({question: resultQuestion, answer: resultAnswer, link: resultLink, lastupdate: resultLastUpdate })       
        })
    }

    return (
        <>
          <EuiFlyoutHeader hasBorder>
              <EuiTitle size="m">
                <h2 id="flyoutTitle">{ TranslateLanguage(language,result.question)}</h2>
              </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>

            <EuiText><span dangerouslySetInnerHTML={{__html:  TranslateLanguage(language,result.answer)}} /></EuiText>
            <EuiText>來源：GotQuestions.org</EuiText>
            <EuiText>{'上次更新：' + result.lastupdate}</EuiText>
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                </EuiFlexItem>
                <EuiFlexItem grow={false}> 
                  <EuiButton href={result.link} target="_blank" fill>
                  { TranslateLanguage(language,"連結來源")}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
          </EuiFlyoutFooter>
         </>
    )
}

export default GotQuestions
