import React ,{useState, useEffect} from 'react';
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import '../style.css';

import {
    EuiText,
    EuiSelect, 
    EuiSpacer,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiTitle,
    EuiButton
  } from '@elastic/eui';

function Prayer(props) {
    var versenumber =  props.versenumber;
    var prayer_id =  props.verse_id.replace("ctb-prayer|","");
    var title = props.bookname;
    var language = props.language;
    const [resultVerse, setResultVerse] = useState("");
    const [source, setSource] = useState("");
    const [summary, setSummary] = useState("");
    const [authorLink, setAuthorLink] = useState("");
    const [author, setAuthor] = useState("");
    const [publisherLink, setPublisherLink] = useState("");
    const [publisher, setPublisher] = useState("");
    const [prayer, setPrayer] = useState("");
    const [worshipLink, setWorshipLink] = useState("");
    const [worshipName, setWorshipName] = useState("");
    const prayerApiURL = process.env.REACT_APP_PryaerApiURL;
    const bibleApiURL = process.env.REACT_APP_BibleApiURL;
    const worshipApiURL = process.env.REACT_APP_WorshipApiURL;
    const apiKey = process.env.REACT_APP_APIKey;

    useEffect(()=>{
        loadInfo();
    },[])
   
    const loadInfo = async () => {
        var resultVerseText = "";

        // LOAD Main Prayer Info
        await fetch(prayerApiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization": "Bearer search-" + apiKey
            },
            body:JSON.stringify({
                query:prayer_id,
                filters: {"all": [{id: prayer_id}]}, 
                page:{size: 1000},
                sort:{id: "asc"}
            })
        })
        .then((response) => {
            return(response.json())
        })
        .then((data) => {
            var results = JSON.parse(JSON.stringify(data))
            if (results.results.length > 0 ){
                setSource(results.results[0].source.raw);
                setSummary(results.results[0].summary.raw);
                setWorshipName(results.results[0].worship_name.raw);
                setWorshipLink(results.results[0].worship_link.raw);
                setAuthor(results.results[0].author.raw);
                setAuthorLink(results.results[0].author_link.raw);
                setPublisher(results.results[0].publisher.raw);
                setPublisherLink(results.results[0].publisher_link.raw);
                setPrayer(results.results[0].prayer.raw);
            }
        });
  
        if (versenumber != "" ){
            versenumber =  versenumber.split(", ").join(",").trim() ;
            if (versenumber.substring(versenumber.length-1,1) == ',') {
                versenumber = versenumber.substring(0,versenumber.length-2) 
            }
            var resultVerseList =  versenumber.split(",").join("\",\"").trim() ;

            // LOAD REFERENCE VERSES

            await fetch(bibleApiURL,{
                method:"POST",
                headers:{
                    "Content-Type":"application/json, charset=UTF-8",
                    "Authorization": "Bearer search-" + apiKey
                },
                body:JSON.stringify({
                    query:"",
                    filters: {"any": [{verse: [resultVerseList]}]}, 
                    page:{size: 1000},
                    sort:{id: "asc"}
                }).replace(/\\"/g, '"')
            })
            .then((response) => {
                return(response.json())
            })
            .then((data) => {
                var results = JSON.parse(JSON.stringify(data))
                if (results.results.length > 0){
                for(var i = 0; i < results.results.length; i++) {
                        resultVerseText = resultVerseText + "<EuiText>" + "( " +
                        results.results[i].chapter_name.raw + " " + 
                        results.results[i].chapter_verse_number.raw + " ) " +
                        results.results[i].title.raw   + "</EuiText><br/>"
                }
                } else {
                    resultVerseText = "";
                }
            })
            setResultVerse(resultVerseText)    
            // console.log("loaded" );      
        }
    };

    return (
        <>
          <EuiFlyoutHeader hasBorder>
              <EuiTitle size="m">
                <h2 id="flyoutTitle">{title}</h2>
              </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiText><span dangerouslySetInnerHTML={{__html:TranslateLanguage(language,source)}} /></EuiText>
            <EuiSpacer></EuiSpacer>
            <EuiText><span dangerouslySetInnerHTML={{__html:TranslateLanguage(language,summary)}} /></EuiText>
            <EuiSpacer></EuiSpacer>

            <EuiText>{TranslateLanguage(language,"相關經文")}： </EuiText>
            <EuiText><span dangerouslySetInnerHTML={{__html:TranslateLanguage(language,resultVerse)}} /></EuiText>
            <EuiSpacer></EuiSpacer>

            {(worshipName) && <div>
            <EuiText>{TranslateLanguage(language,"詩歌")}： </EuiText>
            <EuiText><span dangerouslySetInnerHTML={{__html:TranslateLanguage(language,worshipName)}} /></EuiText>    
            <EuiButtonEmpty size="xs" color="primary" href={worshipLink} target='_blank'><span dangerouslySetInnerHTML={{__html:worshipLink}} /></EuiButtonEmpty>    
            <EuiSpacer></EuiSpacer>  </div>
            }  

            <EuiText>{TranslateLanguage(language,"禱告")}： </EuiText>
            <EuiText><span dangerouslySetInnerHTML={{__html:TranslateLanguage(language,prayer)}} /></EuiText>   
            <EuiSpacer></EuiSpacer>

            <EuiText>{TranslateLanguage(language,"作者")}： </EuiText>     
            <EuiText><span dangerouslySetInnerHTML={{__html:author}} /></EuiText>    
            <EuiButtonEmpty iconType="link" size="xs" color="primary" href={authorLink} target='_blank'><span dangerouslySetInnerHTML={{__html:authorLink}} /></EuiButtonEmpty>    
            <EuiText>{TranslateLanguage(language,"發行")}： </EuiText>    
            <EuiText><span dangerouslySetInnerHTML={{__html:publisher}} /></EuiText>     
            <EuiButtonEmpty iconType="link" size="xs" color="primary" href={publisherLink} target='_blank'><span dangerouslySetInnerHTML={{__html:publisherLink}} /></EuiButtonEmpty>            
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
          </EuiFlyoutFooter>
        </>
    )
}   
export default Prayer
