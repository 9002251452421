// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Search from "./components/Search";
import Settings from "./components/Settings";
import HowToUse from "./components/HowToUse";
import PlaylistPlayer from "./components/PlaylistPlayer";
import ShareSong from "./components/ShareSong";
import Share from "./components/Share";
import SharePage from "./components/SharePage";
import Radio from "./components/Radio";
import RadioMobile from "./components/RadioMobile";

export default function App() {
   return (
     <Router>
        <Routes>
          <Route path="/howtouse" element={<HowToUse />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/player" element={<PlaylistPlayer />}></Route>
          <Route path="/sharesong" element={<ShareSong/>}></Route>
          <Route path="/share" element={<Share/>}></Route>
          <Route path="/sharepage" element={<SharePage/>}></Route>
          <Route path="/radio" element={<Radio/>}></Route>
          <Route path="/radiomobile" element={<RadioMobile/>}></Route>
          <Route path="/home" element={<Search />}></Route>
          <Route path="/" element={<Search />}></Route>
        </Routes>
      </Router>
  );
}