import React ,{useState} from 'react';
import { useNavigate } from 'react-router';
import { 
        EuiButtonEmpty,
        EuiFlyoutBody,
        EuiFlyoutHeader,
        EuiButton,
        EuiText,
        EuiSpacer,
        EuiTitle,
        EuiFlyoutFooter,
        EuiDragDropContext,
        EuiDraggable,
        EuiDroppable,
        EuiPanel,
        EuiIcon,
        euiDragDropReorder,
        EuiFlexGroup,
        EuiFlexItem,
        copyToClipboard,
        EuiModal ,
        EuiModalHeader,
        EuiModalBody,
        EuiModalHeaderTitle,
        EuiModalFooter } from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';        
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import LoadUserSettings, {RemoveShareListItem, SaveUserSettings} from '../components/UserServices';
import Footer from '../components/Footer';


function ShareList(props){
    const navigate = useNavigate();
    var language = localStorage.userLanguage;
    var videoJson = '[{"id": "i2", "playListId": "1", "title": "活出愛", "itemId": "lfP-3JLVvaw","itemType" : "v", "docType" : "詩歌" }]'
    var videoIdList =  (LoadUserSettings("","shareItemList", true)) ? LoadUserSettings("","shareItemList", true) : JSON.parse(videoJson);
    const [list, setList] = useState(videoIdList);
    const currentURL = window.location.origin;
    const divStyle = { "text-align": "center" };
    const [shareLink, setSharelink] = useState("");
    const [isModalVisible, setisModalVisible] = useState(false);
    
    const opts = {
        width:"",
        height: "",
        playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1 
        }
    };    

    const previewShare = ()=> {
      var items = "";
      list.forEach((element, index) => {
        items += element.id +",";
      })
      items = window.location.origin + "/share?items=" + btoa(items.substring(0,items.length-1));
      window.location = items;
    }
    
    const shareClicked = ()=> {
      var items = "";
      list.forEach((element, index) => {
        items += element.id +",";
      })
      items = window.location.origin + "/share?items=" + btoa(items.substring(0,items.length-1));
      setSharelink(items);
      setisModalVisible(true);
    }

    const closeModal =()=> {
      copyToClipboard(shareLink);
      setisModalVisible(false);
    }

    let modal;
    if (isModalVisible) {
      modal = (
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h2> {TranslateLanguage(language, "分享我的收藏")}</h2>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
  
          <EuiModalBody>
          {TranslateLanguage(language, "您可以使用以下的連結來分享您的收藏。")}
            <EuiSpacer />{shareLink}
          </EuiModalBody>
  
          <EuiModalFooter>
            <EuiButton onClick={closeModal} fill>
            {TranslateLanguage(language, "複製 Copy Link")}
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      );
    }

    const onDragEnd = ({ source, destination }) => {
    if (source && destination) {
        const items = euiDragDropReorder(list, source.index, destination.index);
        setList(items);
        SaveUserSettings("","shareItemList",items, true);
    }
    };

    const onRemoveItem = (index) => {
    var newList = RemoveShareListItem("", 1, index);
    setList(newList);
    }

    return (
        <>
        {modal}
        <EuiFlyoutHeader hasBorder>
        <div  style={{"text-align": "center"}}>
          <span><a href={currentURL}><img  src={logosmall} alt="Check The Bible" /></a> </span>
              <EuiTitle size="m"><h2 id="mysharelist">{TranslateLanguage(language, "我的收藏")}</h2></EuiTitle>
        </div>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
            <EuiText size="s">{TranslateLanguage(language, "您可以使用下面項目中 ")}<EuiIcon type="grab"></EuiIcon>{TranslateLanguage(language, " 圖示拖拉收藏項目來重新排序，或使用 X 圖示來移除。目前最多只能存取不重複15個項目")}</EuiText>
            <EuiSpacer></EuiSpacer>
            <EuiDragDropContext onDragEnd={onDragEnd} >
              <EuiDroppable droppableId="DROPPABLE_AREA" spacing="none">
                {list.map(({ videoId, title, id, docType }, idx) => (
                  <EuiDraggable spacing="none" key={id} index={idx} draggableId={id} hasBorder={true} >
                    {(provided, state) => (
                      <EuiPanel hasShadow={state.isDragging} hasBorder={true} paddingSize="none"  style={{"margin": "6px", "border":"1px solid #D3DAE6"}}>
                        <EuiFlexGroup responsive={false}>
                          <EuiFlexItem grow={false}  style={{"width": "8px"}}>
                            <EuiIcon type="grab" style={{"margin": "8px"}}/>
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}  >
                            <EuiButtonEmpty  iconType="cross" onClick={()=> onRemoveItem(idx)} ></EuiButtonEmpty>
                          </EuiFlexItem>
                          <EuiFlexItem ><EuiText>
                          {TranslateLanguage(language, docType+" : ")}
                          {TranslateLanguage(language, 
                                                title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ") != "" ? 
                                                title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").substring(0,50) : title)}
                          {title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").length > 50 ? "..." : ""}
                          </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPanel>
                    )}
                  </EuiDraggable>
                ))}
              </EuiDroppable>
            </EuiDragDropContext>
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup gutterSize="m">
            <EuiFlexItem grow={false}><EuiButton onClick={() => previewShare()}>{TranslateLanguage(language, "預覽")}</EuiButton></EuiFlexItem>
            <EuiFlexItem grow={false}><EuiButton size="m" color="primary" fill onClick={() => shareClicked()}>{TranslateLanguage(language, "分享給好友")}</EuiButton></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
        <Footer />
      </>
  )
}

export default ShareList