import React from 'react';
import {
  EuiFlexGroup,  EuiFlexItem,  EuiPanel,  EuiSpacer,  EuiText} from '@elastic/eui';

function YTModal(props) {
    var url =  props.videoURL;
    var description = props.videoDescription;
    if(url){
      var videoId = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      var startTimeURL = new URLSearchParams(url);
      var startTime = startTimeURL.get('t');
      var srcPath ="https://www.youtube.com/embed/" + videoId[1] + (( videoId[1].includes('?')) ? "&" : "?") + "autoplay=1" + ((startTime) ? "&start=" + startTime : "");

      return (
            <iframe id="ytplayer" type="text/html" width="100%" height="405"
            src={srcPath}
            frameBorder="0" allowFullScreen>
            </iframe>
      )
    }
    if(!url){
      var message = description != null ? description : "抱歉，沒找到您所選擇的項目。請前往原始連結獲取更多信息。"
      return (
              <>
              <EuiSpacer></EuiSpacer>
              <p><EuiText><span dangerouslySetInnerHTML={{__html:  message}}></span></EuiText></p>
              <EuiSpacer></EuiSpacer>
              </>
       )
    }
} 

export default YTModal