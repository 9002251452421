import React ,{useState, useEffect} from 'react';
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import '../style.css';

import {
    EuiText,
    EuiSelect, 
    EuiSpacer,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiButton
  } from '@elastic/eui';

function CrossReference(props) {
    var book = props.book;
    var chapter = props.chapter;
    var language = props.language;
    var versenumber =  props.versenumber;
    var verse_id =  props.verse_id;
    var bookname = props.bookname;
    const [resultVerse, setResultVerse] = useState("");
    const [mainVerse, setMainVerse] = useState("");
    const crossReferenceApiURL = process.env.REACT_APP_CrossReferenceApiURL;
    const bibleApiURL = process.env.REACT_APP_BibleApiURL;
    const apiKey = process.env.REACT_APP_APIKey;

    useEffect(()=>{
        loadVerse();
    },[])
   
    const loadVerse = async () => {
        var resultVerseText = "";
        var mainVerseText = "";
        var resultVerseIds = "";

        // LOAD REFERENCES IDS
        await fetch(crossReferenceApiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization": "Bearer search-" + apiKey
            },
            body:JSON.stringify({
                query:verse_id,
                filters: {"all": [{verse_id: verse_id.toString()}]}, 
                page:{size: 1000},
                sort:{id: "asc"}
            })
        })
        .then((response) => {
            return(response.json())
        })
        .then((data) => {
            var results = JSON.parse(JSON.stringify(data))
            if (results.results ){
                resultVerseIds = results.results[0].verse_refid.raw;
            }
        });
  
        if (resultVerseIds =="" ){
            setResultVerse("此經文沒有找到任何相關經節！");  
            return
        }

        resultVerseIds =  verse_id + "," + resultVerseIds ;
        //resultVerseIds = resultVerseIds.split(",").join("\",\"") ;
        var versenum = resultVerseIds.split(",").map(Number);
        // LOAD REFERENCE VERSES

        await fetch(bibleApiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization": "Bearer search-" + apiKey
            },
            body:JSON.stringify({
                query:"",
                filters: {"any": [{verse_id: versenum}]}, 
                page:{size: 1000},
                sort:{id: "asc"}
            }).replace(/\\"/g, '"')
        })
        .then((response) => {
            return(response.json())
        })
        .then((data) => {
            var results = JSON.parse(JSON.stringify(data))
            if (results.results){
             for(var i = 0; i < results.results.length; i++) {
                 if(results.results[i].verse_id.raw == verse_id){
                    mainVerseText = results.results[i].title.raw;
                 } else {
                    resultVerseText = resultVerseText + "<EuiText>" + "( " +
                    results.results[i].chapter_name.raw + " " + 
                    results.results[i].chapter_verse_number.raw + " ) " +
                    results.results[i].title.raw   + "</EuiText><br/><br />"
                 }
               }
            } else {
                resultVerseText = "此經文沒有找到任何相關經節！";
            }
        })
        setMainVerse(mainVerseText)    
        setResultVerse(resultVerseText)    
        // console.log("loaded" );  
    };

    return (
        <>
          <EuiFlyoutHeader hasBorder>
              <EuiTitle size="m">
                <h2 id="flyoutTitle">{TranslateLanguage(language,bookname)} {versenumber} </h2>
              </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <EuiText><em><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,mainVerse)}} /></em></EuiText>
            <EuiSpacer></EuiSpacer>
            <EuiText>相關經文： </EuiText>
            <EuiSpacer></EuiSpacer>
              <EuiText><span dangerouslySetInnerHTML={{__html:  TranslateLanguage(language,resultVerse)}} /></EuiText>

          </EuiFlyoutBody>
          <EuiFlyoutFooter>
          </EuiFlyoutFooter>
        </>
    )
}   
export default CrossReference
