import React, { useState }  from "react";
import {useNavigate} from "react-router-dom";
import logo from '../assets/CTBLogo.png';
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiEmptyPrompt,
    EuiTitle,
    EuiLink,
    EuiSelect,
    EuiButtonEmpty,
    EuiSpacer
  } from '@elastic/eui';
  import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';

export default function Settings(props){
    const navigate = useNavigate();
    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) == "zh-CN" ? "S" : "T";
    var language = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
    var qtOption = (localStorage.qtOption) ? localStorage.qtOption : 'CGNTV';
    var yearReadOption =  (localStorage.yearReadOption) ? localStorage.yearReadOption : 'HT';
  
    const QTOptions = [
        { value: 'CGNTV', text: 'CGNTV 中文台 活潑的生命' },
        { value: 'CAI', text: '二重靈糧堂 蔡金龍牧師' },
        { value: 'GCH', text: '台北榮耀堂 （週一至週五）' },
    ];
    const LanguageOptions = [
        { value: 'T', text: '繁體' },
        { value: 'S', text: '簡體' },
    ];
    const YearReadOptions = [
        { value: 'HT', text: '時序' },
        { value: 'OT', text: '舊約開始' },
        { value: 'NT', text: '新約開始' }
    ];

    const [LanguageOption, setLanguageOption] = useState(language);
    const [QTOption, setQTOption] = useState(qtOption);
    const [YearReadOption, setYearReadOption] = useState(yearReadOption);

    const onLanguageChange = (e) => {
        setLanguageOption(e.target.value);
        localStorage.userLanguage = e.target.value;
    };
    const onQTChange = (e) => {
        setQTOption(e.target.value);
        localStorage.qtOption = e.target.value;
    };
    const onYearReadOption = (e) => {
        setYearReadOption(e.target.value);
        localStorage.yearReadOption = e.target.value;
    };

    return(
        <EuiPageTemplate
        template="centeredbody"
        pageContentProps={{ paddingSize: 'l' }}
        >
        <EuiEmptyPrompt
                title={<h2>{TranslateLanguage(language,"我的設定")}</h2>}
                layout="horizontal"
                color="plain"
                body={
                <>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                            {TranslateLanguage(language,"語言選項:")}
                        </EuiFlexItem>  
                        <EuiFlexItem>
                            <EuiSelect
                            id="LanguageOption"
                            options={LanguageOptions}
                            value={LanguageOption}
                            onChange={(e) => onLanguageChange(e)}
                          />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                            {TranslateLanguage(language,"活潑的生命頻道:")}
                        </EuiFlexItem>  
                        <EuiFlexItem>
                            <EuiSelect
                            id="QTOption"
                            options={QTOptions}
                            value={QTOption}
                            onChange={(e) => onQTChange(e)}
                          />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup> 
                        <EuiFlexItem>
                            {TranslateLanguage(language,"全年讀經計劃:")}
                        </EuiFlexItem>  
                        <EuiFlexItem>
                            <EuiSelect
                            id="YearReadOption"
                            options={YearReadOptions}
                            value={YearReadOption}
                            onChange={(e) => onYearReadOption(e)}
                          />
                        </EuiFlexItem>
                    </EuiFlexGroup>                    
                </>
                }
                actions={
                <EuiButton color="primary" fill onClick={() => {navigate(-1)}}>
                    {TranslateLanguage(language,"儲存")}
                </EuiButton>
                }
            />
            <div className="footer">
            <EuiSpacer></EuiSpacer>
                <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/")}}>首頁</EuiButtonEmpty>
                <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/about" target="_blank">有關我們</EuiButtonEmpty>
                <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/vision" target="_blank">異象</EuiButtonEmpty>            
                <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/mission" target="_blank">事工</EuiButtonEmpty>
                <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/contact" target="_blank">與我們聯繫</EuiButtonEmpty>
                <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/howtouse")}}>如何使用</EuiButtonEmpty>
                <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/settings")}}>{TranslateLanguage(language,"我的設定")}</EuiButtonEmpty> 
            <EuiSpacer></EuiSpacer>
            </div>
      </EuiPageTemplate> 
    )

}

