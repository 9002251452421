import React, { useState }  from "react";
import {useNavigate} from "react-router-dom";
import { 
    EuiSpacer,
    EuiButtonEmpty} from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';   

export default function Footer(props) {
    const withLanguage = props.withLanguage;
    console.log('withLangiage',withLanguage);
    const navigate = useNavigate();
    const language = localStorage.userLanguage;
    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) == "zh-CN" ? "S" : "T"
    var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
    localStorage.userLanguage = userLanguage;
    const [languageState, setLanguageState] = useState(userLanguage);
  
    const onChangeLanguage  = () => {
         language == "T"? setLanguageState("S"):setLanguageState("T");
         language == "T"? localStorage.userLanguage = "S" : localStorage.userLanguage = "T"
         window.scrollTo(0, 0);
        };

    return(
        <div className="footer">
        <EuiSpacer></EuiSpacer>
            <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/")}}>首頁</EuiButtonEmpty>
            <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/about" target="_blank">有關我們</EuiButtonEmpty>
            <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/vision" target="_blank">異象</EuiButtonEmpty>            
            <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/mission" target="_blank">事工</EuiButtonEmpty>
            <EuiButtonEmpty size ="xs" color="primary" href="https://www.checkthebible.org/contact" target="_blank">與我們聯繫</EuiButtonEmpty>
            <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/howtouse")}}>如何使用</EuiButtonEmpty>
            {(withLanguage == "1") && (<EuiButtonEmpty size ="xs" color="primary" onClick={()=>{onChangeLanguage()}}>{(language == "T"? "简体":"繁體")}</EuiButtonEmpty>)}
            <EuiButtonEmpty size ="xs" color="primary" onClick={()=>{navigate("/settings")}}>{TranslateLanguage(language,"我的設定")}</EuiButtonEmpty> 
        <EuiSpacer></EuiSpacer>
        </div>
    )
}


